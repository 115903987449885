import React from 'react';
import { FormControl, MenuItem, Select, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { selectStyles as styles } from './styles';

const SelectInput = ({ value, options, onChange, className, classes, isMultiple = false }) => {
    if (isMultiple) {
        return (
            <FormControl className={classNames(classes.formControl, className)} variant="outlined">
                <Select id="select-products" multiple value={value} onChange={(event) => onChange(event.target.value)}>
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    }
    return (
        <FormControl className={classNames(classes.formControl, className)} variant="outlined">
            <Select value={value} onChange={(event) => onChange(event.target.value)}>
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

SelectInput.propTypes = {
    value: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    isMultiple: PropTypes.bool
};

export default withStyles(styles)(SelectInput);
