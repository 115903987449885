export const CAMPAIGN_STATUS = {
    COMPLETED: 'COMPLETED',
    DRAFT: 'DRAFT',
    WAITING: 'WAITING',
    ACTIVE: 'ACTIVE',
    PAUSED: 'PAUSED'
};
export const CAMPAIGN_TYPES = {
    ONE_OFF: 'ONE_OFF',
    RECURRING: 'RECURRING',
    SCHEDULED: 'SCHEDULED'
};

export const MESSAGE_TYPE = {
    SMS: 'SMS',
    EMAIL: 'EMAIL'
};

export const NOTIFICATION_MESSAGE_TYPE = {
    EMAIL: 'email'
};

export const MESSAGE_FORM = {
    SUBJECT: 'subject',
    PREVIEW: 'preview'
};

export const MESSAGE_FORM_REDUCER = {
    ADD_ON_FORM: 'add_on_form',
    PREVIOUS_FORM: 'previous_form',
    CLEAN_FIELDS: 'clean_fields'
};
