import api from './api';

const createTemplate = (data) => {
    return api.request({
        method: 'POST',
        url: '/notifications/templates',
        data
    });
};

const updateTemplate = (data, id) => {
    return api.request({
        method: 'PUT',
        url: `/notifications/templates/${id}`,
        data
    });
};

export default {
    createTemplate,
    updateTemplate
};
