import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppBar, Tabs, Tab, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { cleanFields } from '../../redux/actions/messageActions';
import CancelContinueModal from '../common/CancelContinueModal';

import style from './style';
import _ from 'lodash';
import { checkDifferences } from '../../services/checkDifference';

const mainTabsArray = [
    { value: 'campaigns', label: 'Campaigns' },
    { value: 'segments', label: 'Segments' },
    { value: 'templates', label: 'Templates' }
];

const subTabsMap = {
    segments: []
};

const tabToNewWindow = (tab) => {
    if (['dynamic', 'static'].includes(tab)) {
        return `/segments/${tab}`;
    } else {
        return tab === 'segments' ? '/segments/dynamic' : `/${tab}`;
    }
};

const Header = ({ classes }) => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const form = useSelector((state) => state.message);
    const original = useSelector((state) => state.message.previous);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState(1);

    const getSelectedTab = (level) => location.pathname.split('/')[level];

    const renderTab = (tabObject, className, index) => {
        const navigate = tabToNewWindow(tabObject.value);
        return (
            <Tab
                key={index}
                className={className}
                value={tabObject.value}
                label={
                    <a href={navigate} onClick={(e) => e.preventDefault()}>
                        {tabObject.label}
                    </a>
                }
            />
        );
    };

    const renderSubTabs = () => {
        const tabsArray = subTabsMap[getSelectedTab(1)];
        if (!tabsArray || !getSelectedTab(2) || !tabsArray.find((item) => item.value === getSelectedTab(2))) {
            return null;
        }
        return (
            <AppBar position="static" className={classes.whiteBar} classes={{ root: classes.noShadow }}>
                <Tabs
                    classes={{ indicator: classes.tabIndicator }}
                    variant="fullWidth"
                    value={getSelectedTab(2)}
                    onChange={(e, value) => history.push(`/${getSelectedTab(1)}/${value}`)}
                >
                    {tabsArray.map((tab, index) => renderTab(tab, classes.subTab, index))}
                </Tabs>
            </AppBar>
        );
    };

    if (!getSelectedTab(1)) {
        return null;
    }

    const changeTab = (value) => {
        dispatch(cleanFields());
        history.push(`/${value || selectedTab}`);
    };

    return (
        <>
            <AppBar position="static" color="secondary" classes={{ root: classes.noShadow }}>
                <Tabs
                    variant="fullWidth"
                    value={getSelectedTab(1)}
                    className={classes.tabsContainer}
                    onChange={(e, value) => {
                        setSelectedTab(value);
                        if (form && original) {
                            if (checkDifferences(_.omit(original, 'previous'), _.omit(form, 'previous'))) {
                                setIsConfirmModalOpen(true);
                                return;
                            }
                        }
                        changeTab(value);
                    }}
                >
                    {mainTabsArray.map((tab, index) => renderTab(tab, classes.mainTab, index))}
                </Tabs>
            </AppBar>
            {renderSubTabs()}
            {isConfirmModalOpen && (
                <CancelContinueModal
                    setOpen={setIsConfirmModalOpen}
                    onContinue={() => {
                        changeTab();
                        dispatch(cleanFields());
                    }}
                    title={'Your changes will not saved'}
                    contentText={'Are you sure you want to continue?'}
                />
            )}
        </>
    );
};

Header.propTypes = {
    classes: PropTypes.object
};

export default withStyles(style)(Header);
