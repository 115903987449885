import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { templatesViewStyle as styles } from './styles';
import { Tabs, Tab, withStyles, Paper, Box } from '@material-ui/core';
import { MESSAGE_TYPE } from '../../constants/index';
import TemplatesList from './TemplatesList';
import campaignApi from '../../api/campaignApi';
import MessageForm from '../common/MessageForm';
import { addOnForm, cleanFields } from '../../redux/actions/messageActions';
import { useDispatch } from 'react-redux';
import Button from '../common/Button';
import SimpleModal from '../common/SimpleModal';
import CancelContinueModal from '../common/CancelContinueModal';

function TemplatesView({ classes }) {
    const dispatch = useDispatch();
    const [format, setFormat] = useState(MESSAGE_TYPE.EMAIL);
    const [filter, setFilter] = useState({
        page: 0,
        pageSize: 25,
        type: format
    });
    const [templates, setTemplates] = useState({});
    const [displayTemplateModal, setDisplayTemplateModal] = useState(false);
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [templateToEdit, setTemplateToEdit] = useState({});

    useEffect(() => {
        dispatch(addOnForm({ ...templateToEdit, format: templateToEdit.type }));
        /* eslint-disable-next-line */
    }, [templateToEdit, setTemplateToEdit]);

    useEffect(() => {
        setFilter({ ...filter, type: format });
        dispatch(addOnForm({ format: format }));
        /* eslint-disable-next-line */
    }, [format, setFormat]);

    useEffect(() => {
        campaignApi.queryTemplate(filter).then((res) => {
            setTemplates(res);
        });
    }, [filter, setFilter]);

    useEffect(() => {
        setDisplayTemplateModal(false);
        setTemplateToEdit({});
    }, [templates, setTemplates]);

    return (
        <>
            <Paper square>
                <Tabs
                    value={format === MESSAGE_TYPE.EMAIL ? 0 : 1}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={() => setFormat(format === MESSAGE_TYPE.EMAIL ? MESSAGE_TYPE.SMS : MESSAGE_TYPE.EMAIL)}
                >
                    <Tab label="Email" value={0} />
                    <Tab label="SMS" value={1} />
                </Tabs>
            </Paper>
            <Box p={2}>
                <Box>
                    <Button
                        type="secondary"
                        className={classes.blueButton}
                        onClick={() => {
                            setDisplayTemplateModal(true);
                            dispatch(addOnForm({ format: format }));
                        }}
                    >
                        New {format === MESSAGE_TYPE.EMAIL ? format.toLowerCase() : format} template
                    </Button>
                </Box>
                <Box>
                    <TemplatesList
                        templates={templates}
                        setTemplates={setTemplates}
                        filter={filter}
                        setFilter={setFilter}
                        setDisplayEditTemplate={setDisplayTemplateModal}
                        setTemplateToEdit={setTemplateToEdit}
                    />
                </Box>
            </Box>
            <SimpleModal
                onClose={() => {
                    setDisplayConfirmationModal(true);
                }}
                isOpen={displayTemplateModal}
                onCancel={() => {
                    setDisplayConfirmationModal(true);
                }}
                size={'lg'}
            >
                <MessageForm
                    isEditing={!!templateToEdit.type}
                    isCampaignForm={false}
                    setTemplates={setTemplates}
                    setDisplayConfirmationModal={setDisplayConfirmationModal}
                    filter={filter}
                />
            </SimpleModal>
            {displayConfirmationModal && (
                <CancelContinueModal
                    setOpen={setDisplayConfirmationModal}
                    onContinue={() => {
                        setTemplateToEdit({});
                        setDisplayTemplateModal(false);
                        dispatch(cleanFields());
                    }}
                    title={'Your changes will not be saved'}
                    contentText={'Are you sure you want to continue?'}
                />
            )}
        </>
    );
}

TemplatesView.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TemplatesView);
