import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { form as styles, modalStyles } from '../styles';
import { modalsButtonStyles } from '../../../collums-constants/styles/stylesheets/buttonsStyles';
import formStyles from '../../../collums-constants/styles/stylesheets/formStyles';
import Moment from 'moment';
import {
    withStyles,
    makeStyles,
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
    FormControlLabel,
    Checkbox,
    MenuItem
} from '@material-ui/core';
import DateInput from '../../common/DateInput';
import { CAMPAIGN_TYPES, MESSAGE_TYPE } from '../../../constants';
import { toastr } from 'react-redux-toastr';
import { useSelector, useDispatch } from 'react-redux';
import { addOnForm } from '../../../redux/actions/messageActions';
import { Autocomplete } from '@material-ui/lab';
import segmentApi from '../../../api/segmentApi';
import { convertSegmentToText } from '../../../services/segmentHelper';
import { getCurrentTimezonedDate } from '../../../collums-components/helpers/timezone';

function CampaignFormSend({ classes, onSave }) {
    const [displaySchedule, setDisplaySchedule] = useState(false);
    const [displayRecurring, setDisplayRecurring] = useState(false);
    const [sendDialogType, setSendDialogType] = useState(null);
    const [hasChanged, setHasChanged] = useState(false);
    const dispatch = useDispatch();
    const [segmentText, setSegmentText] = useState('');
    const form = useSelector((state) => state.message);
    const globalModalStyles = makeStyles(modalStyles)();
    const globalButtonsStyles = makeStyles(modalsButtonStyles)();
    const globalFormStyles = makeStyles(formStyles)();
    const history = useHistory();
    const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    useEffect(() => {
        segmentApi
            .get(form.segment, true)
            .then(async (data) => {
                const msg = convertSegmentToText(data);
                setSegmentText(msg);
                const count = await segmentApi.countCustomersForSegment(form.segment);
                setSegmentText(`${msg} (${form.format === 'EMAIL' ? count.emailAllowed : count.smsAllowed})`);
            })
            .catch((error) => console.error(error));
        if (form.type === 'RECURRING') {
            setDisplayRecurring(true);
            const hour = new Moment(form.recurringTime, 'HH:mm').hours();
            const minute = new Moment(form.recurringTime, 'HH:mm').minutes();
            const recurringTime = new Moment().set({ hour, minute });
            dispatch(
                addOnForm({
                    nextSend: recurringTime
                })
            );
        } else if (form.type === 'SCHEDULED') {
            setDisplaySchedule(true);
        }

        /* eslint-disable-next-line */
    }, []);

    useEffect(() => {
        if (form.id) return;
        if (displayRecurring || form.type === 'RECURRING') {
            dispatch(
                addOnForm({
                    recurringStart: new Moment(),
                    recurringEnd: new Moment(),
                    nextSend: new Moment().startOf('day'),
                    recurringDays: weekDays
                })
            );
        } else {
            dispatch(
                addOnForm({
                    recurringStart: undefined,
                    recurringEnd: undefined,
                    nextSend: new Moment().startOf('day')
                })
            );
        }
        // eslint-disable-next-line
    }, [displayRecurring, setDisplayRecurring]);

    useEffect(() => {
        if (form.id) return;
        if (displaySchedule) {
            const { minutes, hours } = next5min(new Moment());
            const dayMoment = new Moment().set({ hour: hours, minute: minutes });
            dispatch(
                addOnForm({
                    nextSend: dayMoment
                })
            );
        }
        // eslint-disable-next-line
    }, [displaySchedule, setDisplaySchedule]);

    const dialogTextHandler = (dialog_type = sendDialogType) => {
        const new_campaign = history.location.pathname.includes('/new');
        const sure = 'Are you sure you want to';
        switch (dialog_type) {
            // SCHEDULE CAMPAIGN
            case 'SCHEDULED':
                return {
                    button: 'Schedule',
                    text: sure + ' schedule this campaign?',
                    new_campaign
                };
            // SEND CAMPAIGN
            case 'ONE_OFF':
                return {
                    button: 'Send',
                    text: sure + ' send this campaign?',
                    new_campaign
                };
            // RECURRING CAMPAIGN
            case 'RECURRING':
                return {
                    button: 'Activate',
                    text: sure + ' activate this campaign?',
                    new_campaign
                };
            default:
                return { text: '', button: '', new_campaign };
        }
    };
    const next5min = (date) => {
        if (!date || !date.isValid()) {
            return { hours: '00', minutes: '00' };
        }
        const _minutes = Math.ceil(parseInt(date.format('mm')) / 5) * 5;
        const _hours = _minutes >= 60 ? String(parseInt(date.format('HH')) + 1) : date.format('HH');
        return {
            minutes: _minutes < 60 ? String(_minutes < 10 ? '0' + _minutes : _minutes) : '00',
            hours: _hours >= 24 ? '00' : _hours
        };
    };
    const renderSelectTime = (initialValue) => {
        const hours = form.nextSend?.isValid() ? form.nextSend.format('HH') : '00';
        const minutes = form.nextSend?.isValid() ? form.nextSend.format('mm') : '00';
        return (
            <Box display="flex" flexDirection="row" alignItems="center">
                {displayRecurring && <b className={classes.width10}>Choose send time*</b>}
                <TextField
                    required
                    className={classes.marginLeft16px}
                    select
                    label="HH"
                    variant="outlined"
                    defaultValue={initialValue ? new Moment(initialValue).format('HH') : hours}
                    onChange={(e) => {
                        const newNextSend = () => {
                            if (new Moment(form.nextSend).isValid()) {
                                return new Moment(form.nextSend).clone().set({ hour: e.target.value });
                            } else {
                                return Moment().set({ hour: e.target.value, minute: '00' });
                            }
                        };
                        dispatch(addOnForm({ nextSend: newNextSend() }));
                    }}
                >
                    {Array.from({ length: 24 }, (_, k) => {
                        const value = k >= 10 ? k : `0${k}`;
                        return (
                            <MenuItem key={value} value={value}>
                                {value}
                            </MenuItem>
                        );
                    })}
                </TextField>
                <b className={classes.marginLeft8px}>:</b>
                <TextField
                    select
                    label="MM"
                    className={classes.marginLeft8px}
                    variant="outlined"
                    defaultValue={initialValue ? new Moment(initialValue).format('mm') : minutes}
                    onChange={(e) => {
                        const newNextSend = () => {
                            if (new Moment(form.nextSend).isValid()) {
                                return new Moment(form.nextSend).clone().set({ minute: e.target.value });
                            } else {
                                return Moment().set({ minute: e.target.value, hour: '00' });
                            }
                        };
                        dispatch(addOnForm({ nextSend: newNextSend() }));
                    }}
                >
                    {
                        // Creates an array with 12 elements filled with values from 0 to 11
                        Array.from(Array(60 / 5).keys()).map((value) => {
                            // Checks whether value*5 is less than 10, because it needs a "0"
                            // in front of it
                            const minute = value * 5 >= 10 ? value * 5 : '0' + value * 5;
                            return (
                                <MenuItem key={minute} value={minute}>
                                    {minute}
                                </MenuItem>
                            );
                        })
                    }
                </TextField>
            </Box>
        );
    };

    const validationForm = (type) => {
        if (!form.id) {
            delete form.previous;
        }
        if (type === CAMPAIGN_TYPES.RECURRING && form.recurringDays.length === 0) {
            return toastr.warning('You need to inform at least one recurring day');
        }
        if (/* form.name && form.locations.length !== 0 &&*/ form.segment && form.content) {
            if (form.format === MESSAGE_TYPE.EMAIL && !form.subject) {
                return toastr.warning('You need to fill the subject');
            }

            return setSendDialogType(type);
        }

        //${form.locations.length === 0 ? 'locations' : ''}

        return toastr.warning(
            `You need to fill: ${!form.name ? 'name' : ''} ${!form.segment ? 'segment' : ''} ${
                !form.content ? 'content' : ''
            }`
        );
    };

    const renderScheduleCampaign = () => {
        const { minutes, hours } = next5min(new Moment());
        const dayMoment = new Moment().set({ hour: hours, minute: minutes });
        const timeValue = new Moment(form.nextSend).isValid()
            ? getCurrentTimezonedDate(new Moment(form.nextSend).toDate())
            : getCurrentTimezonedDate(dayMoment.toDate());
        const new_campaign = history.location.pathname.includes('/new');
        return (
            <Box className={classes.marginTop32px}>
                <Box>
                    <b>Schedule campaign</b>
                </Box>
                <Box display="flex" flexDirection="column" className={classes.marginTop16px}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <DateInput
                            className={classes.marginRight16px}
                            value={timeValue}
                            // onChange={(date) => dispatch(addOnForm({ ...form, nextSend: new Moment(date) }))}
                            onChange={(date) => {
                                const newNextSend = () => {
                                    if (form.nextSend.isValid()) {
                                        return new Moment(date).set({
                                            hour: timeValue.getHours(),
                                            minute: timeValue.getMinutes()
                                        });
                                    } else {
                                        return Moment(date);
                                    }
                                };
                                dispatch(addOnForm({ ...form, nextSend: newNextSend() }));
                            }}
                        />
                        {renderSelectTime(new_campaign ? dayMoment.toDate() : undefined)}
                    </Box>
                    <Box>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => validationForm('SCHEDULED')}
                            className={`${globalButtonsStyles.confirmButton} ${classes.marginTop16px} ${classes.marginLeft0px}`}
                        >
                            Schedule campaign
                        </Button>
                    </Box>
                </Box>
            </Box>
        );
    };

    const renderRecurringCampaign = () => {
        // If it's a new recurring campaign, make it start today
        const recurringStartValue = form.recurringStart
            ? getCurrentTimezonedDate(form.recurringStart.toDate())
            : getCurrentTimezonedDate();
        // If it's a new recurring campaign, make it end next week as default
        const nextWeek = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7);
        const recurringEndValue = hasChanged ? form.recurringEnd?.toDate() : nextWeek;

        return (
            <Box className={classes.marginTop32px}>
                <Box>
                    <b>Recurring campaign</b>
                </Box>
                <Box display="flex" flexDirection="column" className={classes.marginTop32px}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <b className={classes.width10}>Start date*</b>
                        <DateInput
                            required
                            autoOk
                            className={classes.marginLeft16px}
                            value={recurringStartValue}
                            onChange={(date) => dispatch(addOnForm({ recurringStart: new Moment(date) }))}
                        />
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="center" className={classes.marginTop16px}>
                        <b className={classes.width10}>End date*</b>
                        <DateInput
                            required
                            minDate={recurringStartValue}
                            disabled={form.noEndDate}
                            className={classes.marginLeft16px}
                            autoOk
                            value={recurringEndValue}
                            onChange={(date) => {
                                setHasChanged(true);
                                dispatch(addOnForm({ recurringEnd: new Moment(date) }));
                            }}
                        />
                    </Box>
                    <Box>
                        <FormControlLabel
                            className={classes.marginTop16px}
                            control={
                                <Checkbox
                                    required
                                    defaultChecked={form.noEndDate}
                                    onChange={(e, checked) => {
                                        if (!checked) {
                                            dispatch(addOnForm({ recurringEnd: new Moment(new Date()) }));
                                        }
                                        dispatch(addOnForm({ noEndDate: checked }));
                                    }}
                                    name="checkEndDate"
                                />
                            }
                            label="No end date"
                        />
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        className={`${classes.marginTop16px} ${classes.marginBottom16px}`}
                    >
                        <b style={{ width: 'calc(10% + 16px)' }}>Choose send days*</b>
                        <Autocomplete
                            required
                            className={`${globalFormStyles.autocomplete}`}
                            options={['all', ...weekDays]}
                            multiple
                            id="week-days-autocomplete"
                            value={form.recurringDays ? form.recurringDays : weekDays}
                            onChange={(e, value) => {
                                if (value.includes('all')) {
                                    dispatch(addOnForm({ ...form, recurringDays: weekDays }));
                                } else dispatch(addOnForm({ ...form, recurringDays: value }));
                            }}
                            renderInput={(params) => (
                                <TextField {...params} style={{ marginLeft: 10 }} variant="outlined" />
                            )}
                        />
                    </Box>
                    {renderSelectTime()}
                    <Box>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => validationForm(CAMPAIGN_TYPES.RECURRING)}
                            className={`${globalButtonsStyles.confirmButton} ${classes.marginTop32px} ${classes.marginLeft0px}`}
                        >
                            {/* RECURRING CAMPAIGN BUTTON TO SHOW DIALOG */}
                            {dialogTextHandler().new_campaign ? 'Activate' : 'Update'} campaign
                        </Button>
                    </Box>
                </Box>
            </Box>
        );
    };
    return (
        <>
            <Box className={classes.marginTop32px}>
                <div>
                    <b>Summary sending to: </b>
                    <b>{segmentText}</b>
                </div>
                <Box display="flex" flexDirection="row" className={classes.marginTop32px}>
                    <Box display="grid" alignItems="center" width="calc(10% + 16px)">
                        <span className={classes.marginBottom16px}>Type: </span>
                        <span className={classes.marginBottom16px}>Campaign name: </span>
                        <span className={classes.marginBottom16px}>Description: </span>
                        {form.format === MESSAGE_TYPE.EMAIL && (
                            <span className={classes.marginBottom16px}>Subject: </span>
                        )}
                    </Box>
                    <Box display="grid" alignItems="center" marginLeft="16px" width="100%">
                        <span className={`${classes.marginBottom16px} ${!form.format && classes.opacity05}`}>
                            {form.format || 'Type'}
                        </span>
                        <span className={`${classes.marginBottom16px} ${!form.name && classes.opacity05}`}>
                            {form.name || 'Name'}
                        </span>
                        <span className={`${classes.marginBottom16px} ${!form.description && classes.opacity05}`}>
                            {form.description || 'Description'}
                        </span>
                        {form.format === MESSAGE_TYPE.EMAIL && (
                            <span className={`${classes.marginBottom16px} ${!form.subject && classes.opacity05}`}>
                                {form.subject || 'Subject'}
                            </span>
                        )}
                    </Box>
                </Box>
                <Box display="flex">
                    <Button
                        variant="text"
                        color="primary"
                        className={classes.linkBtn}
                        onClick={() => {
                            if (displayRecurring) setDisplayRecurring(false);
                            setDisplaySchedule(!displaySchedule);
                        }}
                    >
                        Schedule campaign
                    </Button>
                    <Button
                        variant="text"
                        color="primary"
                        className={`${classes.linkBtn} ${classes.marginLeft16px}`}
                        onClick={() => {
                            if (displaySchedule) setDisplaySchedule(false);
                            setDisplayRecurring(!displayRecurring);
                        }}
                    >
                        Recurring campaign
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => validationForm('ONE_OFF')}
                        className={`${globalButtonsStyles.confirmButton} ${classes.marginLeft16px}`}
                    >
                        Send campaign now
                    </Button>
                </Box>
            </Box>

            {displaySchedule && renderScheduleCampaign()}
            {displayRecurring && renderRecurringCampaign()}

            <Dialog open={!!sendDialogType} onClose={() => setSendDialogType(null)} aria-labelledby="form-dialog-title">
                <DialogContent className={globalModalStyles.dialogContent}>
                    <DialogContentText>{dialogTextHandler().text}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        className={globalButtonsStyles.cancelButton}
                        onClick={() => setSendDialogType(null)}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        className={globalButtonsStyles.confirmButton}
                        onClick={() => {
                            onSave(sendDialogType);
                            setSendDialogType(null);
                        }}
                        color="primary"
                    >
                        {dialogTextHandler().button}
                    </Button>
                </DialogActions>
                <Box display="flex" width="100%" justifyContent="center">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={form.ignoreMarketingPreferences}
                                onChange={(e, checked) => dispatch(addOnForm({ ignoreMarketingPreferences: checked }))}
                                name="checkedF"
                            />
                        }
                        label="Ignore client marketing preferences"
                    />
                </Box>
            </Dialog>
        </>
    );
}

CampaignFormSend.propTypes = {
    classes: PropTypes.object.isRequired,
    onSave: PropTypes.func
};

export default withStyles(styles)(CampaignFormSend);
