export const templatesViewStyle = (theme) => ({
    grayButton: {
        backgroundColor: theme.palette.gray.bold,
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.gray.bolder
        }
    },
    blueButton: {
        margin: '5px 0px 0px 0px !important'
    },
    table: {
        marginTop: 16
    },
    tableRow: {
        cursor: 'pointer'
    },
    tableHeader: {
        fontSize: 14,
        opacity: 1,
        fontWeight: '500!important',
        borderBottom: '1px solid #e0e0e0'
    },
    tableBodyCell: {
        borderBottom: '1px solid #e0e0e0',
        opacity: 1,
        fontSize: 14,
        height: 50,
        '&> span': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            display: '-webkit-box'
        }
    },
    tablePagination: {
        textTransform: 'uppercase'
    },
    selectRoot: {
        backgroundColor: 'white',
        width: '12rem',
        '& > div > div': {
            boxSizing: 'border-box',
            height: 'inherit'
        },
        '& label.MuiInputLabel-shrink': {
            transform: 'translate(-2px, -2px) scale(0.75)',
            transformOrigin: 'top left'
        }
    },
    dialogMessageForm: {
        '& .MuiDialog-paperWidthSm': {
            minWidth: '80%',
            padding: 32
        }
    },
    searchBox: {
        '& input::placeholder': {
            fontSize: '14px !important',
            fontFamily: 'Roboto, Arial, "sans-serif" !important'
        },
        maxWidth: '250px',
        width: 'max-content',
        minWidth: '200px'
    }
});
