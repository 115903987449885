import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { messageForm as styles } from './styles';
import { modalsButtonStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';

import { withStyles, makeStyles } from '@material-ui/core';
import SMSEditor from './SMSEditor';
import EmailEditor from './TinyEmailEditor';
import { toastr } from 'react-redux-toastr';
import { MESSAGE_TYPE, NOTIFICATION_MESSAGE_TYPE } from '../../constants/index';
import { useSelector, useDispatch } from 'react-redux';
import { addOnForm } from '../../redux/actions/messageActions';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Typography
} from '@material-ui/core';
import campaignApi from '../../api/campaignApi';
import SendMessageTest from './SendMessageTest';
import Preview from './Preview';
import MergeTagsBox from '../../collums-components/components/common/MergeTagsBox';
import NotificationApi from '../../api/notificationApi';

const MERGE_TAGS = [
    'ClientTitle',
    'ClientFirstName',
    'ClientSurname',
    'ApptStart',
    'ApptDate',
    'ApptYear',
    'ApptDay',
    'StaffTitle',
    'StaffFirstName',
    'StaffSurname',
    'StaffDispName',
    'ClinicEmail',
    'ClinicTel',
    'BookingLink'
];

function MessageForm({ classes, isEditing, isCampaignForm, setTemplates, filter, setDisplayConfirmationModal }) {
    const editor = useRef(null);
    const editorRef = useRef(null);
    const lastClickedElement = useRef(null);
    const dispatch = useDispatch();
    const form = useSelector((state) => state.message);
    const [loadingUpload, setLoadingUpload] = useState({ visible: false, status: 'loading' });
    const [selectionPosition, setSelectionPosition] = useState(0);
    const [smsMessage, setSMSMessage] = useState({
        value: form.content || '',
        cursor: form.content && form.content.length ? form.content.length : 0
    });
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [templateContent, setTemplateContent] = useState();
    const [selectedTag, setSelectedTag] = useState(null);
    const [templateName, setTemplateName] = useState();
    const [templateList, setTemplateList] = useState([]);
    const [displayTemplateDialog, setDisplayTemplateDialog] = useState(false);
    const [previewVisibility, setPreviewVisibility] = useState(false);
    const [pointerPosition, setPointerPosition] = useState(0);

    const globalButtonsStyles = makeStyles(modalsButtonStyles)();

    useEffect(() => {
        campaignApi.queryTemplate({ type: form.format }).then((res) => {
            setTemplateList(res.data);
        });
    }, [form.format]);

    useEffect(() => {
        if (selectedTemplate.length > 0) {
            const template = templateList.find((el) => el.name === selectedTemplate);
            if (form.format === MESSAGE_TYPE.EMAIL) {
                editor.current.value = template.content;
                dispatch(addOnForm({ content: template.content, subject: template.subject || form.subject }));
            } else if (form.format === MESSAGE_TYPE.SMS) {
                dispatch(addOnForm({ content: template.content, subject: template.subject || form.subject }));
                setTemplateContent(template.content);
                setSMSMessage({ value: template.content, cursor: 0 });
            }
        }
        /* eslint-disable-next-line */
    }, [selectedTemplate, setSelectedTemplate]);
    /* eslint-disable-next-line */
    String.prototype.splice = function (idx, rem, str) {
        return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
    };

    const addTag = (key) => {
        if (form.format === MESSAGE_TYPE.EMAIL) {
            if (lastClickedElement.current && lastClickedElement.current.id === 'subject') {
                if (!pointerPosition) {
                    dispatch(addOnForm({ subject: `{${key}}` }));
                } else if (form.subject) {
                    dispatch(
                        addOnForm({
                            subject:
                                form.subject.substring(0, pointerPosition) +
                                `{${key}}` +
                                form.subject.substring(pointerPosition, form.subject.length)
                        })
                    );
                }
                setPointerPosition(pointerPosition + `{${key}}`.length);
                setTimeout(() => {
                    lastClickedElement.current.focus();
                }, 10);
            } else {
                editor.current.insertContent(`{${key}}`);
                dispatch(addOnForm({ content: editor.current.getContent() }));
            }
        } else if (form.format === MESSAGE_TYPE.SMS) {
            const nextPosition = selectionPosition + key.length + 2;
            editorRef.current.value = editorRef.current.value.splice(selectionPosition, 0, `{${key}}`);
            setSelectedTag(key);

            const data = {
                value: editorRef.current.value,
                cursor: nextPosition
            };

            setSMSMessage(data);
            setSelectionPosition(nextPosition);
            setTimeout(() => {
                editorRef.current.focus();
            }, 10);
            dispatch(addOnForm({ content: data.value }));
        }
    };

    const handleSaveTemplate = () => {
        campaignApi
            .saveTemplate({
                name: templateName,
                content: form.content,
                type: form.format,
                subject: form.subject,
                description: form.description
            })
            .then((res) => {
                setTemplateList([...templateList, res]);
                if (setTemplates && filter) {
                    campaignApi.queryTemplate(filter).then((res) => {
                        setTemplates(res);
                    });
                }
                toastr.success('Your template was saved.');
            })
            .catch((err) => {
                if (err.status === 409) toastr.error('There is already a template with that name');
            });

        setDisplayTemplateDialog(false);
    };

    const saveTemplateOnClick = () => {
        if (!isEditing) {
            setDisplayTemplateDialog(true);
        } else {
            if (!form.isNotification) {
                campaignApi
                    .updateTemplate({
                        id: form.id,
                        content: form.content,
                        subject: form.subject,
                        description: form.description
                    })
                    .then(() => {
                        campaignApi.queryTemplate(filter).then((res) => {
                            setTemplates(res);
                            toastr.success('Your template was updated');
                        });
                    });
            } else {
                NotificationApi.updateTemplate(
                    {
                        content: form.content,
                        subject: form.subject,
                        description: form.description
                    },
                    form.id
                ).then(() => {
                    campaignApi.queryTemplate(filter).then((res) => {
                        setTemplates(res);
                        toastr.success('Your template was updated');
                    });
                });
            }
        }
    };

    const isEmailType = (type) => {
        return type === MESSAGE_TYPE.EMAIL || type === NOTIFICATION_MESSAGE_TYPE.EMAIL;
    };

    return (
        <>
            {isCampaignForm && (
                <Box mb={4}>
                    <FormControl classes={{ root: classes.selectRoot }} style={{ width: '50%' }}>
                        <InputLabel className={classes.selectLabel}>Template</InputLabel>
                        <Select
                            variant="outlined"
                            value={selectedTemplate}
                            onChange={(e) => setSelectedTemplate(e.target.value)}
                        >
                            <MenuItem disabled value="">
                                <em>Choose template</em>
                            </MenuItem>
                            {templateList.map((template, index) => {
                                return (
                                    <MenuItem key={index} value={template.name}>
                                        {template.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Box>
            )}

            {isEditing && (
                <Box mb={4}>
                    <Typography>Template: {form.name}</Typography>
                </Box>
            )}

            {isEmailType(form.format) && (
                <>
                    <Box mb={1}>
                        <TextField
                            id={'subject'}
                            onFocus={() => {
                                if (lastClickedElement.current) {
                                    lastClickedElement.current.setSelectionRange(pointerPosition, pointerPosition);
                                }
                            }}
                            value={form.subject || ''}
                            onChange={(e) => {
                                setPointerPosition(e.target.selectionStart);
                                dispatch(addOnForm({ subject: e.target.value }));
                            }}
                            style={{ width: '50%' }}
                            variant="outlined"
                            onClick={(el) => {
                                setPointerPosition(el.target.selectionStart);
                                lastClickedElement.current = el.target;
                                //el.target.setSelectionRange(el.target.selectionStart, el.target.selectionStart)
                            }}
                            label="Subject"
                        />
                    </Box>
                </>
            )}
            {!isCampaignForm && (
                <Box mb={4}>
                    <TextField
                        id={'description'}
                        value={form.description || ''}
                        onChange={(e) => {
                            dispatch(addOnForm({ description: e.target.value }));
                        }}
                        style={{ width: '50%' }}
                        variant="outlined"
                        label="Description"
                    />
                </Box>
            )}

            <Box display="flex" flexDirection="row" style={{ marginBottom: '1rem', width: '100%' }}>
                <Box display="flex" flexDirection="row" width="100%">
                    {isEmailType(form.format) && (
                        <Box
                            display="flex"
                            width="85%"
                            height="100%"
                            onClick={() => {
                                lastClickedElement.current = null;
                            }}
                        >
                            <EmailEditor
                                setLoadingUpload={setLoadingUpload}
                                loadingUpload={loadingUpload}
                                editor={editor}
                                onClick={() => {
                                    lastClickedElement.current = null;
                                }}
                            />
                        </Box>
                    )}
                    {form.format === MESSAGE_TYPE.SMS && (
                        <Box display="flex" flexDirection="column" width="100%" maxHeight="400px">
                            <SMSEditor
                                setSMSMessage={setSMSMessage}
                                smsMessage={smsMessage}
                                setSelectionPosition={setSelectionPosition}
                                selectionPosition={selectionPosition}
                                editorRef={editorRef}
                                templateContent={templateContent}
                                setTemplateContent={setTemplateContent}
                                selectedTag={selectedTag}
                                setSelectedTag={setSelectedTag}
                            />
                        </Box>
                    )}
                    <MergeTagsBox
                        marginTop={isEmailType(form.format) ? 0 : '29px'}
                        height={isEmailType(form.format) ? '400px' : '256px'}
                        mergeTags={MERGE_TAGS}
                        onClick={(element) => {
                            addTag(element);
                        }}
                    />
                </Box>
            </Box>
            <Box display="flex" flexDirection="row">
                {isCampaignForm && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => saveTemplateOnClick()}
                        className={`${globalButtonsStyles.baseButton} ${classes.testBtn}`}
                    >
                        Save template
                    </Button>
                )}
                {isEmailType(form.format) && (
                    <Button
                        variant="text"
                        color="primary"
                        style={{ color: '#3083EC', marginLeft: isCampaignForm ? 10 : 0 }}
                        onClick={() => setPreviewVisibility(!previewVisibility)}
                    >
                        Preview
                    </Button>
                )}
            </Box>
            <SendMessageTest format={form.format} />
            {!isCampaignForm && (
                <Box display="flex" justifyContent="center" margin="20px">
                    <Button
                        onClick={() => setDisplayConfirmationModal(true)}
                        variant="contained"
                        color="primary"
                        className={globalButtonsStyles.cancelButton}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className={`${globalButtonsStyles.confirmButton} ${classes.marginLeft8px}`}
                        onClick={() => saveTemplateOnClick()}
                    >
                        Save
                    </Button>
                </Box>
            )}
            <Dialog
                open={displayTemplateDialog}
                onClose={() => setDisplayTemplateDialog(false)}
                aria-labelledby="form-dialog-title"
            >
                <DialogContent>
                    <DialogContentText>
                        {form.content && form.content.length > 0
                            ? 'Please, type a name for your template.'
                            : 'Your email body is empty, please fill it before save as template.'}
                    </DialogContentText>
                    {form.content && form.content.length > 0 && (
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Template name"
                            value={templateName}
                            onChange={(e) => setTemplateName(e.target.value)}
                            type="text"
                            fullWidth
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        className={globalButtonsStyles.cancelButton}
                        onClick={() => setDisplayTemplateDialog(false)}
                        color="primary"
                    >
                        {form.content && form.content.length > 0 ? 'Cancel' : 'Ok'}
                    </Button>
                    {form.content && form.content.length > 0 && (
                        <Button
                            disabled={!templateName}
                            className={globalButtonsStyles.confirmButton}
                            onClick={handleSaveTemplate}
                            color="primary"
                            variant="contained"
                        >
                            Confirm
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <Preview
                subject={form.subject || ''}
                content={editor && editor.current ? editor.current.getContent() : ''}
                visible={previewVisibility}
                setVisibility={setPreviewVisibility}
            />
        </>
    );
}

MessageForm.propTypes = {
    classes: PropTypes.object.isRequired,
    isEditing: PropTypes.bool,
    isCampaignForm: PropTypes.bool,
    setTemplates: PropTypes.func,
    filter: PropTypes.object,
    setDisplayConfirmationModal: PropTypes.func
};

export default withStyles(styles)(MessageForm);
