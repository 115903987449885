import React, { useRef } from 'react';
import { withStyles, CircularProgress } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

import PropTypes from 'prop-types';

import { badgeLoading as bdStyles } from './styles';
import { useEffect } from 'react';

const BadgeLoading = ({ data, setVisibility, classes, style }) => {
    const { visible, status } = data;

    const timeoutRef = useRef(null);

    useEffect(() => {
        if (status !== 'loading') {
            if (timeoutRef.current !== null) clearTimeout(timeoutRef.current);

            timeoutRef.current = setTimeout(() => {
                timeoutRef.current = null;
                setVisibility();
            }, 1500);
        }
        /* eslint-disable-next-line */
    }, [timeoutRef, visible, status]);

    return (
        <div
            className={`${classes.badgeWraper} ${status === 'loading' ? classes.badgeLoading : classes.badgeComplete}`}
            style={{
                ...style,
                display: visible ? 'flex' : 'none'
            }}
        >
            <div className={classes.centerSpinner}>{status === 'loading' ? <CircularProgress /> : <CheckIcon />}</div>
            <div>{status === 'loading' ? 'Loading' : 'Complete'}</div>
        </div>
    );
};

BadgeLoading.propTypes = {
    data: PropTypes.object.isRequired,
    setVisibility: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    style: PropTypes.any
};

export default withStyles(bdStyles)(BadgeLoading);
