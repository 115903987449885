import React, { useEffect, useState } from 'react';

import api from '../../api/api';

import PropTypes from 'prop-types';
import { Modal, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { modal } from './styles';

const Preview = ({ classes, visible, setVisibility, content, subject }) => {
    const [now] = useState(new Date());
    const [html, setHTML] = useState('');
    const [parsedSubject, setParsedSubject] = useState('');
    useEffect(() => {
        if (visible) {
            api.request({
                method: 'POST',
                url: '/campaigns/parse',
                data: {
                    html: content,
                    subject
                }
            })
                .then((response) => {
                    setHTML(response.html);
                    setParsedSubject(response.subject);
                }) // Define parsed HTML with the Tags
                .catch(() => {
                    setHTML(content);
                    setParsedSubject(subject);
                }); // Define default content if there are errors in the parse flow
        }
    }, [visible, content, setHTML, setParsedSubject, subject]);

    const displayDate = () =>
        `${
            now.toDateString() // date
        } ${
            now.getHours() < 10 ? '0' : '' // fill full hour HH
        }${
            now.getHours() // hour
        }:${
            now.getMinutes() < 10 ? '0' : '' // fill full minute mm
        }${
            now.getMinutes() // minute
        }:${
            now.getSeconds() < 10 ? '0' : '' // fill full secconds ss
        }${
            now.getSeconds() // secconds
        }`;

    return (
        <Modal
            className={classes.modal}
            onClose={() => setVisibility(false)}
            open={visible}
            onConfirm={() => setVisibility(false)}
        >
            <div className={classes.modalHTMLWrapContent}>
                <div style={{ cursor: 'pointer', float: 'right' }} onClick={() => setVisibility(false)}>
                    <CloseIcon />
                </div>
                {/* Header email */}
                <div>
                    <div className={classes.modalHTMLHeader}>From: </div>
                    <div> from@example.com</div>
                    <div className={classes.modalHTMLHeader}>Subject: </div>
                    <div>
                        <b> {parsedSubject}</b>
                    </div>
                    <div className={classes.modalHTMLHeader}>Date: </div>
                    <div> {displayDate()}</div>
                    <div className={classes.modalHTMLHeader}>To: </div>
                    <div> to@example.com</div>
                </div>

                <hr className={classes.hrCustom} />

                {/* HTMl preview */}
                <div className={classes.modalHTMLContent} dangerouslySetInnerHTML={{ __html: html }} />
            </div>
        </Modal>
    );
};

Preview.propTypes = {
    classes: PropTypes.object,
    visible: PropTypes.func.isRequired,
    setVisibility: PropTypes.func.isRequired,
    content: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired
};

export default withStyles(modal)(Preview);
