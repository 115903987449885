import { MESSAGE_FORM_REDUCER } from '../../constants/index';

const initialState = {
    previous: null
};

function MessageReducer(state = initialState, action) {
    switch (action.type) {
        case MESSAGE_FORM_REDUCER.ADD_ON_FORM:
            return { ...state, ...action.payload };
        case MESSAGE_FORM_REDUCER.PREVIOUS_FORM:
            return { ...state, previous: action.payload };
        case MESSAGE_FORM_REDUCER.CLEAN_FIELDS:
            return {};
        default:
            return state;
    }
}

export default MessageReducer;
