import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

const serverErrorsRegex = new RegExp('401 Unauthorized', 'mi');
export function initSentry() {
    if (process.env.REACT_APP_SENTRY_DSN) {
        const environment = process.env.NODE_ENV;
        Sentry.init({
            dsn: process.env.REACT_APP_SENTRY_DSN,
            integrations: [new BrowserTracing()],
            ignoreErrors: [serverErrorsRegex],
            tracesSampleRate: environment === 'production' || environment === 'staging' ? 0.3 : 0,
            environment,
            release: process.env.REACT_APP_SENTRY_RELEASE ?? 'unreleased'
        });
    } else {
        console.error('Missing Environment Key: {REACT_APP_SENTRY_DSN}');
    }
}

export function setSentryUser(user) {
    if (user) {
        const { id, email, username, displayName } = user;

        Sentry.setUser({
            id,
            email: email?.address,
            username: username ?? displayName,
            ip_address: '{{auto}}'
        });

        return { user: { name: user?.displayName ?? '' } };
    } else {
        return {};
    }
}
