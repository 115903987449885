import { Box, Card, FormControl, InputLabel, MenuItem, Select, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { form as styles } from '../styles';
import CampaignFormActionButtons from './CampaignFormActionButtons';
/* import CheckboxTree from 'react-checkbox-tree';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox'; */
import segmentApi from '../../../api/segmentApi';
import { useSelector, useDispatch } from 'react-redux';
import { addOnForm } from '../../../redux/actions/messageActions';

/* const REGIONS = [
    {
        value: 'uk',
        label: 'UK',
        children: [
            {
                value: 'north',
                label: 'North',
                children: [
                    { value: 'manchester', label: 'Manchester' },
                    { value: 'leeds', label: 'Leeds' },
                    { value: 'liverpool', label: 'Liverpool' }
                ]
            },
            {
                value: 'south',
                label: 'South',
                children: [
                    { value: 'london', label: 'London' },
                    { value: 'southampton', label: 'Southampton' }
                ]
            }
        ]
    }
]; */

function CampaignFormRecipients({ classes, nextStep, setDisplayCancelModal }) {
    // const [expanded, setExpanded] = useState(['uk']);
    const [segments, setSegments] = useState([]);
    const [customersCount, setCustomersCount] = useState({
        emailAllowed: 0,
        smsAllowed: 0
    });
    const [counted, setCounted] = useState();

    const dispatch = useDispatch();
    const form = useSelector((state) => state.message);

    useEffect(() => {
        const doEffect = async () => {
            const result = await segmentApi.query();
            setSegments(result);
            if (form.segment) {
                segmentApi
                    .countCustomersForSegment(form.segment)
                    .then((result) => {
                        setCustomersCount(result);
                    })
                    .catch((err) => {
                        if (err) {
                            dispatch(addOnForm({ segment: undefined }));
                            setCustomersCount(0);
                        }
                    });
            }
        };

        doEffect();
        /* eslint-disable-next-line */
    }, []);
    const checkFormat = () => {
        setCounted(
            (customersCount && (form.format === 'EMAIL' ? customersCount.emailAllowed : customersCount.smsAllowed)) || 0
        );
    };

    useEffect(() => {
        checkFormat();
        // eslint-disable-next-line
    }, [customersCount, form.format]);

    return (
        <Card variant="elevation">
            <Box className={classes.marginTop32px}>
                <div className={classes.marginBottom32px}>
                    {/* <CheckboxTree
                        nodes={REGIONS}
                        checked={form.locations}
                        expanded={expanded}
                        onCheck={(checked) => dispatch(addOnForm({ locations: checked }))}
                        onExpand={(expanded) => setExpanded(expanded)}
                        showNodeIcon={false}
                        icons={{
                            check: <CheckBoxIcon style={{ color: '#72C2F6' }} />,
                            uncheck: <CheckBoxOutlineBlankIcon />,
                            halfCheck: <IndeterminateCheckBoxIcon style={{ color: '#72C2F6' }} />,
                            expandClose: <ArrowRightIcon />,
                            expandOpen: <ArrowDropDownIcon />
                        }}
                    /> */}
                </div>
                <FormControl variant="outlined" className={`${classes.marginBottom32px} ${classes.selectSegmentRoot}`}>
                    <InputLabel className={classes.selectSegmentLabel}>Choose segment *</InputLabel>
                    <Select
                        id="select"
                        value={form.segment || ''}
                        onChange={(event) => {
                            const segmentId = event.target.value;
                            dispatch(addOnForm({ segment: segmentId }));
                            segmentApi.countCustomersForSegment(segmentId).then((result) => setCustomersCount(result));
                        }}
                    >
                        <MenuItem disabled value="">
                            <em>Choose segment *</em>
                        </MenuItem>
                        {segments.map((segment) => (
                            <MenuItem key={segment.id} value={segment.id}>
                                {segment.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <div>
                    <b>Client count: </b>
                    <span>{counted}</span>
                </div>
                <CampaignFormActionButtons
                    next={nextStep}
                    enableNext={/* form.locations && form.locations.length > 0 &&  */ !!form.segment}
                    cancel={() => setDisplayCancelModal(true)}
                />
            </Box>
        </Card>
    );
}

CampaignFormRecipients.propTypes = {
    classes: PropTypes.object.isRequired,
    nextStep: PropTypes.func,
    setDisplayCancelModal: PropTypes.func
};

export default withStyles(styles)(CampaignFormRecipients);
