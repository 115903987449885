import moment from 'moment';

export const checkDifferences = (current, payload, exceptions = {}) => {
    const getId = (object) => object.id || object;
    // Check if it is the same object, if it is one of the exceptions it will be compared using it
    const isSameObject = (e1, e2, key) => {
        if (Object.keys(exceptions).includes(key)) {
            const newPos = exceptions[key];
            return getId(e1[newPos]) === getId(e2[newPos]);
        }
        return getId(e1) === getId(e2);
    };
    const isNotEqual = (e1, e2) => {
        if (moment.isMoment(e1) && moment.isMoment(e2)) {
            return e1.format() !== e2.format();
        }
        return e1 !== e2;
    };
    const changes = {};
    Object.keys(current).forEach((key) => {
        if (Array.isArray(current[key]) && payload[key]) {
            const toUpdate = current[key].filter((element) => payload[key].some((e) => isSameObject(e, element, key)));
            changes[key] = toUpdate
                .map((value) => {
                    return checkDifferences(
                        value,
                        payload[key].find((el) => isSameObject(el, value, key)),
                        exceptions
                    );
                })
                .filter((el) => el)
                .map((el) => ({ ...el, _update: true }));
            const toRemove = current[key]
                .filter((element) => !payload[key].some((e) => isSameObject(e, element, key)))
                .map((el) => ({ ...el, _delete: true }));
            const toInsert = payload[key]
                .filter((element) => !current[key].some((e) => isSameObject(e, element, key)))
                .map((el) => ({ ...el, _insert: true }));
            changes[key] = [...changes[key], ...toRemove, ...toInsert];
            // If it is an empty array, exclude from changes
            if (!changes[key].length) {
                delete changes[key];
            }
        } else if (payload[key] !== undefined && payload[key] !== null && isNotEqual(payload[key], current[key])) {
            changes[key] = payload[key];
        }
    });
    Object.keys(payload).forEach((key) => {
        if (!Object.keys(current).includes(key)) {
            changes[key] = payload[key];
        }
    });
    // If a field has changed, add the id, and if this object has one of the exceptions, it must be added as well
    if (Object.keys(changes).length === 1) {
        changes.id = current.id;
        Object.keys(current).forEach((key) => {
            if (Object.values(exceptions).includes(key) && !Object.keys(changes).includes(key)) {
                changes[key] = current[key];
            }
        });
    }
    // If there are no changes, return undefined
    return Object.keys(changes).length <= 0 ? undefined : changes;
};
