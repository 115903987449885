const style = (theme) => ({
    noShadow: {
        boxShadow: 'none',
        minHeight: '28px',
        height: '38px',
        '& button': {
            padding: 0
        }
    },
    whiteBar: {
        background: 'white',
        color: 'black'
    },
    tabIndicator: {
        backgroundColor: theme.palette.red.main,
        height: 0
    },
    tabsContainer: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '28px',
        height: '38px',
        backgroundColor: '#69767E',
        padding: 0
    },
    mainTab: {
        opacity: 1,
        fontSize: 15,
        fontWeight: 400,
        backgroundColor: '#69767E',
        color: 'white',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        '&.Mui-selected': {
            backgroundColor: '#565A65'
        },
        '& a': {
            color: 'white',
            height: '100%',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            textDecoration: 'none',
            textAlign: 'center',
            whiteSpace: 'nowrap'
        },
        maxWidth: 250,
        width: 250
    },
    subTab: {
        opacity: 1,
        fontSize: 12,
        fontWeight: 'bold',
        color: 'black',
        '& a': {
            color: 'black',
            width: '100%',
            height: '100%',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    }
});

export default style;
