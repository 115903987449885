import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CampaignList from './CampaignList';
import { Link } from 'react-router-dom';
import { Box, withStyles } from '@material-ui/core';
import { campaignListStyles as styles } from './styles';
import Button from '../common/Button';
import Api from '../../api/campaignApi';

const CampaignView = ({ classes }) => {
    const [messageCounter, setMessageCounter] = useState('');
    const [segmentsCounter, setSegmentsCounter] = useState('');

    const getMessageCounter = async () => {
        const res = await Api.messageCounterForMonth();
        setMessageCounter(res.nMessages);
        setSegmentsCounter(res.nSegments);
    };

    useEffect(() => {
        getMessageCounter();
    }, []);

    const renderCreateButtons = () => {
        return (
            <Box display="flex" flexDirection="row" justifyContent="space-between">
                <div>
                    <Link style={{ color: 'inherit' }} to="/campaigns/new/email">
                        <Button type="secondary" className={classes.marginRight12}>
                            New email
                        </Button>
                    </Link>
                    <Link style={{ color: 'inherit' }} to="/campaigns/new/sms">
                        <Button type="secondary">New SMS</Button>
                    </Link>
                </div>
                <div className={classes.countersDiv}>
                    <span>SMS messages sent this month: {messageCounter}</span>
                    <span>SMS segments sent this month: {segmentsCounter}</span>
                </div>
            </Box>
        );
    };
    return (
        <Box p={2}>
            {renderCreateButtons()}
            <CampaignList />
        </Box>
    );
};

CampaignView.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CampaignView);
