import api from './api';

const query = () =>
    api.request({
        method: 'GET',
        url: '/services'
    });

const getServiceCategoryAndTags = () =>
    api.request({
        method: 'GET',
        url: `/services/get-services-with-categories-and-tags?clinic=${localStorage.getItem('currentClinic')}`
    });

export default {
    query,
    getServiceCategoryAndTags
};
