import React from 'react';
import { Button as MaterialButton, Typography, withStyles, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { buttonStyles as styles } from './styles';
import { modalsButtonStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';

const Button = ({ type, onClick, className, classes, children }) => {
    const globalButtonsStyles = makeStyles(modalsButtonStyles)();

    const classesMap = {
        primary: {
            button: globalButtonsStyles.confirmButton
        },
        secondary: {
            button: `${globalButtonsStyles.baseButton} ${classes.secondaryButton}`
        },
        cancel: {
            button: globalButtonsStyles.cancelButton
        }
    };

    return (
        <MaterialButton
            className={classNames(classesMap[type].button, className)}
            variant={'cancel' === type ? 'outlined' : 'text'}
            onClick={onClick}
        >
            <Typography>{children}</Typography>
        </MaterialButton>
    );
};

Button.propTypes = {
    type: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    children: PropTypes.any.isRequired
};

export default withStyles(styles)(Button);
