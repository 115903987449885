import api from './api';

const query = () =>
    api.request({
        method: 'GET',
        url: `/course/getCoursesCategoriesAndTags?clinic=${localStorage.getItem('currentClinic')}`
    });

export default {
    query
};
