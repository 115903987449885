import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const maxWidth = '470px';

// IF REUSE THIS COMPONENT, REMEMBER TO IMPORT THE ROBOTO FONT FROM THE HTML
// FILE ADDING <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500">

const CancelContinueModal = ({ setOpen, onContinue, title, contentText }) => {
    const cancelAction = () => {
        setOpen(false);
    };

    const confirmAction = () => {
        onContinue();
        setOpen(false);
    };

    return (
        <>
            <Dialog open={true} onClose={() => cancelAction()} aria-labelledby="exit-confirm-modal">
                <Title id="exit-confirm-modal">{title}</Title>
                <Content>
                    <Typography>{contentText}</Typography>
                </Content>
                <ActionsContainer>
                    <Button onClick={() => cancelAction()}>Cancel</Button>
                    <Button id="confirm-leave-button" onClick={() => confirmAction()}>
                        Continue
                    </Button>
                </ActionsContainer>
            </Dialog>
        </>
    );
};
CancelContinueModal.propTypes = {
    setOpen: PropTypes.func,
    onContinue: PropTypes.func,
    title: PropTypes.string,
    contentText: PropTypes.string
};
export default CancelContinueModal;

const Title = styled(DialogTitle)`
    h2 {
        font-size: 16px;
        font-weight: 500;
        text-align: center;
    }
`;

const Content = styled(DialogContent)`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: ${maxWidth};
    p {
        font-size: 14px;
    }
`;

const ActionsContainer = styled(DialogActions)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0.5rem 3rem;
    & button {
        width: 80px;
        height: 38px;
        border-radius: 0;
        margin-bottom: 16px;
        text-transform: none;
        font-size: 14px;
        font-weight: 500;
        padding: 6px 16px;
        border: 1px solid #000;
    }

    & button:nth-child(2) {
        background-color: #b41778;
        color: white;
        border: 0px solid #000;
    }
`;
