import api from './config';

class TagsApi {
    static listTags(query) {
        const queryString = query
            ? `archived=${query.archived || false}` +
              `${query.type !== '' ? `&type=${query.type}` : ''}` +
              `${query.name !== '' ? `&name=${query.name}` : ''}` +
              `&inactive=${query.inactive || false}`
            : 'archived=false';
        if (api.params.headers.Authorization === null) {
            api.resetToken(localStorage.getItem('token'));
        }
        return api.request({
            method: 'GET',
            url: `/tags?${queryString}`
        });
    }

    static listTypes() {
        if (api.params.headers.Authorization === null) {
            api.resetToken(localStorage.getItem('token'));
        }
        return api.request({
            method: 'GET',
            url: '/tags/types'
        });
    }

    static archiveTag(id) {
        if (api.params.headers.Authorization === null) {
            api.resetToken(localStorage.getItem('token'));
        }
        return api.request({
            method: 'PUT',
            url: `/tags/${id}/archive`
        });
    }

    static getTag(id) {
        if (api.params.headers.Authorization === null) {
            api.resetToken(localStorage.getItem('token'));
        }
        return api.request({
            method: 'GET',
            url: `/tags/${id}`
        });
    }

    static updateTag(id, data) {
        if (api.params.headers.Authorization === null) {
            api.resetToken(localStorage.getItem('token'));
        }
        return api.request({
            method: 'PUT',
            url: `/tags/${id}`,
            data
        });
    }

    static deleteTag(id) {
        if (api.params.headers.Authorization === null) {
            api.resetToken(localStorage.getItem('token'));
        }
        return api.request({
            method: 'DELETE',
            url: `/tags/${id}`
        });
    }

    static createTag(data) {
        if (api.params.headers.Authorization === null) {
            api.resetToken(localStorage.getItem('token'));
        }
        return api.request({
            method: 'POST',
            url: '/tags',
            data
        });
    }

    static changeTagActive(id) {
        if (api.params.headers.Authorization === null) {
            api.resetToken(localStorage.getItem('token'));
        }
        return api.request({
            method: 'PUT',
            url: `/tags/${id}/change-active`
        });
    }

    static getTagsByIds(ids) {
        return api.request({
            method: 'GET',
            url: `/tags/multiple/${ids}`
        });
    }
}

export default TagsApi;
