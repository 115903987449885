import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import Moment from 'moment';
import classNames from 'classnames';

import { dateInputStyles as styles } from './styles';

const DateInput = ({ value, onInit = () => {}, minDate, onChange, className, blackBorder, classes, ...props }) => {
    useEffect(() => {
        onInit(Moment().format('YYYY-MM-DD'));
        // eslint-disable-next-line
    }, []);

    const handleDateChange = (event) => {
        const date = event.target
            ? Moment(event.target.value, 'DD/MM/YY').format('YYYY-MM-DD')
            : Moment(event).format('YYYY-MM-DD');
        onChange(date);
    };

    const initialAppointmentDate = Moment(value, 'YYYY-MM-DD').toDate();

    const datePickerInputRef = React.useRef();

    const formatDateWithAppend = (string) => {
        //in case user input using tab the string param will recieve only the letter inputed.
        if (string.length === 1) {
            let newString = Moment(value, 'YYYY-MM-DD').format('DD/MM/YY');
            newString.split('').splice(0, 1, string).join('');
            return newString;
        }

        const inputPosition = datePickerInputRef.current.selectionStart;
        const cleanString = string.replace(/\//g, '');
        const addedString = string.charAt(inputPosition - 1);
        const cleanInputPosition = (() => {
            if (inputPosition > 6) return inputPosition - 2;
            if (inputPosition > 3) return inputPosition - 1;
            return inputPosition;
        })();
        const previousString =
            cleanString.substring(0, cleanInputPosition) +
            cleanString.substring(cleanInputPosition + 1, cleanString.length);
        let newString = previousString;
        newString.split('').splice(cleanInputPosition, 1, addedString).join('');

        return newString[0] + newString[1] + '/' + newString[2] + newString[3] + '/' + newString[4] + newString[5];
    };

    const handleClearDatePickerKeyboard = (event) => {
        //backspace(8) or delete(46) key
        if (event.keyCode === 8 || event.keyCode === 46) {
            if (datePickerInputRef.current.createTextRange) {
                var part = datePickerInputRef.current.createTextRange();
                part.move('character', 0);
                part.select();
            } else if (datePickerInputRef.current.setSelectionRange) {
                datePickerInputRef.current.setSelectionRange(0, 0);
            }
            datePickerInputRef.current.focus();
            handleDateChange(initialAppointmentDate);
        }
    };

    const classesArray = [classes.root, className];
    blackBorder && classesArray.push(classes.blackBorder);

    return (
        <KeyboardDatePicker
            className={classNames(...classesArray)}
            autoOk
            disableToolbar
            rifmFormatter={formatDateWithAppend}
            inputProps={{
                ref: datePickerInputRef,
                onKeyDown: handleClearDatePickerKeyboard
            }}
            minDate={minDate}
            variant="inline"
            format="dd/MM/yy"
            mask="__/__/__"
            maskChar={'_'}
            inputVariant="outlined"
            value={value ? Moment(value, 'YYYY-MM-DD').toDate() : null}
            onAccept={handleDateChange}
            onBlur={handleDateChange}
            onChange={() => {}}
            KeyboardButtonProps={{
                'aria-label': 'change date'
            }}
            {...props}
        />
    );
};

DateInput.propTypes = {
    value: PropTypes.string,
    onInit: PropTypes.func,
    onChange: PropTypes.func,
    blackBorder: PropTypes.bool,
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    minDate: PropTypes.object
};

export default withStyles(styles)(DateInput);
