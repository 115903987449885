import React from 'react';
import PropTypes from 'prop-types';
import CancelContinueModal from '../../collums-components/components/common/CancelContinueModal';

const DeleteConfirmationModal = ({ deleteCampaignId, setDeleteCampaignId, deleteCampaign }) => {
    return (
        <CancelContinueModal
            open={deleteCampaignId !== ''}
            onCancel={() => setDeleteCampaignId('')}
            onContinue={() => {
                deleteCampaign(deleteCampaignId);
                setDeleteCampaignId('');
            }}
            continueButtonText={'Delete'}
            title={'Delete Campaign'}
            contentText={'Are you sure you want to continue?'}
        />
    );
};

DeleteConfirmationModal.propTypes = {
    deleteCampaignId: PropTypes.string,
    setDeleteCampaignId: PropTypes.func,
    deleteCampaign: PropTypes.func
};

export default DeleteConfirmationModal;
