export function isInvalidCustomerRule(rule) {
    if (!rule.customerCondition) return true;
    if (rule.customerCondition === 'age') {
        if (rule.operator === 'between' && rule.ageFrom && rule.ageTo) return false;
        if (!rule.ageValue) return true;
    }
    if (rule.customerCondition === 'birthday' && !rule.operator) return true;
    if (rule.customerCondition === 'birthday' && !isNaN(rule.operator) && !rule.dateMoment) return true;
    return false;
}

export function isInvalidAppointmentRule(rule) {
    if (!rule.service) return true;
    if (rule.appointmentCondition === 'appointmentFrequency' && !rule.operator) {
        return true;
    }
    if (
        rule.type === 'appointment' &&
        rule.operator === 'moreThan' &&
        rule.appointmentCondition === 'futureAppointment' &&
        (!rule.occurrenceValue || !rule.occurrenceUnit)
    )
        return true;
    if (!rule.appointmentCondition) return true;

    if (rule.type === 'appointment') {
        if (!rule.practitionerRuleCondition) return true;
        if (!rule.practitionerRule) return true;
        if (rule?.practitionerRuleCondition === 'is not' && rule?.practitionerRule === 'any') return true;
    }

    if (
        (rule.appointmentCondition === 'hasHadBefore' || rule.appointmentCondition === 'hasNotHadBefore') &&
        !rule.haveAppointmentInTheFuture
    )
        return true;
    if (rule.operator === 'any' && rule.occurrenceValue && rule.occurrenceUnit) return false;
    if (rule.appointmentCondition === 'lastAppointment') {
        if (!rule.canHaveFutureBooking || !rule.operator) return true;
        if (rule.operator !== 'between' && (!rule.occurrenceUnit || !rule.occurrenceValue)) return true;
        if (rule.operator === 'between' && (!rule.occurrenceFrom || !rule.occurrenceTo)) return true;
    }

    if (
        rule.operator === 'between' &&
        (!rule.occurrenceFrom || !rule.occurrenceTo) &&
        (!rule.frequencyFrom || !rule.frequencyTo)
    )
        return true;

    if (rule.operator === 'exactly') {
        if (rule.appointmentCondition === 'nextAppointment') {
            if (!rule.occurrenceValue || !rule.occurrenceUnit || parseInt(rule.occurrenceValue) < 0) {
                return true;
            }
        } else {
            if (!rule.canHaveFutureBooking && !rule.frequencyValue && !rule.frequencyTimesUnit) {
                return true;
            }
        }
    }

    return false;
}

export function isInvalidPractitionerRule(rule) {
    if (!rule.practitionerCondition || !rule.practitioner) return true;
    return false;
}

export function isInvalidProductRule(rule) {
    if (!rule.products || !rule.productCondition || !rule.operator || !rule.repurchasedSince) return true;
    if (rule.operator !== 'between' && (!rule.dateType || !rule.productFrom)) return true;
    if (rule.operator === 'between' && (!rule.productDateTo || !rule.productDateFrom)) return true;
    return false;
}

export function isInvalidCourseRule(rule) {
    if (!rule.course || !rule.courseCondition || (!rule.courseFrom && !rule.courseDateFrom) || !rule.operator)
        return true;
    if (rule.courseCondition !== 'remaining' && rule.operator !== 'between' && !rule.dateType && rule.type === 'course')
        return true;
    if (
        rule.type === 'course' &&
        rule.operator === 'between' &&
        rule.courseCondition !== 'remaining' &&
        (!rule.courseDateTo || !rule.courseDateFrom)
    )
        return true;
    return false;
}
