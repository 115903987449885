import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { IconButton, Typography, withStyles } from '@material-ui/core';

import Select from '../common/Select';
import DateInput from '../common/DateInput';
import TextField from '../common/TextField';
import Autocomplete from '../common/Autocomplete';
import SimpleTagsSelector from '../../collums-components/form/SimpleTagsSelector';

import { GENDERS, REFERRAL_SOURCES_TYPE } from '../../collums-constants';

import CountriesApi from '../../api/countriesApi';

import { dynamicSegmentRuleStyles as styles } from './styles';
import ListApi from "../../collums-components/api/ListApi";

const DynamicSegmentRule = ({
    index,
    rule,
    categories,
    practitioners,
    products,
    courses,
    onChange,
    onDelete,
    classes
}) => {
    const [countriesList, setCountriesList] = useState([]);
    const [referralSourceType, setReferralSourceType] = useState(Object.values(REFERRAL_SOURCES_TYPE).sort());

    useEffect(() => {
        ListApi.getAll().then(lists => {
            const referralSource = lists.find(el => el.sys_name === 'client_referral_sources');
            if (referralSource) {
                setReferralSourceType(referralSource.values.split('\n'));
            }
        });
        //eslint-disable-next-line
    }, []);

    const ruleChecker = {
        isPractitionerRuleVisible: () => {
            if ('appointment' !== rule.type || !rule.appointmentCondition) {
                return false;
            }

            return !(
                (rule.appointmentCondition !== 'lastAppointment' &&
                    rule.appointmentCondition !== 'nextAppointment' &&
                    rule.appointmentCondition !== 'appointmentFrequency') ||
                !rule?.operator
            );
        },
        isPractitionerRuleVisibleForFutureAppointment: () => {
            if ('appointment' !== rule.type || !rule.appointmentCondition) {
                return false;
            }

            return !(rule.appointmentCondition !== 'hasHadBefore' && rule.appointmentCondition !== 'hasNotHadBefore');
        }
    };

    const renderTypeCondition = () => {
        const options = [
            { value: 'appointment', label: 'Appointment' },
            { value: 'course', label: 'Course' },
            { value: 'customer', label: 'Client' },
            { value: 'practitioner', label: 'Practitioner' },
            { value: 'product', label: 'Product' }
        ];
        return <Select value={rule.type} onChange={(value) => onChange(index, 'type', value)} options={options} />;
    };

    const renderCustomerCondition = () => {
        if ('customer' !== rule.type) {
            return null;
        }
        const options = [
            { value: 'age', label: 'Age' },
            { value: 'birthday', label: 'Birthday' },
            { value: 'city', label: 'City' },
            { value: 'creationAnniversary', label: 'Creation anniversary' },
            { value: 'creationDate', label: 'Creation date' },
            { value: 'country', label: 'Country' },
            { value: 'gender', label: 'Gender' },
            { value: 'referralSource', label: 'Referral source' },
            { value: 'state', label: 'State' },
            { value: 'tag', label: 'Tag' }
        ];
        return (
            <Select
                value={rule.customerCondition || ''}
                onChange={(value) => onChange(index, 'customerCondition', value)}
                options={options}
            />
        );
    };

    const renderPractitionerRule = (skipRuleCheck = false) => {
        if (!skipRuleCheck && !ruleChecker.isPractitionerRuleVisible()) {
            return null;
        }

        const options = [
            { value: 'is', label: 'is' },
            { value: 'is not', label: 'is not' }
        ];

        const practitionersOptions = [...practitioners];

        if (
            (!practitionersOptions?.length || practitionersOptions[0]?.value !== 'any') &&
            rule?.practitionerRuleCondition !== 'is not'
        ) {
            practitionersOptions.unshift({ label: 'Any', value: 'any' });
        }

        if (!rule?.practitionerRule && rule?.practitionerRuleCondition === 'is') {
            rule.practitionerRule = 'any';
        }

        return (
            <>
                <Typography className={classes.ruleInlineLabel}>Practitioner</Typography>
                <Select
                    value={rule.practitionerRuleCondition || ''}
                    onChange={(value) => onChange(index, 'practitionerRuleCondition', value)}
                    options={options}
                />
                <Autocomplete
                    className={classes.rulePractitionersInput}
                    value={rule.practitionerRule}
                    onChange={(value) => onChange(index, 'practitionerRule', value)}
                    options={practitionersOptions || []}
                    renderInput={(params) => <TextField {...params} style={{ width: 300 }} />}
                />
            </>
        );
    };

    const renderPractitionerCondition = () => {
        if ('practitioner' !== rule.type) {
            return null;
        }
        const options = [
            { value: 'is', label: 'is' },
            { value: 'is not', label: 'is not' }
        ];
        return (
            <Select
                className={classes.ruleSmallInput}
                value={rule.practitionerCondition || ''}
                onChange={(value) => onChange(index, 'practitionerCondition', value)}
                options={options}
            />
        );
    };

    const renderPractitioners = () => {
        if ('practitioner' !== rule.type) {
            return null;
        }
        return (
            <Autocomplete
                className={classes.rulePractitionersInput}
                value={rule.practitioner}
                onChange={(value) => onChange(index, 'practitioner', value)}
                options={practitioners || []}
                renderInput={(params) => <TextField {...params} style={{ width: 300 }} />}
            />
        );
    };

    const renderServices = () => {
        if ('appointment' !== rule.type) {
            return null;
        }
        const options = categories.map((item) => ({ value: item.id, label: item.name }));
        options.unshift({ label: 'Any', value: 'any' });
        if (!rule.service && rule.appointmentCondition) {
            rule.service = 'any';
        }
        return (
            <Autocomplete
                className={classes.ruleServicesInput}
                value={rule.service}
                onChange={(value) => onChange(index, 'service', value)}
                options={options}
                renderInput={(params) => <TextField {...params} style={{ width: 300 }} />}
            />
        );
    };

    const renderProducts = () => {
        if ('product' !== rule.type || !rule.productCondition) {
            return null;
        }
        return (
            <Autocomplete
                isMultiple={true}
                className={classes.ruleProductInput}
                value={rule.products || []}
                onChange={(value) => {
                    onChange(index, 'products', value);
                }}
                options={products}
            />
        );
    };

    const renderCoursesCondition = () => {
        if ('course' !== rule.type) {
            return null;
        }
        const options = [
            { value: 'expired', label: 'expired' },
            { value: 'purchased', label: 'purchased' },
            { value: 'remaining', label: 'remaining' },
            { value: 'willExpire', label: 'will expire' }
        ];
        return (
            <Select
                value={rule.courseCondition || ''}
                onChange={(value) => {
                    onChange(index, 'courseCondition', value);
                }}
                options={options}
            />
        );
    };

    const renderCourses = () => {
        if ('course' !== rule.type || !rule.courseCondition) {
            return null;
        }
        return (
            <Select
                className={classes.ruleServicesInput}
                value={rule.course || []}
                onChange={(value) => {
                    onChange(index, 'course', value);
                }}
                options={courses}
            />
        );
    };

    const renderProductCondition = () => {
        if (rule.type !== 'product') {
            return null;
        }
        const options = [
            { value: 'did not purchase', label: "Didn't purchase" },
            { value: 'purchased', label: 'Purchased' }
        ];
        return (
            <Select
                value={rule.productCondition || ''}
                onChange={(value) => onChange(index, 'productCondition', value)}
                options={options}
            />
        );
    };

    const renderProductOperator = () => {
        if (rule.type !== 'product' || !rule.productCondition || !rule.products) {
            return null;
        }
        const options = [
            { value: 'exactly', label: 'exactly' },
            { value: 'between', label: 'between' },
            { value: 'lessThan', label: 'less than' },
            { value: 'moreThan', label: 'more than' }
        ];
        return (
            <Select
                value={rule.operator || ''}
                onChange={(value) => {
                    onChange(index, 'operator', value);
                }}
                options={options}
            />
        );
    };

    const renderProductInputs = () => {
        if (rule.type !== 'product' || !rule.productCondition || !rule.products || !rule.operator) {
            return null;
        }
        const isSingleInput = rule.operator !== 'between';
        const timeUnit = [
            { label: 'Days', value: 'days' },
            { label: 'Weeks', value: 'weeks' },
            { label: 'Months', value: 'months' },
            { label: 'Years', value: 'years' }
        ];
        if (isSingleInput) {
            return (
                <>
                    <TextField
                        className={classes.ruleSmallInput}
                        type="number"
                        value={rule.productFrom || ''}
                        onChange={(value) => onChange(index, 'productFrom', value)}
                    />
                    <Select
                        value={rule.dateType || ''}
                        onChange={(value) => onChange(index, 'dateType', value)}
                        options={timeUnit}
                    />
                    <Typography className={classes.ruleInlineLabel}>ago</Typography>
                </>
            );
        }
        return (
            <>
                <DateInput
                    blackBorder
                    value={rule.productDateFrom}
                    onInit={(value) => {
                        if (rule.productDateFrom) return;
                        onChange(index, 'productDateFrom', value);
                    }}
                    onChange={(value) => onChange(index, 'productDateFrom', value)}
                />
                <Typography className={classes.ruleInlineLabel}>and</Typography>
                {rule.productDateFrom && (
                    <DateInput
                        blackBorder
                        value={rule.productDateTo}
                        onInit={(value) => {
                            if (rule.productDateTo) return;
                            onChange(index, 'productDateTo', value);
                        }}
                        onChange={(value) => onChange(index, 'productDateTo', value)}
                    />
                )}
            </>
        );
    };

    const renderCourseInputs = () => {
        if (rule.type !== 'course' || !rule.courseCondition || !rule.course || !rule.operator) {
            return null;
        }
        const isSingleInput = rule.operator !== 'between';
        const timeUnit = [
            { label: 'Days', value: 'days' },
            { label: 'Weeks', value: 'weeks' },
            { label: 'Months', value: 'months' },
            { label: 'Years', value: 'years' }
        ];
        let nonEditableText = '';
        if (rule.courseCondition === 'willExpire') nonEditableText = 'from now';
        else if (rule.courseCondition === 'expired' || rule.courseCondition === 'purchased') nonEditableText = 'ago';
        if (isSingleInput) {
            return (
                <>
                    <TextField
                        className={classes.ruleSmallInput}
                        type="number"
                        value={rule.courseFrom || ''}
                        onChange={(value) => onChange(index, 'courseFrom', value)}
                    />
                    {rule.courseCondition !== 'remaining' && (
                        <Select
                            value={rule.dateType || ''}
                            onChange={(value) => onChange(index, 'dateType', value)}
                            options={timeUnit}
                        />
                    )}
                    <Typography className={classes.ruleInlineLabel}>{nonEditableText}</Typography>
                </>
            );
        }
        return (
            <>
                {rule.courseCondition === 'remaining' ? (
                    <>
                        <TextField
                            className={classes.ruleSmallInput}
                            type="number"
                            value={rule.courseFrom || ''}
                            onChange={(value) => onChange(index, 'courseFrom', value)}
                        />
                        <Typography className={classes.ruleInlineLabel}>and</Typography>
                        <TextField
                            className={classes.ruleSmallInput}
                            type="number"
                            value={rule.courseTo || ''}
                            onChange={(value) => onChange(index, 'courseTo', value)}
                        />
                    </>
                ) : (
                    <>
                        <DateInput
                            blackBorder
                            value={rule.courseDateFrom}
                            onInit={(value) => {
                                if (rule.courseDateFrom) return;
                                onChange(index, 'courseDateFrom', value);
                            }}
                            onChange={(value) => onChange(index, 'courseDateFrom', value)}
                        />
                        <Typography className={classes.ruleInlineLabel}>and</Typography>
                        {rule.courseDateFrom && (
                            <DateInput
                                blackBorder
                                value={rule.courseDateTo}
                                onInit={(value) => {
                                    if (rule.courseDateTo) return;
                                    onChange(index, 'courseDateTo', value);
                                }}
                                onChange={(value) => onChange(index, 'courseDateTo', value)}
                            />
                        )}
                    </>
                )}
            </>
        );
    };

    const renderAppointmentCondition = () => {
        if (!rule.service) {
            return null;
        }
        const options = [
            { value: 'appointmentFrequency', label: 'Frequency of appts' },
            { value: 'lastAppointment', label: 'Last appointment' },
            { value: 'nextAppointment', label: 'Next appointment' },
            { value: 'hasHadBefore', label: 'Has had before' },
            { value: 'hasNotHadBefore', label: 'Has not had before' }
            //{ value: 'futureAppointment', label: 'Expected next appointment' },
        ];
        return (
            <Select
                value={rule.appointmentCondition || ''}
                onChange={(value) => onChange(index, 'appointmentCondition', value)}
                options={options}
            />
        );
    };

    const renderOperator = () => {
        const excludedProps = ['gender', 'city', 'state', 'country', 'referralSource'];
        if (
            ((!rule.customerCondition || excludedProps.find((prop) => prop === rule.customerCondition)) &&
                !rule.appointmentCondition &&
                !rule.course) ||
            rule.appointmentCondition === 'hasNotHadBefore' ||
            rule.appointmentCondition === 'hasHadBefore'
        ) {
            return null;
        }
        const dateProps = ['birthday', 'creationAnniversary', 'creationDate'];
        const dateOptions = [
            { value: 'after', label: 'After' },
            { value: 'before', label: 'Before' },
            { value: 'between', label: 'Between' },
            { value: 'exactly', label: 'Exactly' }
        ];
        const dobOptions = new Array(31).fill(31).map((value, index) => {
            return { value: String(index + 1), label: String(index + 1) };
        });
        const timesOptions = [
            { value: 'exactly', label: 'Exactly' },
            { value: 'between', label: 'Between' },
            { value: 'lessThan', label: 'Less than' },
            { value: 'moreThan', label: 'More than' }
        ];
        const tagsOptions = [
            { value: 'all', label: 'All' },
            { value: 'any', label: 'Any' }
        ];
        let selectedOpts = timesOptions;

        if (dateProps.find((prop) => prop === rule.customerCondition)) {
            if (rule.customerCondition === 'birthday') {
                selectedOpts = [...dateOptions, ...dobOptions];
            } else selectedOpts = dateOptions;
        } else if (rule.customerCondition === 'tag') {
            selectedOpts = tagsOptions;
        }

        return (
            <>
                <Typography className={classes.ruleInlineLabel}>is</Typography>
                <Select
                    value={rule.operator || ''}
                    onChange={(value) => onChange(index, 'operator', value)}
                    options={selectedOpts}
                />
            </>
        );
    };

    const renderCustomerAgeValue = () => {
        if ('age' !== rule.customerCondition || !rule.operator || 'between' === rule.operator) {
            return null;
        }
        return (
            <>
                <TextField
                    className={classes.ruleSmallInput}
                    type="number"
                    value={rule.ageValue || ''}
                    onChange={(value) => onChange(index, 'ageValue', value)}
                />
                <Typography className={classes.ruleInlineLabel}>years old</Typography>
            </>
        );
    };

    const renderCustomerAgeBetween = () => {
        if ('age' !== rule.customerCondition || !rule.operator || 'between' !== rule.operator) {
            return null;
        }
        return (
            <>
                <TextField
                    className={classes.ruleSmallInput}
                    type="number"
                    value={rule.ageFrom || ''}
                    onChange={(value) => onChange(index, 'ageFrom', value)}
                />
                <Typography className={classes.ruleInlineLabel}>and</Typography>
                <TextField
                    className={classes.ruleSmallInput}
                    type="number"
                    value={rule.ageTo || ''}
                    onChange={(value) => onChange(index, 'ageTo', value)}
                />
                <Typography className={classes.ruleInlineLabel}>years old</Typography>
            </>
        );
    };

    const renderCustomerGender = () => {
        if ('gender' !== rule.customerCondition) {
            return null;
        }

        const options = Object.values(GENDERS).map((opt) => ({ value: opt, label: opt }));

        return (
            <>
                <Typography className={classes.ruleInlineLabel}>is</Typography>
                <Select
                    value={rule.gender || ''}
                    onChange={(value) => onChange(index, 'gender', value)}
                    options={options}
                />
            </>
        );
    };

    const renderCustomerReferral = () => {
        if ('referralSource' !== rule.customerCondition) {
            return null;
        }

        const options = referralSourceType.map((opt) => ({ value: opt, label: opt }));

        return (
            <>
                <Typography className={classes.ruleInlineLabel}>is</Typography>
                <Select
                    value={rule.referralSource || ''}
                    onChange={(value) => onChange(index, 'referralSource', value)}
                    options={options}
                />
            </>
        );
    };

    const renderCustomerDateType = () => {
        if (rule.customerCondition !== 'birthday' || isNaN(rule.operator) || !rule.operator) {
            return null;
        }
        const typeOptions = [
            { value: 'days', label: 'Days' },
            { value: 'weeks', label: 'Weeks' },
            { value: 'months', label: 'Months' },
            { value: 'years', label: 'Years' }
        ];

        return (
            <>
                <Select
                    value={rule.dateType || ''}
                    onChange={(value) => onChange(index, 'dateType', value)}
                    options={typeOptions}
                />
            </>
        );
    };

    const renderCustomerDateMoment = () => {
        if (rule.customerCondition !== 'birthday' || isNaN(rule.operator) || !rule.operator || !rule.dateType) {
            return null;
        }
        const typeOptions = [
            { value: 'future', label: 'Future' },
            { value: 'past', label: 'Past' }
        ];

        return (
            <>
                <Typography className={classes.ruleInlineLabel}>in the</Typography>
                <Select
                    value={rule.dateMoment || ''}
                    onChange={(value) => onChange(index, 'dateMoment', value)}
                    options={typeOptions}
                />
            </>
        );
    };

    const renderCustomerDateValue = () => {
        const dateProps = ['birthday', 'creationAnniversary', 'creationDate'];
        if (
            !dateProps.find((prop) => prop === rule.customerCondition) ||
            !rule.operator ||
            'between' === rule.operator ||
            !isNaN(rule.operator)
        ) {
            return null;
        }
        return (
            <DateInput
                blackBorder
                value={rule.customerDateValue}
                onInit={(value) => {
                    if (rule.customerDateValue) return;
                    onChange(index, 'customerDateValue', value);
                }}
                onChange={(value) => onChange(index, 'customerDateValue', value)}
            />
        );
    };

    const renderCustomerDateBetween = () => {
        const dateProps = ['birthday', 'creationAnniversary', 'creationDate'];
        if (
            !dateProps.find((prop) => prop === rule.customerCondition) ||
            !rule.operator ||
            'between' !== rule.operator
        ) {
            return null;
        }
        return (
            <>
                <DateInput
                    blackBorder
                    value={rule.customerDateFrom}
                    onInit={(value) => {
                        if (rule.customerDateFrom) return;
                        onChange(index, 'customerDateFrom', value);
                    }}
                    onChange={(value) => onChange(index, 'customerDateFrom', value)}
                />
                <Typography className={classes.ruleInlineLabel}>and</Typography>
                {rule.customerDateFrom && (
                    <DateInput
                        blackBorder
                        value={rule.customerDateTo}
                        onInit={(value) => {
                            if (rule.customerDateTo) return;
                            onChange(index, 'customerDateTo', value);
                        }}
                        onChange={(value) => onChange(index, 'customerDateTo', value)}
                    />
                )}
            </>
        );
    };

    const renderCustomerTag = () => {
        if ('tag' !== rule.customerCondition || !rule.operator) {
            return null;
        }
        return (
            <>
                <Typography className={classes.ruleInlineLabel}>of</Typography>
                <SimpleTagsSelector
                    onChange={(value) => onChange(index, 'tags', value)}
                    className={`${classes.ruleBigInput} ${classes.ruleTagsInput}`}
                    size={'small'}
                />
            </>
        );
    };

    const renderCustomerCountry = () => {
        if ('country' !== rule.customerCondition) {
            return null;
        }
        return (
            <>
                <Typography className={classes.ruleInlineLabel}>is</Typography>
                <Select
                    value={(rule.country && rule.country.value) || countriesList[0]}
                    onChange={(value) => {
                        const selectedCountry = countriesList.filter((current) => current.value === value);
                        onChange(index, 'country', selectedCountry[0]);
                    }}
                    options={countriesList}
                />
            </>
        );
    };

    const renderCustomerTextValue = () => {
        const textProps = ['city', 'state'];
        if (!textProps.find((prop) => prop === rule.customerCondition)) {
            return null;
        }
        return (
            <>
                <Typography className={classes.ruleInlineLabel}>is</Typography>
                <TextField value={rule.textValue || ''} onChange={(value) => onChange(index, 'textValue', value)} />
            </>
        );
    };

    const renderOccurrenceDateDetails = () => {
        if (
            'appointment' !== rule.type ||
            'appointmentFrequency' === rule.appointmentCondition ||
            'hasHadBefore' === rule.appointmentCondition ||
            'hasNotHadBefore' === rule.appointmentCondition ||
            !rule.operator ||
            'between' === rule.operator
        ) {
            return null;
        }
        const options = [
            { value: 'days', label: 'Days' },
            { value: 'weeks', label: 'Weeks' },
            { value: 'months', label: 'Months' },
            { value: 'years', label: 'Years' }
        ];

        const getLabel = () => {
            return rule.appointmentCondition === 'lastAppointment'
                ? `ago${ruleChecker.isPractitionerRuleVisible() ? '. ' : ''}`
                : `in the future${ruleChecker.isPractitionerRuleVisible() ? '. ' : ''}`;
        };

        return (
            <>
                <TextField
                    className={classes.ruleSmallInput}
                    type="number"
                    value={rule.occurrenceValue || ''}
                    onChange={(value) => onChange(index, 'occurrenceValue', value)}
                />
                <Select
                    value={rule.occurrenceUnit || ''}
                    onChange={(value) => onChange(index, 'occurrenceUnit', value)}
                    options={options}
                />
                <Typography className={classes.ruleInlineLabel}>{getLabel()}</Typography>
            </>
        );
    };

    const renderOccurrenceBetweenDates = () => {
        if (
            'appointment' !== rule.type ||
            'appointmentFrequency' === rule.appointmentCondition ||
            'hasHadBefore' === rule.appointmentCondition ||
            'hasNotHadBefore' === rule.appointmentCondition ||
            !rule.operator ||
            'between' !== rule.operator
        ) {
            return null;
        }
        return (
            <>
                <DateInput
                    blackBorder
                    value={rule.occurrenceFrom}
                    onInit={(value) => {
                        if (rule.occurrenceFrom) return;
                        onChange(index, 'occurrenceFrom', value);
                    }}
                    onChange={(value) => onChange(index, 'occurrenceFrom', value)}
                />
                <Typography className={classes.ruleInlineLabel}>and</Typography>
                {rule.occurrenceFrom && (
                    <DateInput
                        blackBorder
                        value={rule.occurrenceTo}
                        onInit={(value) => {
                            if (rule.occurrenceTo) return;
                            onChange(index, 'occurrenceTo', value);
                        }}
                        onChange={(value) => onChange(index, 'occurrenceTo', value)}
                    />
                )}
            </>
        );
    };

    const renderFrequencyTimesDetails = () => {
        if (
            !rule.operator ||
            'appointmentFrequency' !== rule.appointmentCondition ||
            'hasHadBefore' === rule.appointmentCondition ||
            'hasNotHadBefore' === rule.appointmentCondition ||
            'between' === rule.operator
        ) {
            return null;
        }
        return (
            <TextField
                className={classes.ruleSmallInput}
                type="number"
                value={rule.frequencyValue || ''}
                onChange={(value) => onChange(index, 'frequencyValue', value)}
            />
        );
    };

    const renderFrequencyBetweenTimes = () => {
        if (
            !rule.operator ||
            'appointmentFrequency' !== rule.appointmentCondition ||
            'hasHadBefore' === rule.appointmentCondition ||
            'hasNotHadBefore' === rule.appointmentCondition ||
            'between' !== rule.operator
        ) {
            return null;
        }
        return (
            <>
                <TextField
                    className={classes.ruleSmallInput}
                    type="number"
                    value={rule.frequencyFrom || ''}
                    onChange={(value) => onChange(index, 'frequencyFrom', value)}
                />
                <Typography className={classes.ruleInlineLabel}>and</Typography>
                <TextField
                    className={classes.ruleSmallInput}
                    type="number"
                    onInit={(value) => {
                        if (rule.frequencyTo) return;
                        onChange(index, 'customerDateTo', value);
                    }}
                    value={rule.frequencyTo || ''}
                    onChange={(value) => onChange(index, 'frequencyTo', value)}
                />
            </>
        );
    };

    const renderFrequencyLastPeriod = () => {
        if (!rule.operator || 'appointmentFrequency' !== rule.appointmentCondition) {
            return null;
        }
        const singularOptions = [
            { value: 'months', label: 'Month' },
            { value: 'years', label: 'Year' }
        ];
        const pluralOptions = [
            { value: 'months', label: 'Months' },
            { value: 'years', label: 'Years' }
        ];
        return (
            <>
                <Typography className={classes.ruleInlineLabel}>times per</Typography>
                <Select
                    value={rule.frequencyTimesUnit || ''}
                    onChange={(value) => onChange(index, 'frequencyTimesUnit', value)}
                    options={singularOptions}
                />
                <Typography className={classes.ruleInlineLabel}>in the last</Typography>
                <TextField
                    className={classes.ruleSmallInput}
                    type="number"
                    value={rule.frequencyLastPeriodValue || ''}
                    onChange={(value) => onChange(index, 'frequencyLastPeriodValue', value)}
                />
                <Select
                    value={rule.frequencyLastPeriodUnit || ''}
                    onChange={(value) => onChange(index, 'frequencyLastPeriodUnit', value)}
                    options={pluralOptions}
                />
            </>
        );
    };

    const renderHaveApptInTheFuture = () => {
        if (
            'appointment' !== rule.type ||
            !rule.appointmentCondition ||
            rule.appointmentCondition === 'lastAppointment' ||
            rule.appointmentCondition === 'nextAppointment' ||
            rule.appointmentCondition === 'appointmentFrequency'
        )
            return null;
        const options = [
            { value: 'does or does not', label: 'does or does not' },
            { value: 'does', label: 'does' },
            { value: 'does not', label: 'does not' }
        ];
        rule.haveAppointmentInTheFuture = rule.haveAppointmentInTheFuture || 'does or does not';
        return (
            <>
                <Typography className={classes.ruleInlineLabel}>and</Typography>
                <Select
                    className={classes.ruleSmallInput}
                    value={rule.haveAppointmentInTheFuture}
                    onChange={(value) => onChange(index, 'haveAppointmentInTheFuture', value)}
                    options={options}
                />
                <Typography className={classes.ruleInlineLabel}>have appointment in the future </Typography>
            </>
        );
    };

    const renderPractitionerRuleForFutureAppointment = () => {
        if (!ruleChecker.isPractitionerRuleVisibleForFutureAppointment()) {
            return null;
        }

        return renderPractitionerRule(true);
    };

    const renderHasOrNot = () => {
        if ('product' !== rule.type || !rule.productCondition || !rule.operator) return null;
        const options = [
            { value: 'has', label: 'Has' },
            { value: 'has not', label: 'Has not' },
            { value: 'has or has not', label: 'Has or has not' }
        ];
        return (
            <>
                <Typography className={classes.ruleInlineLabel}>and</Typography>
                <Select
                    className={classes.ruleSmallInput}
                    value={rule.repurchasedSince || ''}
                    onChange={(value) => onChange(index, 'repurchasedSince', value)}
                    options={options}
                />
                <Typography className={classes.ruleInlineLabel}>re-purchased since.</Typography>
            </>
        );
    };

    const renderCanHaveFutureAppts = () => {
        if (
            'appointment' !== rule.type ||
            !rule.operator ||
            rule.operator === 'any' ||
            rule.appointmentCondition === 'hasNotHadBefore' ||
            rule.appointmentCondition === 'hasHadBefore' ||
            rule.appointmentCondition === 'nextAppointment'
        )
            return null;
        const options = [
            { value: 'does', label: 'does' },
            { value: 'does not', label: 'does not' },
            { value: 'does or does not', label: 'does or does not' }
        ];

        return (
            <>
                <Typography className={classes.ruleInlineLabel}>and</Typography>
                <Select
                    className={classes.ruleSmallInput}
                    value={rule.canHaveFutureBooking || ''}
                    onChange={(value) => onChange(index, 'canHaveFutureBooking', value)}
                    options={options}
                />
                <Typography className={classes.ruleInlineLabel}>
                    {rule.service === 'any'
                        ? 'have any booking in the future'
                        : 'have any of these booked in the future'}
                </Typography>
            </>
        );
    };

    useEffect(() => {
        async function getCountriesList() {
            const res = await CountriesApi.getCountries();
            setCountriesList(res);
        }

        getCountriesList();
    }, []);

    return (
        <div>
            <Typography className={classes.ruleLabel}>Rule {index + 1}</Typography>
            <div key={index} className={classes.ruleRow}>
                {renderTypeCondition()}
                {renderCustomerCondition()}
                {renderPractitionerCondition()}
                {renderCoursesCondition()}
                {renderProductCondition()}
                {renderProducts()}
                {renderCourses()}
                {renderServices()}
                {renderAppointmentCondition()}
                {renderOperator()}
                {renderPractitionerRuleForFutureAppointment()}
                {renderHaveApptInTheFuture()}
                {renderProductOperator()}
                {renderProductInputs()}
                {renderCourseInputs()}
                {renderPractitioners()}
                {renderCustomerAgeValue()}
                {renderCustomerAgeBetween()}
                {renderCustomerGender()}
                {renderCustomerDateType()}
                {renderCustomerDateValue()}
                {renderCustomerDateBetween()}
                {renderCustomerDateMoment()}
                {renderCustomerReferral()}
                {renderCustomerTextValue()}
                {renderCustomerCountry()}
                {renderCustomerTag()}
                {renderOccurrenceDateDetails()}
                {renderOccurrenceBetweenDates()}
                {renderFrequencyTimesDetails()}
                {renderFrequencyBetweenTimes()}
                {renderFrequencyLastPeriod()}
                {renderPractitionerRule()}
                {renderCanHaveFutureAppts()}
                {renderHasOrNot()}
                <IconButton className={classes.deleteButton} onClick={() => onDelete(index)}>
                    <DeleteOutlineIcon />
                </IconButton>
            </div>
        </div>
    );
};

DynamicSegmentRule.propTypes = {
    index: PropTypes.number.isRequired,
    rule: PropTypes.object.isRequired,
    categories: PropTypes.array.isRequired,
    courses: PropTypes.array.isRequired,
    products: PropTypes.array.isRequired,
    practitioners: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DynamicSegmentRule);
