import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { campaignListStyles as styles } from './styles';
import {
    withStyles,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel,
    TableBody,
    Box,
    //Switch,
    //Typography,
    TextField,
    Select,
    InputLabel,
    FormControl,
    MenuItem,
    Tooltip,
    TablePagination,
    IconButton,
    InputAdornment,
    Button,
    Menu
} from '@material-ui/core';
import campaignApi from '../../api/campaignApi';
import segmentApi from '../../api/segmentApi';
import format from 'date-fns/format';
import _ from 'lodash';
import { Search } from '@material-ui/icons';

import { CAMPAIGN_TYPES, CAMPAIGN_STATUS } from '../../constants';
import { useHistory } from 'react-router-dom';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import CancelContinueModal from '../../collums-components/components/common/CancelContinueModal';
import { getCurrentTimezonedDate } from '../../collums-components/helpers/timezone';

const headCells = [
    { id: 'name', label: 'Name', roerdable: true },
    { id: 'segment', label: 'Target segment', roerdable: false },
    { id: 'format', label: 'Format', roerdable: false },
    { id: 'type', label: 'Type', roerdable: false },
    { id: 'createdAt', label: 'Created', roerdable: true },
    { id: 'status', label: 'Status', roerdable: false },
    { id: 'lastSent', label: 'Last sent', roerdable: true },
    { id: 'noSent', label: 'No sent', roerdable: true },
    { id: 'nextSend', label: 'Next send', roerdable: true },
    { id: 'description', label: 'Description', reordable: false },
    { id: 'deliver', label: 'Deliver', roerdable: true },
    { id: 'open', label: 'Open', roerdable: true },
    { id: 'clickThru', label: 'Click thru', roerdable: true },
    { id: 'conversions', label: 'Conv', roerdable: true },
    { id: 'bounce', label: 'Bounce', roerdable: true },
    { id: 'unsubscribed', label: 'Unsub', roerdable: true },
    { id: 'actions', label: '', roerdable: false }
];

const transformType = (type) => {
    return {
        [CAMPAIGN_TYPES.ONE_OFF]: 'One off',
        [CAMPAIGN_TYPES.SCHEDULED]: 'Scheduled',
        [CAMPAIGN_TYPES.RECURRING]: 'Recurring'
    }[type];
};

const standardizeType = (type) => {
    return {
        'One off': CAMPAIGN_TYPES.ONE_OFF,
        Scheduled: CAMPAIGN_TYPES.SCHEDULED,
        Recurring: CAMPAIGN_TYPES.RECURRING
    }[type];
};

const transformStatus = (status) => {
    return {
        [CAMPAIGN_STATUS.PAUSED]: 'Paused',
        [CAMPAIGN_STATUS.COMPLETED]: 'Completed',
        [CAMPAIGN_STATUS.WAITING]: 'Waiting to send',
        [CAMPAIGN_STATUS.DRAFT]: 'Draft',
        [CAMPAIGN_STATUS.ACTIVE]: 'Active'
    }[status];
};

const getPercentage = (value, total) => {
    if (!value) value = 0;
    if (!total) return '0%';
    return ((value * 100) / total).toFixed(1) + '%';
};
function CampaignList({ classes }) {
    const ActionButton = ({ row }) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const openMenu = (event) => {
            event.preventDefault();
            event.stopPropagation();
            setAnchorEl(event.currentTarget);
        };
        const closeMenu = (e) => {
            e.stopPropagation();
            setAnchorEl(null);
        };
        ActionButton.propTypes = {
            row: PropTypes.object
        };
        return (
            <>
                <Button onClick={openMenu} className={classes.grayButton}>
                    Options
                </Button>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
                    <MenuItem
                        onClick={(e) => {
                            e.stopPropagation();
                            pauseCampaign(row);
                            closeMenu(e);
                        }}
                    >
                        {row.status === 'Paused' ? 'Unpause' : 'Pause'}
                    </MenuItem>
                    <MenuItem
                        onClick={(e) => {
                            e.stopPropagation();
                            duplicateCampaign(row);
                            closeMenu(e);
                        }}
                    >
                        Copy
                    </MenuItem>
                    <MenuItem
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(row);
                            closeMenu(e);
                        }}
                    >
                        Delete
                    </MenuItem>
                </Menu>
            </>
        );
    };
    const [filter, setFilter] = useState({
        page: 0,
        pageSize: 25,
        order: '-createdAt'
    });
    const [campaigns, setCampaigns] = useState([]);
    const [totalCampaigns, setTotalCampaigns] = useState(0);
    const [showTracking] = useState(false);
    const [deleteCampaignId, setDeleteCampaignId] = useState('');
    const [deletingCampaign, setDeletingCampaign] = useState({ showModal: false, row: null });
    const history = useHistory();

    const refreshList = async () => {
        const campaigns = await campaignApi.query(filter);
        const segments = await segmentApi.query();
        setTotalCampaigns(campaigns.total);
        setCampaigns(
            campaigns.data.map((campaign) => {
                const CurrentSegment = segments.find((seg) => seg.id === campaign?.segment?.id);

                if (campaign.type) {
                    campaign.type.replace(/\s/g, '_');
                    campaign.type = campaign.type.toUpperCase();
                }

                if (!CurrentSegment) {
                    campaign.segment = 'undefined';
                    if (['RECURRING', 'SCHEDULED'].includes(campaign.type)) {
                        campaign.status = 'PAUSED';
                    }
                }

                return {
                    ...campaign,
                    type: transformType(campaign.type),
                    status: transformStatus(campaign.status),
                    createdAt: format(getCurrentTimezonedDate(campaign.createdAt), 'dd/LL/yyyy HH:mm'),
                    lastSent:
                        campaign.lastSent && format(getCurrentTimezonedDate(campaign.lastSent), 'dd/LL/yyyy HH:mm'),
                    nextSend:
                        campaign.nextSend && format(getCurrentTimezonedDate(campaign.nextSend), 'dd/LL/yyyy HH:mm')
                };
            })
        );
    };

    useEffect(() => {
        refreshList();
        /* eslint-disable-next-line */
    }, [filter]);

    const getSortProperty = () => {
        const order = filter.order;
        return order.replace('-', '');
    };

    const duplicateCampaign = async (data) => {
        const equalNames = campaigns
            .map((campaign) => campaign.name)
            .filter(
                (name) =>
                    name.startsWith(
                        data.name.slice(0, data.name.indexOf(' -') >= 0 ? data.name.indexOf(' -') : data.name.length)
                    ) || name === data.name
            );
        const name = data.name + ' - Copy'.repeat(equalNames.length);
        const newCampaign = {
            name,
            segment: data.segment?.id,
            format: data.format,
            type: standardizeType(data.type),
            preview: data.preview,
            subject: data.subject,
            content: data.content,
            description: data.description,
            status: CAMPAIGN_STATUS.DRAFT,
            locations: data.locations,
            ...(data.recurringDays.length && {
                recurringStart: moment(data.recurringStart),
                recurringEnd: moment(data.recurringEnd),
                recurringDays: data.recurringDays
            }),
            recurringTime: data.recurringTime,
            nextSend: data.nextSend
        };
        await campaignApi.saveCampaign(newCampaign, true).then(() => {
            toastr.success('Campaign successfully copied');
        });
        await refreshList();
    };

    const deleteCampaign = async (id) => {
        try {
            await campaignApi.deleteCampaign(id);
            await refreshList();
            toastr.success('Campaign deleted successfully');
            setDeletingCampaign({ showModal: false, row: null });
        } catch {
            toastr.error('Something went wrong.');
        }
    };
    const handleDelete = (row) => {
        setDeletingCampaign({ showModal: true, row });
    };
    const pauseCampaign = async (data) => {
        const status = data.status === 'Paused' ? CAMPAIGN_STATUS.DRAFT : CAMPAIGN_STATUS.PAUSED;
        try {
            await campaignApi.updateCampaign(
                data.id,
                {
                    ...data,
                    segment: data.segment.id,
                    status
                },
                true
            );
            await refreshList();
        } catch {
            toastr.error('Something went wrong.');
        }
    };

    const getOrder = () => {
        return filter.order.includes('-') ? 'desc' : 'asc';
    };

    const createSortHandler = (id) => {
        if (getSortProperty() === id) {
            setFilter({ ...filter, order: getOrder() === 'desc' ? id.replace('-', '') : `-${id}` });
            return;
        }
        setFilter({ ...filter, order: id });
    };

    const getCells = () => {
        if (showTracking) return headCells;
        return headCells.slice(0, 10).concat(headCells[16]);
    };

    const searchLag = _.debounce((value) => setFilter({ ...filter, name: value }), 500);

    const renderFilters = () => {
        return (
            <>
                <Box display="flex" width="100%" flexDirection="row" justifyContent="space-between" mt={2}>
                    <Box display="flex" flexWrap="wrap">
                        <TextField
                            classes={{ root: classes.selectRoot }}
                            className={classes.searchBox}
                            placeholder="Search campaigns"
                            variant="outlined"
                            onChange={(e) => searchLag(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton style={{ padding: 5, marginLeft: 0 }}>
                                            <Search />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />

                        <FormControl classes={{ root: classes.selectRoot }}>
                            <InputLabel className={classes.selectLabel}>Type</InputLabel>
                            <Select
                                variant="outlined"
                                value={filter.type || ''}
                                onChange={(e) => setFilter({ ...filter, type: e.target.value })}
                            >
                                <MenuItem value="">
                                    <em>All</em>
                                </MenuItem>
                                <MenuItem value="ONE_OFF">One off</MenuItem>
                                <MenuItem value="SCHEDULED">Scheduled</MenuItem>
                                <MenuItem value="RECURRING">Recurring</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl classes={{ root: classes.selectRoot }}>
                            <InputLabel className={classes.selectLabel}>Status</InputLabel>
                            <Select
                                variant="outlined"
                                value={filter.status || ''}
                                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                            >
                                <MenuItem value="">
                                    <em>All</em>
                                </MenuItem>
                                <MenuItem value="PAUSED">Paused</MenuItem>
                                <MenuItem value="COMPLETED">Completed</MenuItem>
                                <MenuItem value="WAITING">Waiting to send</MenuItem>
                                <MenuItem value="DRAFT">Draft</MenuItem>
                                <MenuItem value="ACTIVE">Active</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl classes={{ root: classes.selectRoot }}>
                            <InputLabel className={classes.selectLabel}>Format</InputLabel>
                            <Select
                                variant="outlined"
                                value={filter.format || ''}
                                onChange={(e) => setFilter({ ...filter, format: e.target.value })}
                            >
                                <MenuItem value="">
                                    <em>All</em>
                                </MenuItem>
                                <MenuItem value="SMS">SMS</MenuItem>
                                <MenuItem value="EMAIL">EMAIL</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    {/*<Box display="flex" alignItems="center">
                        <Typography>Show/hide tracking</Typography>
                        <Switch
                            color="primary"
                            checked={showTracking}
                            onChange={(e) => setShowTracking(e.target.checked)}
                            name="checkedA"
                        />
                    </Box>*/}
                </Box>
                <CancelContinueModal
                    open={deletingCampaign.showModal}
                    onCancel={() => setDeletingCampaign({ showModal: false, row: null })}
                    onContinue={async () => deleteCampaign(deletingCampaign.row?.id)}
                    continueButtonText="Delete campaign"
                    cancelButtonText="Back"
                    title={`Delete ${deletingCampaign?.row?.name ? deletingCampaign?.row?.name + ' ' : ''}Campaign`}
                    contentHtml="Are you sure you want to delete this campaign?<br />This action CANNOT be undone."
                />
            </>
        );
    };

    const renderTableHead = () => {
        return (
            <TableHead>
                <TableRow>
                    {getCells().map((headCell) => (
                        <TableCell
                            className={classes.tableHeader}
                            padding={showTracking ? 'checkbox' : 'default'}
                            key={headCell.id}
                            sortDirection={getSortProperty() === headCell.id ? getOrder() : false}
                        >
                            {headCell.roerdable ? (
                                <TableSortLabel
                                    active={getSortProperty() === headCell.id}
                                    direction={getSortProperty() === headCell.id ? getOrder() : 'asc'}
                                    onClick={() => createSortHandler(headCell.id)}
                                >
                                    <span>{headCell.label}</span>
                                </TableSortLabel>
                            ) : (
                                <span>{headCell.label}</span>
                            )}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    };

    return (
        <>
            {renderFilters()}
            <Table className={classes.table} stickyHeader>
                {renderTableHead()}
                <TableBody>
                    {campaigns.map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                            <TableRow
                                className={classes.tableRow}
                                onClick={() => history.push(`/campaigns/${row.id}`)}
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.id}
                            >
                                <TableCell
                                    id={labelId}
                                    className={classes.tableBodyCell}
                                    padding={showTracking ? 'checkbox' : 'default'}
                                >
                                    <Tooltip title={row.name}>
                                        <span style={{ color: '#3083EC', fontSize: '14px', cursor: 'pointer' }}>
                                            {row.name}
                                        </span>
                                    </Tooltip>
                                </TableCell>
                                <TableCell
                                    id={labelId}
                                    className={classes.tableBodyCell}
                                    padding={showTracking ? 'checkbox' : 'default'}
                                >
                                    <Tooltip title={row.segment ? row.segment.name : ''}>
                                        <span>{row.segment && row.segment.name ? row.segment.name : ''}</span>
                                    </Tooltip>
                                </TableCell>
                                <TableCell
                                    id={labelId}
                                    className={classes.tableBodyCell}
                                    padding={showTracking ? 'checkbox' : 'default'}
                                >
                                    {row.format}
                                </TableCell>
                                <TableCell
                                    id={labelId}
                                    className={classes.tableBodyCell}
                                    padding={showTracking ? 'checkbox' : 'default'}
                                >
                                    {row.type}
                                </TableCell>
                                <TableCell
                                    id={labelId}
                                    className={classes.tableBodyCell}
                                    padding={showTracking ? 'checkbox' : 'default'}
                                >
                                    {row.createdAt}
                                </TableCell>
                                <TableCell
                                    id={labelId}
                                    className={classes.tableBodyCell}
                                    padding={showTracking ? 'checkbox' : 'default'}
                                >
                                    {row.status}
                                </TableCell>
                                <TableCell
                                    id={labelId}
                                    className={classes.tableBodyCell}
                                    padding={showTracking ? 'checkbox' : 'default'}
                                >
                                    {row.lastSent}
                                </TableCell>
                                <TableCell
                                    id={labelId}
                                    className={classes.tableBodyCell}
                                    padding={showTracking ? 'checkbox' : 'default'}
                                >
                                    {row.status.toUpperCase() !== CAMPAIGN_STATUS.DRAFT && row.lastSent
                                        ? row.noSent
                                        : '-'}
                                </TableCell>
                                <TableCell
                                    id={labelId}
                                    className={classes.tableBodyCell}
                                    padding={showTracking ? 'checkbox' : 'default'}
                                >
                                    {row.status.toUpperCase() !== CAMPAIGN_STATUS.DRAFT ? row.nextSend : ''}
                                </TableCell>
                                <TableCell
                                    id={labelId}
                                    className={classes.tableBodyCell}
                                    padding={showTracking ? 'checkbox' : 'default'}
                                >
                                    {row.description}
                                </TableCell>
                                {showTracking && (
                                    <>
                                        <TableCell
                                            id={labelId}
                                            className={classes.tableBodyCell}
                                            padding={showTracking ? 'checkbox' : 'default'}
                                        >
                                            {row.deliver} ({getPercentage(row.deliver, row.total)})
                                        </TableCell>
                                        <TableCell
                                            id={labelId}
                                            className={classes.tableBodyCell}
                                            padding={showTracking ? 'checkbox' : 'default'}
                                        >
                                            {row.open} ({getPercentage(row.open, row.total)})
                                        </TableCell>
                                        <TableCell
                                            id={labelId}
                                            className={classes.tableBodyCell}
                                            padding={showTracking ? 'checkbox' : 'default'}
                                        >
                                            {row.clickThru} ({getPercentage(row.clickThru, row.total)})
                                        </TableCell>
                                        <TableCell
                                            id={labelId}
                                            className={classes.tableBodyCell}
                                            padding={showTracking ? 'checkbox' : 'default'}
                                        >
                                            {row.conversions} ({getPercentage(row.conversions, row.total)})
                                        </TableCell>
                                        <TableCell
                                            id={labelId}
                                            className={classes.tableBodyCell}
                                            padding={showTracking ? 'checkbox' : 'default'}
                                        >
                                            {row.bounce} ({getPercentage(row.bounce, row.total)})
                                        </TableCell>
                                        <TableCell
                                            id={labelId}
                                            className={classes.tableBodyCell}
                                            padding={showTracking ? 'checkbox' : 'default'}
                                        >
                                            {row.unsubscribed} ({getPercentage(row.unsubscribed, row.total)})
                                        </TableCell>
                                    </>
                                )}
                                <TableCell
                                    id={labelId}
                                    className={classes.tableBodyCell}
                                    padding={showTracking ? 'checkbox' : 'default'}
                                >
                                    <ActionButton row={row} key={row.id} />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={totalCampaigns}
                rowsPerPage={filter.pageSize}
                page={filter.page}
                onChangePage={(e, page) => setFilter({ ...filter, page })}
                onChangeRowsPerPage={(e) => setFilter({ ...filter, pageSize: e.target.value, page: 0 })}
                classes={{ root: classes.tablePagination }}
            />
            {deleteCampaignId !== '' && (
                <DeleteConfirmationModal
                    deleteCampaignId={deleteCampaignId}
                    setDeleteCampaignId={setDeleteCampaignId}
                    deleteCampaign={deleteCampaign}
                />
            )}
        </>
    );
}

CampaignList.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CampaignList);
