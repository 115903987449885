import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import Header from './header/Header';
import Sidebar from '../collums-components/components/common/Sidebar';
import { validateJobPermissions } from '../collums-components/helpers/index';

import api from '../api/api';
import authApi from '../api/authApi';
import { getQueryParams } from '../services/locationHelper';
import config from '../config';
import ShowPINModal from './../collums-components/components/common/PINModal/index';
import { COLLUMS_APPS_ACCESS_TIER } from './../collums-constants';
import { isAvailableByPlan, isValidMongoIdString } from './../collums-constants/utils';
import { CURRENT_CLINIC } from './../collums-constants/storageKeys';
import EnvironmentDisabled from './../collums-components/components/common/EnvironmentDisabled';
import { listActiveClinics } from '../collums-components/api/ClinicApi';
import LogoutModal from '../collums-components/components/common/LogoutModal';
import { useCookies } from 'react-cookie';
import * as Sentry from '@sentry/react';
import GenericErrorBoundaryFallback from '../collums-components/components/common/GenericErrorBoundaryFallback';
import { setSentryUser } from '../collums-components/helpers/sentry';
import _ from 'lodash';
import Intercom from '../collums-components/helpers/Intercom';
import { validateAndUpdateClinic } from '../collums-components/helpers/user';

const App = ({ children }) => {
    const [isInitialized, setIsInitialized] = useState(false);
    const [sentryDialogOpts, setSentryDialogOpts] = useState({});
    const location = useLocation();
    const [cookies] = useCookies(['token']);

    const fetchClinics = async () => {
        const clinics = await listActiveClinics();
        const user = await authApi.getMe();
        const allowedClinics = clinics.filter((c) => user?.locations?.some((loc) => loc.clinic === c.id));
        return allowedClinics[0] ?? {};
    };

    useEffect(() => {
        const doEffect = async () => {
            const token = getQueryParams(location).token || cookies.token;
            if (token) {
                try {
                    api.config({ headers: { Authorization: token } });
                    localStorage.setItem('token', token);
                    const me = await authApi.getMe();
                    setSentryDialogOpts(setSentryUser(me));

                    await validateJobPermissions(token, me, process.env.REACT_APP_NAME);

                    const currentClinic = getQueryParams(location).clinic;
                    if (currentClinic && isValidMongoIdString(currentClinic)) {
                        validateAndUpdateClinic(me, currentClinic);
                    }

                    await Intercom.updateUser(me);
                } catch (error) {
                    if (error && error.status === 401) {
                        window.location = `${config.authUrl}?redirect=marketing`;
                    } else {
                        throw error;
                    }
                }
            } else {
                window.location = `${config.authUrl}?redirect=marketing`;
            }
            setIsInitialized(true);
        };

        const localClinic = localStorage.getItem(CURRENT_CLINIC);

        // Try to find by logged user and assign clinic if not exists.
        if (!localClinic) {
            fetchClinics().then((clinic) => {
                if (!_.isEmpty(clinic)) {
                    localStorage.setItem(CURRENT_CLINIC, clinic.id);
                    window.location.reload();
                }
            });
        }

        doEffect();

        // eslint-disable-next-line
    }, []);

    if (!isAvailableByPlan(COLLUMS_APPS_ACCESS_TIER.MARKETING)) {
        return <EnvironmentDisabled />;
    }

    if (!isInitialized) {
        return null;
    }

    return (
        <>
            <ShowPINModal
                onEnterPin={async (user, token) => {
                    api.config({ headers: { Authorization: token } });
                }}
            />
            {cookies.logoutActive === 'true' && <LogoutModal />}
            <div className="content-flex">
                <div className="content-width">
                    <Header />
                    <Sentry.ErrorBoundary
                        fallback={<GenericErrorBoundaryFallback />}
                        showDialog
                        dialogOptions={sentryDialogOpts}
                    >
                        <div>{children}</div>
                    </Sentry.ErrorBoundary>
                </div>
                <Sidebar appName="marketing" />
            </div>
        </>
    );
};

App.propTypes = {
    children: PropTypes.any.isRequired
};

export default App;
