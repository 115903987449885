import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, makeStyles, IconButton } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';

import TagsApi from '../api/TagsApi';

import { tagsStyles } from './styles';
import SimpleTagsModal from '../components/tags/SimpleTagsModal';

function SimpleTagsSelector({ size, onChange, className, showAmount, defaultValue, showAddButton }) {
    const [options, setOptions] = useState([]);
    const [value, setValue] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const classes = makeStyles(tagsStyles)();

    async function getTagOptions() {
        const res = await TagsApi.listTags();
        setOptions(res);
    }

    const filterValue = () => {
        if (options.length > 0) {
            const filteredValues = [];

            defaultValue.forEach(tagId => {
                const haveValue = options.filter(option => option.id === tagId);
                if (haveValue) filteredValues.push(haveValue[0]);
            });

            setValue(filteredValues);
        }
    };

    useEffect(() => {
        getTagOptions();
    }, []);

    useEffect(() => {
        if (defaultValue) filterValue();
        /* eslint-disable-next-line */
    }, [options]);

    return (
        <div className={classes.root}>
            <Autocomplete
                className={className}
                size={size || 'small'}
                value={value}
                fullWidth
                autoComplete
                autoHighlight
                limitTags={showAmount || 3}
                multiple
                options={options}
                getOptionLabel={opt => (opt?.name ? opt.name : '')}
                onChange={(e, value) => {
                    onChange(value);
                    setValue(value);
                }}
                classes={{ listbox: classes.listBox }}
                renderInput={params => <TextField {...params} variant={'outlined'} fullWidth placeholder={'Tags'} />}
            />
            {showAddButton && (
                <IconButton onClick={() => setIsModalVisible(true)} variant="outlined">
                    <AddCircle />
                </IconButton>
            )}
            {isModalVisible && (
                <SimpleTagsModal
                    isVisible={isModalVisible}
                    closeModal={() => {
                        setIsModalVisible(false);
                        getTagOptions();
                    }}
                />
            )}
        </div>
    );
}

SimpleTagsSelector.propTypes = {
    name: PropTypes.string,
    size: PropTypes.string,
    value: PropTypes.array,
    onChange: PropTypes.func,
    className: PropTypes.object,
    showAmount: PropTypes.number,
    defaultValue: PropTypes.array,
    showAddButton: PropTypes.bool
};

export default SimpleTagsSelector;
