import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { smsEditor as styles } from './styles';
import { withStyles, Box, TextField, Typography } from '@material-ui/core';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import LinkIcon from '@material-ui/icons/Link';
import UrlShortner from './UrlShortener';
import { useSelector, useDispatch } from 'react-redux';
import { addOnForm } from '../../redux/actions/messageActions';

import Emoji from './Emoji';

function SMSEditor({
    classes,
    setSMSMessage,
    smsMessage,
    selectionPosition,
    setSelectionPosition,
    editorRef,
    templateContent,
    selectedTag,
    setSelectedTag,
    setTemplateContent
}) {
    const [historyData, setHistoryData] = useState({
        position: 0,
        data: ['']
    });

    const dispatch = useDispatch();
    const form = useSelector((state) => state.message);

    const [displayUrl, setDisplayUrl] = useState(false);
    const [smsCreditCount, setSMSCreditCount] = useState(1);

    useEffect(() => {
        if (editorRef) {
            editorRef.current.value = form.content || '';
            setSelectionPosition(editorRef.current.selectionEnd || 0);
        }
        /* eslint-disable-next-line */
    }, [editorRef, setSelectionPosition]);

    useEffect(() => {
        editorRef.current.value = smsMessage.value;
        /* eslint-disable-next-line */
    }, [smsMessage, setSMSMessage]);

    const addEmoji = (emoji) => {
        const nextPosition = editorRef.current.selectionEnd + emoji.length;
        editorRef.current.value = editorRef.current.value.splice(editorRef.current.selectionEnd, 0, emoji);

        editorRef.current.focus();

        editorRef.current.selectionEnd = nextPosition;

        const data = {
            value: editorRef.current.value,
            cursor: nextPosition
        };

        setSMSMessage(data);
        setSelectionPosition(nextPosition);
    };

    useEffect(() => {
        if (templateContent) {
            const newData = {
                position: historyData.position,
                data: [...historyData.data, templateContent]
            };
            setHistoryData(newData);
            setTemplateContent(null);
        }
        if (selectedTag) {
            const newData = {
                position: historyData.position,
                data: [...historyData.data, editorRef.current.value]
            };
            setHistoryData(newData);
            setSelectedTag(null);
        }
        // eslint-disable-next-line
    }, [templateContent, selectedTag]);

    const handleHistory = (content, pos) => {
        if (historyData.position) {
            const newData = {
                position: 0,
                data: [...historyData.data].splice(0, historyData.data.length + historyData.position)
            };
            setHistoryData(newData);
            return;
        }
        const newData = { position: pos ? pos : historyData.position, data: [...historyData.data, content] };
        setHistoryData(newData);
    };

    const handleURLchange = (url) => {
        editorRef.current.value = editorRef.current.value.splice(
            selectionPosition,
            0,
            `${editorRef.current.value[selectionPosition - 1] === ' ' ? '' : ' '}${url}`
        );

        const newData = {
            position: historyData.position,
            data: [...historyData.data, editorRef.current.value]
        };
        setHistoryData(newData);

        setSMSMessage({ ...smsMessage, value: editorRef.current.value });
        setDisplayUrl(false);
    };

    const handleMoveHistory = (direction) => {
        const position = historyData.position + direction;

        const history =
            position >= 0
                ? historyData.data[historyData.data.length - 1]
                : historyData.data[historyData.data.length - 1 + position];

        if (typeof history === 'string') {
            editorRef.current.value = history;

            if (position <= 0) setHistoryData({ ...historyData, position });
        }
    };

    return (
        <>
            <Box display="block" position="relative" width="100%">
                <Emoji addEmoji={addEmoji} />
                <RotateLeftIcon className={classes.smsBtn} onClick={() => handleMoveHistory(-1)} />
                <RotateRightIcon className={classes.smsBtn} onClick={() => handleMoveHistory(1)} />
                <LinkIcon className={classes.smsBtn} onClick={() => setDisplayUrl(!displayUrl)} />

                <UrlShortner
                    visible={displayUrl}
                    setVisibility={() => setDisplayUrl(!displayUrl)}
                    onChange={handleURLchange}
                />

                {/* Text field */}
                <TextField
                    inputRef={editorRef}
                    multiline
                    fullWidth
                    rows={15}
                    className={classes.smsInput}
                    onClick={(e) => {
                        const pos = e.target.selectionEnd;
                        editorRef.current.selectionEnd = pos;
                        setSelectionPosition(pos);
                    }}
                    onBlur={() => dispatch(addOnForm({ content: editorRef.current.value }))}
                    onChange={(event) => {
                        if (event.target.value.length <= 1) dispatch(addOnForm({ content: event.target.value }));
                        setSMSMessage({ ...smsMessage, value: event.target.value });
                        const count = parseInt(event.target.value.length / 160, 10) + 1;
                        setSMSCreditCount(count);
                        handleHistory(editorRef.current.value);
                        const pos = event.target.selectionEnd;
                        editorRef.current.selectionEnd = pos;
                        setSelectionPosition(pos);
                    }}
                    onFocus={() => {
                        if (editorRef.current) {
                            editorRef.current.setSelectionRange(selectionPosition, selectionPosition);
                        }
                    }}
                    placeholder="Message"
                    InputProps={{ style: { height: 256 } }}
                    inputProps={
                        (onkeydown = (event) => {
                            if (event.ctrlKey) {
                                if (!event.shiftKey && event.key === 'z') {
                                    event.preventDefault();
                                    handleMoveHistory(-1);
                                } else if (event.key === 'y' || (event.shiftKey && event.key === 'Z')) {
                                    event.preventDefault();
                                    handleMoveHistory(1);
                                }
                            }
                        })
                    }
                    variant="outlined"
                />
            </Box>
            <Typography
                className={`${classes.helperText} ${classes.marginTop8px}`}
            >{`Characters ${smsMessage.value.length}/160 ${smsCreditCount} SMS credits (when GSM-7 character set used). Using other characters will use more credits.`}</Typography>
            <Typography className={classes.helperText}>
                Check the number of credits (segments) your message will use{' '}
                <a
                    className={classes.link}
                    href="https://twiliodeved.github.io/message-segment-calculator/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    here
                </a>
                . Read more about SMS encoding{' '}
                <a
                    className={classes.link}
                    href="https://www.twilio.com/blog/2017/03/what-the-heck-is-a-segment.html"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    here
                </a>
            </Typography>
        </>
    );
}

SMSEditor.propTypes = {
    classes: PropTypes.object.isRequired,
    smsMessage: PropTypes.object.isRequired,
    setSMSMessage: PropTypes.func.isRequired,
    selectionPosition: PropTypes.object,
    setSelectionPosition: PropTypes.func.isRequired,
    editorRef: PropTypes.object,
    templateContent: PropTypes.string,
    setTemplateContent: PropTypes.func,
    selectedTag: PropTypes.string,
    setSelectedTag: PropTypes.func
};

export default withStyles(styles)(SMSEditor);
