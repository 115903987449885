import React from 'react';
import PropTypes from 'prop-types';
import { form as styles } from '../styles';
import { withStyles, makeStyles, Box, Button } from '@material-ui/core';

import { modalsButtonStyles } from '../../../collums-constants/styles/stylesheets/buttonsStyles';

function CampaignFormActionButtons({ next, cancel, enableNext, classes }) {
    const globalButtonsStyles = makeStyles(modalsButtonStyles)();

    return (
        <Box display="flex" justifyContent="center" margin="56px 20px 30px">
            <Button onClick={cancel} variant="contained" color="primary" className={globalButtonsStyles.cancelButton}>
                Cancel
            </Button>
            <Button
                disabled={!enableNext}
                variant="contained"
                className={`${globalButtonsStyles.confirmButton} ${classes.marginLeft8px}`}
                onClick={next}
            >
                Save
            </Button>
        </Box>
    );
}

CampaignFormActionButtons.propTypes = {
    classes: PropTypes.object.isRequired,
    next: PropTypes.func,
    cancel: PropTypes.func,
    enableNext: PropTypes.bool
};

export default withStyles(styles)(CampaignFormActionButtons);
