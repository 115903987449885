import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import CampaignView from './components/campaigns/CampaignView';
import DynamicSegmentsListView from './components/segments/DynamicSegmentsListView';
import DynamicSegmentCreateView from './components/segments/DynamicSegmentCreateView';
import CampaignForm from './components/campaigns/campaignForms/CampaignForm';
import TemplateView from './components/templates/TemplatesView';

function Routes() {
    return (
        <Switch>
            <Redirect exact from="/" to="/campaigns" />
            <Route exact path="/templates" component={TemplateView} />
            <Route exact path="/campaigns" component={CampaignView} />
            <Redirect exact path="/campaigns/new" to="/campaigns/new/email" />
            <Route exact path="/campaigns/:id" component={CampaignForm} />
            <Route exact path="/campaigns/new/:type" component={CampaignForm} />
            <Redirect exact from="/segments" to="/segments/dynamic" />
            <Route exact path="/segments/dynamic" component={DynamicSegmentsListView} />
            <Route exact path="/segments/dynamic/create" component={DynamicSegmentCreateView} />
            <Route exact path="/segments/static">
                Segments static view
            </Route>
        </Switch>
    );
}

export default Routes;
