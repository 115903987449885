import { Box, Card, withStyles } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import CampaignFormActionButtons from './CampaignFormActionButtons';
import { form as styles } from '../styles';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { MESSAGE_TYPE } from '../../../constants';
import MessageForm from '../../common/MessageForm';

function CampaignFormContent({ nextStep, setDisplayCancelModal }) {
    const form = useSelector((state) => state.message);

    /* eslint-disable-next-line */
    String.prototype.splice = function (idx, rem, str) {
        return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
    };

    return (
        <Card variant="elevation">
            <Box p={2}>
                <MessageForm isCampaignForm={true} />

                {/* TODO enable next button dynamic */}
                <Box mt={2}>
                    <CampaignFormActionButtons
                        next={nextStep}
                        enableNext={
                            form.format === MESSAGE_TYPE.EMAIL ? !!form.subject && !!form.content : !!form.content
                        }
                        cancel={() => setDisplayCancelModal(true)}
                    />
                </Box>
            </Box>
        </Card>
    );
}

CampaignFormContent.propTypes = {
    classes: PropTypes.object.isRequired,
    nextStep: PropTypes.func,
    setDisplayCancelModal: PropTypes.func
};

export default withStyles(styles)(CampaignFormContent);
