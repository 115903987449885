import api from './api';

const getCountries = () =>
    api.request({
        method: 'GET',
        url: '/countries'
    });

export default {
    getCountries
};
