import FileDownload from 'js-file-download';

import api from './api';

const query = (params) =>
    api.request({
        method: 'GET',
        url: '/segments',
        params
    });

const get = (segmentId, allData = false) =>
    api.request({
        method: 'GET',
        url: `/segments/${segmentId}?allData=${allData}`
    });

const create = (data) =>
    api.request({
        method: 'POST',
        url: '/segments',
        data
    });

const update = (data) =>
    api.request({
        method: 'PUT',
        url: `/segments/${data.id}`,
        data
    });

const remove = (segmentId) =>
    api.request({
        method: 'DELETE',
        url: `/segments/${segmentId}`
    });

const countCustomersForSegment = (segmentId) =>
    api.request({
        method: 'GET',
        url: `/segments/count-customers/${segmentId}`
    });

const countCustomersForBody = (data) =>
    api.request({
        method: 'POST',
        url: '/segments/count-customers',
        data
    });

const downloadCsv = async (segmentId) => {
    const response = await api.request({
        method: 'GET',
        url: `/segments/download-csv/${segmentId}`
    });
    FileDownload(response, 'customers.csv');
};

export default {
    query,
    get,
    update,
    create,
    remove,
    countCustomersForSegment,
    countCustomersForBody,
    downloadCsv
};
