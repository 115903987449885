import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import CloseBtn from '../common/CloseBtn';
import { Button, Modal, Paper, TextField, withStyles } from '@material-ui/core';
import TagsApi from '../../api/TagsApi';
import { tagModalStyles } from './styles';

function SimpleTagsModal({ isVisible, selectedTag, closeModal, classes }) {
    const [name, setName] = useState(selectedTag?.name || '');
    const [description, setDescription] = useState(selectedTag?.description || '');
    const [type, setType] = useState(selectedTag?.type || '');

    const formRef = useRef();

    const handleChange = (setter, newValue) => {
        setter(newValue);
    };

    const persistData = () => {
        const values = {
            name,
            description,
            type
        };

        if (selectedTag) {
            TagsApi.updateTag(values).then(() => {
                toastr.success('Tag updated!');
                closeModal();
            });
        } else {
            TagsApi.createTag(values).then(() => {
                toastr.success('Tag created!');
                closeModal();
            });
        }
    };

    return (
        <Modal open={isVisible} disableBackdropClick={false} className={classes.modal} onCancel={() => closeModal()}>
            <Paper className={classes.root}>
                <div className={classes.header}>
                    <p>{selectedTag ? 'Edit tag' : 'New tag'}</p>
                    <div className={classes.closeBtn}>
                        <CloseBtn onClick={closeModal} />
                    </div>
                </div>
                <form
                    ref={formRef}
                    className={classes.formRoot}
                    onSubmit={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        persistData();
                    }}
                >
                    <TextField
                        className={classes.formItem}
                        name="name"
                        label="Name"
                        onChange={e => handleChange(setName, e.target.value)}
                        variant="outlined"
                        value={name}
                    />
                    <TextField
                        className={classes.formItem}
                        name="description"
                        label="Description"
                        onChange={e => handleChange(setDescription, e.target.value)}
                        variant="outlined"
                        value={description}
                    />
                    <TextField
                        className={classes.formItem}
                        name="type"
                        label="Type"
                        onChange={e => handleChange(setType, e.target.value)}
                        variant="outlined"
                        value={type}
                    />
                    <div style={{ marginTop: 24 }}>
                        <Button type="submit" variant="outlined" className={classes.outlinedButton}>
                            Confirm
                        </Button>
                    </div>
                </form>
            </Paper>
        </Modal>
    );
}

SimpleTagsModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    classes: PropTypes.object,
    selectedTag: PropTypes.object
};

export default withStyles(tagModalStyles)(SimpleTagsModal);
