import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { templatesViewStyle as styles } from './styles';
import {
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Box,
    IconButton,
    TablePagination,
    TextField,
    InputAdornment,
    withStyles,
    Menu,
    MenuItem,
    Button
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import campaignApi from '../../api/campaignApi';
import { MESSAGE_TYPE, MESSAGE_FORM, NOTIFICATION_MESSAGE_TYPE } from '../../constants/index';
import Moment from 'moment-timezone';
import CancelContinueModal from '../../collums-components/components/common/CancelContinueModal';
import notificationApi from '../../api/notificationApi';

function TemplatesList({
    classes,
    templates,
    filter,
    setFilter,
    setTemplates,
    setDisplayEditTemplate,
    setTemplateToEdit
}) {
    const [displayModal, setDisplayModal] = useState(false);
    const [templateToDelete, setTemplateToDelete] = useState({});
    const tableHeadCells = [
        { id: 'name', value: 'Name' },
        { id: 'description', value: 'Description' },
        { id: 'subject', value: 'Subject' },
        { id: 'content', value: 'Content' },
        { id: 'createdAt', value: 'Created At' },
        { id: 'actions', value: '' }
    ];

    const searchLag = _.debounce((value) => setFilter({ ...filter, name: value }), 500);

    const displayEditModal = (element) => {
        setDisplayEditTemplate(true);
        setTemplateToEdit(element);
    };
    const handleDelete = (element) => {
        setDisplayModal(true);
        setTemplateToDelete(element);
    };
    const handleCopy = (element, name) => {
        const newTemplate = { ...element };
        delete newTemplate.id;
        delete newTemplate.createdAt;

        if (!element?.isNotification) {
            campaignApi
                .saveTemplate({
                    ...newTemplate,
                    name
                })
                .then(() => {
                    campaignApi.queryTemplate(filter).then((res) => {
                        setTemplates(res);
                    });
                });
        } else {
            if (Object.prototype.hasOwnProperty.call(newTemplate, 'isNotification')) {
                delete newTemplate.isNotification;
            }

            notificationApi
                .createTemplate({
                    ...newTemplate,
                    name
                })
                .then(() => {
                    campaignApi.queryTemplate(filter).then((res) => {
                        setTemplates(res);
                    });
                });
        }
    };
    const renderContent = (content, isEmail) => {
        const rawContent = content
            .replace(/(<([^>]+)>)/gi, '')
            .replace(/(\r\n|\n|\r)/gm, '')
            .trim();
        if (rawContent.length > 25 && isEmail) return rawContent.substring(0, 25) + '...';
        return rawContent;
    };
    const ActionButton = ({ row }) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const openMenu = (event) => {
            event.preventDefault();
            event.stopPropagation();
            setAnchorEl(event.currentTarget);
        };
        const closeMenu = (e) => {
            e.stopPropagation();
            setAnchorEl(null);
        };
        ActionButton.propTypes = {
            row: PropTypes.object
        };
        return (
            <>
                <Button onClick={openMenu} className={classes.grayButton}>
                    Options
                </Button>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
                    <MenuItem
                        onClick={(e) => {
                            const equalNames = templates.data
                                .map((template) => template.name)
                                .filter(
                                    (name) =>
                                        name.startsWith(
                                            row.name.slice(
                                                0,
                                                row.name.indexOf(' -') >= 0 ? row.name.indexOf(' -') : row.name.length
                                            )
                                        ) || name === row.name
                                );
                            const name = row.name + ' - Copy'.repeat(equalNames.length);
                            handleCopy(row, name);
                            closeMenu(e);
                        }}
                    >
                        Copy
                    </MenuItem>
                    <MenuItem
                        onClick={(e) => {
                            handleDelete(row);
                            closeMenu(e);
                        }}
                    >
                        Delete
                    </MenuItem>
                </Menu>
            </>
        );
    };
    return (
        <>
            <Box className={classes.rootBox} mt={2}>
                <TextField
                    classes={{ root: classes.selectRoot }}
                    className={classes.searchBox}
                    placeholder="Search template"
                    variant="outlined"
                    onChange={(e) => searchLag(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton>
                                    <Search />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </Box>
            <Table className={classes.table} size="medium">
                <TableHead>
                    <TableRow>
                        {tableHeadCells.map((element) => {
                            if (
                                filter.type === MESSAGE_TYPE.SMS &&
                                (element.id === MESSAGE_FORM.SUBJECT || element.id === MESSAGE_FORM.PREVIEW)
                            )
                                return null;
                            return (
                                <TableCell key={element.id} padding="default" className={classes.tableHeader}>
                                    <span>{element.value}</span>
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {templates &&
                        templates.data &&
                        templates.data.map((element) => {
                            return (
                                <TableRow className={classes.tableRow} key={element.id}>
                                    <TableCell
                                        onClick={() => displayEditModal(element)}
                                        width="15%"
                                        className={classes.tableBodyCell}
                                        key={`name-${element.id}`}
                                    >
                                        <span style={{ color: 'rgb(43, 120, 228)' }}>{element.name}</span>
                                    </TableCell>
                                    <TableCell
                                        width="15%"
                                        onClick={() => displayEditModal(element)}
                                        className={classes.tableBodyCell}
                                        key={`name-${element.id}`}
                                    >
                                        <span>
                                            <span>{element.description}</span>
                                        </span>
                                    </TableCell>
                                    {(filter.type === MESSAGE_TYPE.EMAIL ||
                                        filter.type === NOTIFICATION_MESSAGE_TYPE.EMAIL) && (
                                        <>
                                            <TableCell
                                                width="15%"
                                                onClick={() => displayEditModal(element)}
                                                className={classes.tableBodyCell}
                                                key={`subject-${element.id}`}
                                            >
                                                <span>{element.subject}</span>
                                            </TableCell>
                                        </>
                                    )}
                                    <TableCell
                                        width="15%"
                                        onClick={() => displayEditModal(element)}
                                        className={classes.tableBodyCell}
                                        key={`content-${element.id}`}
                                    >
                                        <span>
                                            {renderContent(
                                                element.content,
                                                filter.type === MESSAGE_TYPE.EMAIL ||
                                                    filter.type === NOTIFICATION_MESSAGE_TYPE.EMAIL
                                            )}
                                        </span>
                                    </TableCell>
                                    <TableCell
                                        width="10%"
                                        onClick={() => displayEditModal(element)}
                                        className={classes.tableBodyCell}
                                        key={`subject-${element.id}`}
                                    >
                                        <span>
                                            {element.createdAt &&
                                                Moment(new Date().setTime(element.createdAt)).format('D/M/YY')}
                                        </span>
                                    </TableCell>
                                    <TableCell width="10%" id={`icons-${element.id}`} className={classes.tableBodyCell}>
                                        <ActionButton row={element} key={element.id} />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={templates.total || 0}
                rowsPerPage={filter.pageSize}
                page={filter.page}
                onChangePage={(e, page) => setFilter({ ...filter, page })}
                onChangeRowsPerPage={(e) => setFilter({ ...filter, pageSize: e.target.value, page: 0 })}
                classes={{ root: classes.tablePagination }}
            />
            {displayModal && (
                <CancelContinueModal
                    open={displayModal}
                    title={'Delete template'}
                    contentText={'Are you sure you want to continue?'}
                    continueButtonText={'Delete'}
                    onCancel={() => {
                        setDisplayModal(false);
                    }}
                    onContinue={() => {
                        campaignApi.deleteTemplate(templateToDelete.id).then(() => {
                            campaignApi.queryTemplate(filter).then((res) => {
                                setTemplates(res);
                            });
                            setDisplayModal(false);
                            setTemplateToDelete({});
                        });
                    }}
                />
            )}
        </>
    );
}

TemplatesList.propTypes = {
    classes: PropTypes.object.isRequired,
    templates: PropTypes.object,
    filter: PropTypes.object.isRequired,
    setFilter: PropTypes.func.isRequired,
    setTemplates: PropTypes.func.isRequired,
    setDisplayEditTemplate: PropTypes.func,
    setTemplateToEdit: PropTypes.func
};

export default withStyles(styles)(TemplatesList);
