import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { IconButton, TextField, Typography, withStyles, CircularProgress } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DynamicSegmentRule from './DynamicSegmentRule';
import Button from '../common/Button';
import Select from '../common/Select';
import TextFieldInput from '../common/TextField';
import segmentApi from '../../api/segmentApi';
import serviceApi from '../../api/serviceApi';
import practitionerApi from '../../api/practitionerApi';
import stockApi from '../../api/stockApi';
import { getQueryParams } from '../../services/locationHelper';
import { dynamicSegmentCreateViewStyles as styles } from './styles';
import { Mutex } from 'async-mutex';
import courseApi from '../../api/courseApi';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';
import {
    isInvalidAppointmentRule,
    isInvalidCourseRule,
    isInvalidCustomerRule,
    isInvalidPractitionerRule,
    isInvalidProductRule
} from './ruleChecker';
import WarningModal from './WarningModal';
import { useDebounce } from '../../collums-components/helpers';
import { CURRENT_CLINIC } from '../../collums-constants/storageKeys';

const DynamicSegmentCreateView = ({ classes }) => {
    const location = useLocation();
    const history = useHistory();
    const clinic = localStorage.getItem(CURRENT_CLINIC);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [allOrAny, setAllOrAny] = useState('all');
    const [categories, setCategories] = useState([]);
    const [practitioners, setPractitioners] = useState([]);
    const [products, setProducts] = useState([]);
    const [rules, setRules] = useState([{ type: '' }]);
    const [customersCount, setCustomersCount] = useState(null);
    const [courses, setCourses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [isCounted, setIsCounted] = useState(false);
    const mutex = useState(new Mutex())[0];
    const [canSearch, setCanSearch] = useState(false);
    const changeDebounced = useDebounce(rules, 500);

    const redirectTo = useRef();
    const canRedirect = useRef(false);

    useEffect(() => {
        const doEffect = async () => {
            try {
                setIsLoading(true);
                const query = getQueryParams(location);
                const result = await serviceApi.getServiceCategoryAndTags(clinic);
                setCategories(result);
                const practitionerOptions = await practitionerApi.query();
                setPractitioners(practitionerOptions.map((el) => ({ value: el.value, label: el.name })));
                const productOptions = await stockApi.query();
                setProducts(productOptions);
                const courseOptions = await courseApi.query();
                setCourses(courseOptions);
                if (query.copySegmentId || query.id) {
                    const segment = await segmentApi.get(query.copySegmentId ? query.copySegmentId : query.id);
                    if (query.name) {
                        setName(query.name);
                    } else {
                        setName(segment.name);
                    }
                    setDescription(segment.description);
                    setAllOrAny(segment.allOrAny);
                    setRules(segment.rules);
                    calculateMatchedClients(segment.allOrAny, segment.rules);
                }
            } catch (err) {
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        };

        doEffect();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    const ref = useRef();

    const leaveAction = () => {
        setShowWarning(false);
        canRedirect.current = true;
        if (redirectTo.current) {
            try {
                redirectTo.current.click();
            } catch (err) {
                return;
            }
        } else {
            history.push('/segments/dynamic');
        }
    };

    const handleClickOutside = (event) => {
        if (event.target?.id === 'confirm-leave-button') return;

        const tagName = event.target?.tagName?.toLowerCase();
        if (tagName !== 'a' && tagName !== 'img') return;

        if (canRedirect.current) return;

        const isAnyFieldFilled = name !== '' || description !== '' || (rules.length > 0 && rules[0].type !== '');

        if (ref.current && !ref.current.contains(event.target) && isAnyFieldFilled) {
            redirectTo.current = event.target;
            event.stopPropagation();
            event.preventDefault();
            setShowWarning(true);
        }
    };

    const calculateMatchedClients = async (_allOrAny, _rules) => {
        if (!canSearch || isSearching) return;
        const release = await mutex.acquire();
        setIsSearching(true);
        await segmentApi
            .countCustomersForBody({ name, description, allOrAny: _allOrAny || allOrAny, rules: _rules || rules })
            .then((result) => {
                setIsCounted(true);
                setCustomersCount(result);
            })
            .catch(() => {
                setCustomersCount(null);
            });
        setIsSearching(false);
        release();
    };

    const validateRules = (newRules) => {
        return (
            newRules &&
            newRules.length &&
            !newRules.some((rule) => {
                if (rule.textValue === '') return true;
                if (rule.type === '') return true;
                if (rule.type === 'customer') {
                    return isInvalidCustomerRule(rule);
                } else {
                    if (rule.type === 'appointment') {
                        return isInvalidAppointmentRule(rule);
                    }
                    if (rule.type === 'practitioner') {
                        return isInvalidPractitionerRule(rule);
                    }
                    if (rule.type === 'product') {
                        return isInvalidProductRule(rule);
                    }
                    if (rule.type === 'course') {
                        return isInvalidCourseRule(rule);
                    }
                }
                return false;
            })
        );
    };

    const onAllOrAnyChange = (value) => {
        setAllOrAny(value);
        if (validateRules(rules)) calculateMatchedClients(value, null);
        else setCustomersCount(null);
    };

    useEffect(() => {
        if (changeDebounced) {
            if (!validateRules(changeDebounced)) {
                setCustomersCount(null);
                setCanSearch(false);
            } else {
                setCanSearch(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changeDebounced]);

    const onRuleChange = (index, property, value) => {
        setIsCounted(false);
        const rulesCopy = [...rules];
        const ruleCopy = 'type' === property ? {} : { ...rulesCopy[index] };
        ruleCopy[property] = value;
        rulesCopy[index] = ruleCopy;
        rulesCopy.forEach((rule, index) => {
            const propsToErase = [];
            Object.keys(rule).forEach((ruleProp) => {
                if (rule[ruleProp] === '') propsToErase.push(ruleProp);
            });
            propsToErase.forEach((prop) => {
                try {
                    delete rulesCopy[index][prop];
                } catch {
                    return;
                }
            });
        });
        setRules(rulesCopy);
    };

    const onRuleDelete = (index) => {
        setIsCounted(false);
        const rulesCopy = [...rules];
        rulesCopy.splice(index, 1);
        setRules(rulesCopy);
    };

    const onSave = () => {
        if (!name) {
            return toastr.error('Name is required');
        } else if (!rules.length || !rules[0].type) {
            return toastr.error('Rules are required');
        } else if (!validateRules(rules)) {
            return toastr.error('Rules are required');
        }
        const mappedRules = rules.map((rule) => {
            if (rule.customerCondition === 'tag') {
                return {
                    ...rule,
                    tags: rule.tags.map((tag) => tag.id)
                };
            } else {
                return rule;
            }
        });

        setIsLoading(true);
        const query = getQueryParams(location);
        if (query.id) {
            return segmentApi
                .update({ id: query.id, name, description, allOrAny, rules: mappedRules, isCounted })
                .then(() => {
                    toastr.success('Segment updated successfully');
                    history.push('/segments/dynamic');
                })
                .catch(() => toastr.error('Something went wrong'))
                .finally(() => setIsLoading(false));
        }
        segmentApi
            .create({ name, description, allOrAny, rules: mappedRules, isCounted })
            .then(() => {
                toastr.success('Segment created successfully');
                history.push('/segments/dynamic');
            })
            .catch((e) => toastr.error(e.data.message || 'Something went wrong'))
            .finally(() => setIsLoading(false));
    };

    const onCancel = () => {
        const isAnyFieldFilled = name !== '' || description !== '' || (rules.length > 0 && rules[0].type !== '');
        if (isAnyFieldFilled) {
            redirectTo.current = undefined;
            setShowWarning(true);
        } else {
            history.push('/segments/dynamic');
        }
    };

    return (
        <div className={classes.container} ref={ref}>
            <Typography className={classes.bigLabel}>Create Segment</Typography>
            <div className={classes.inputRow}>
                <Typography className={classes.smallLabel}>Name:</Typography>
                <TextFieldInput className={classes.nameInput} value={name} onChange={setName} />
            </div>
            <div className={classes.inputRow}>
                <Typography className={classes.smallLabel}>Description:</Typography>
                <TextField
                    className={classes.descriptionInput}
                    variant="outlined"
                    multiline
                    rows="1"
                    value={description}
                    onChange={(event) => setDescription(event.target.value)}
                />
            </div>
            <div className={classNames(classes.inputRow, classes.marginTop)}>
                <Typography className={classes.inlineLabel}>Find clients that match</Typography>
                <Select
                    className={classes.allOrAnySelect}
                    value={allOrAny}
                    onChange={onAllOrAnyChange}
                    options={[
                        { value: 'all', label: 'All' },
                        { value: 'any', label: 'Any' }
                    ]}
                />
                <Typography className={classes.inlineLabel}>of the following rules</Typography>
            </div>
            {rules.map((rule, index) => (
                <DynamicSegmentRule
                    key={index}
                    rule={rule}
                    index={index}
                    categories={categories}
                    courses={courses}
                    practitioners={practitioners}
                    products={products}
                    onDelete={onRuleDelete}
                    onChange={onRuleChange}
                />
            ))}

            <IconButton
                className={classes.addRuleBtn}
                onClick={() => {
                    setRules(rules.concat({ type: '' }));
                }}
            >
                <Add />
            </IconButton>
            {null == customersCount && (
                <Typography className={classes.inlineLabel}>
                    Fill all fields{' '}
                    <div className={classes.loadingIcon}>
                        {isSearching && <CircularProgress className={classes.searchIconProgress} size={18} />}
                    </div>
                    <Button
                        type="secondary"
                        className={`${classes.btnSearch} ${!canSearch || isSearching ? 'disabled' : ''}`}
                        onClick={() => calculateMatchedClients(null, changeDebounced)}
                    >
                        Client count
                    </Button>
                </Typography>
            )}

            {null != customersCount && (
                <Typography className={classes.inlineLabel}>
                    {`${customersCount.total || 0} clients match these rules, ${
                        customersCount.emailAllowed || 0
                    } clients accept email marketing, ${customersCount.smsAllowed || 0} clients accept SMS marketing`}
                    <div className={classes.loadingIcon}>
                        {isSearching && <CircularProgress className={classes.searchIconProgress} size={18} />}
                    </div>
                    <Button
                        type="secondary"
                        className={`${classes.btnSearch} ${!canSearch || isSearching ? 'disabled' : ''}`}
                        onClick={() => calculateMatchedClients(null, changeDebounced)}
                    >
                        Client count
                    </Button>
                </Typography>
            )}
            <div className={classes.bottomButtons}>
                <Button type="cancel" onClick={onCancel} id="cancel-button">
                    Cancel
                </Button>
                <Button type="primary" onClick={onSave}>
                    Save
                </Button>
            </div>
            {isLoading && <LoadingScreen />}
            {showWarning && <WarningModal leaveAction={leaveAction} setShowWarning={setShowWarning} />}
        </div>
    );
};

DynamicSegmentCreateView.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DynamicSegmentCreateView);
