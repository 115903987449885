import api from './api';
import querystring from 'querystring';
import Axios from 'axios';
import { CAMPAIGN_TYPES } from '../constants';
import moment from 'moment';

const messageCounterForMonth = async () => {
    return api.request({
        method: 'GET',
        url: '/messages-counter?period=month'
    });
};

const query = (filter) =>
    api.request({
        method: 'GET',
        url: `/campaigns?${querystring.stringify(filter)}`
    });

/**
 *
 * @param {File} file
 */
const uploadImage = async (file) => {
    const uploadUrl = await api.request({
        method: 'GET',
        url: `/campaigns/upload-url?filename=${file.name}`
    });

    const signedRequest = uploadUrl.signedRequest;
    const url = uploadUrl.url;

    const options = {
        headers: {
            'Content-Type': file.name.split('.')[1]
        }
    };

    await Axios.put(signedRequest, file, options);
    return url;
};

const sendMessageTest = (data) => {
    return api.request({
        method: 'POST',
        url: '/campaigns/test-message',
        data
    });
};

const saveCampaign = (campaign, cloning) => {
    const nextSend = moment(campaign.nextSend, 'DD/MM/YYYY HH:mm');
    const lastSent = moment(campaign.lastSent, 'DD/MM/YYYY HH:mm');
    campaign = {
        ...campaign,
        lastSent: lastSent.isValid() ? lastSent.toDate() : undefined,
        nextSend: nextSend.isValid() ? nextSend.toDate() : undefined,
        recurringStart: campaign.recurringStart && campaign.recurringStart.toDate(),
        recurringEnd: campaign.recurringEnd && campaign.recurringEnd.toDate()
    };
    if (!cloning && campaign.type) {
        campaign.recurringTime =
            campaign.type === CAMPAIGN_TYPES.RECURRING ? moment(campaign.nextSend).format('HH:mm') : '00:00';
    }
    return api.request({
        method: campaign.id ? 'PUT' : 'POST',
        url: campaign.id ? `/campaigns/${campaign.id}` : '/campaigns',
        data: { ...campaign, id: undefined }
    });
};

const deleteCampaign = (id) => {
    return api.request({
        method: 'DELETE',
        url: `/campaigns/${id}`
    });
};

const updateCampaign = async (id, data, changeStatus = false) => {
    const payload = data;
    if (changeStatus) {
        payload.lastSent = data.lastSent
            ? moment(data.lastSent).isValid()
                ? data.lastSent
                : moment(data.lastSent, 'DD/MM/YYYY HH:mm').toDate()
            : undefined;
        payload.nextSend = data.nextSend
            ? moment(data.nextSend).isValid()
                ? data.nextSend
                : moment(data.nextSend, 'DD/MM/YYYY HH:mm').toDate()
            : undefined;
    }
    return api.request({
        method: 'PUT',
        url: `/campaigns/${id}`,
        data: payload
    });
};

const saveTemplate = async (data) => {
    return api.request({
        method: 'POST',
        url: '/templates',
        data
    });
};

const getTemplates = async () => {
    return api.request({
        method: 'GET',
        url: '/templates'
    });
};

const deleteTemplate = async (id) => {
    return api.request({
        method: 'DELETE',
        url: '/templates',
        data: { id }
    });
};

const updateTemplate = async (data) => {
    return api.request({
        method: 'PUT',
        url: 'templates',
        data
    });
};

const queryTemplate = async (filter) => {
    return api.request({
        method: 'GET',
        url: `/templates?${querystring.stringify(filter)}`
    });
};

const getCampaign = async (id) => {
    return api
        .request({
            method: 'GET',
            url: `/campaigns/${id}`
        })
        .then((campaign) => ({
            ...campaign,
            nextSend: campaign.nextSend && moment(campaign.nextSend),
            recurringStart: campaign.recurringStart && moment(campaign.recurringStart),
            recurringEnd: campaign.recurringEnd && moment(campaign.recurringEnd),
            total: undefined,
            createdAt: undefined
        }));
};

export default {
    query,
    queryTemplate,
    messageCounterForMonth,
    uploadImage,
    sendMessageTest,
    saveTemplate,
    getTemplates,
    saveCampaign,
    updateCampaign,
    deleteCampaign,
    getCampaign,
    deleteTemplate,
    updateTemplate
};
