import { Box, Button, Card, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { mergeTagsStyles } from './styles';
function MergeTagsBox({ classes, mergeTags, onClick, height = '256px', marginTop = '0' }) {
    return (
        <Box
            display="flex"
            ml={2}
            mt={marginTop}
            width="16%"
            minWidth={160}
            height={height}
            flexDirection="column"
            alignItems="center"
        >
            <b className={classes.mergeTagsHeader}>Merge Tags</b>
            <Card variant="outlined" className={classes.mergeTagsBox}>
                <Box display="flex" width="100%" height="100%" flexDirection="column" className={classes.mergeTags}>
                    {mergeTags.map(element => (
                        <Button key={element} className={classes.mergeBtn} onClick={() => onClick(element)}>
                            {element}
                        </Button>
                    ))}
                </Box>
            </Card>
        </Box>
    );
}

MergeTagsBox.propTypes = {
    classes: PropTypes.object.isRequired,
    mergeTags: PropTypes.arrayOf(PropTypes.string),
    onClick: PropTypes.func,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    marginTop: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
export default withStyles(mergeTagsStyles)(MergeTagsBox);
