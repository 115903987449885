export const tagModalStyles = () => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiPaper-root': {
            paddingBottom: 24
        }
    },
    root: {
        padding: '0.5% 2.5%'
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        '& p': {
            fontSize: 16,
            fontWeight: 500
        },
        position: 'relative',
        margin: '5px 0'
    },
    closeBtn: {
        position: 'absolute',
        right: 0,
        top: 0,
        '& :hover': {
            cursor: 'pointer'
        }
    },
    autocomplete: {
        marginTop: 8,
        marginRight: 8,
        marginBottom: 8,
        paddingRight: 0,
        width: '40vw !important',
        '& .MuiInputBase-root': {
            padding: '2px 10px'
        },
        '& .MuiAutocomplete-tag': {
            marginTop: 1
        }
    },
    formRoot: {
        display: 'flex',
        flexDirection: 'column'
    },
    formContent: {
        width: '100%',
        display: 'flex'
    },
    outlinedButton: {},
    formItem: {
        width: '40vw !important',
        marginTop: 8,
        marginRight: 8,
        marginBottom: 8,
        paddingRight: 0,
        '& label': {
            fontSize: 14
        }
    },
    autocompleteTextField: {
        width: '40vw !important'
    },
    textFieldInputProps: {
        fontSize: 14
    },
    autocompleteOptions: {
        fontSize: 14
    }
});
