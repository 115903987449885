import React from 'react';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    withStyles
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';

const styles = {
    closeButton: {
        position: 'absolute',
        right: 6,
        top: 6,
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 20
    }
};

function SimpleModal({ classes, title, children, onConfirm, confirmLabel, onClose, isOpen }) {
    const renderActions = () => {
        const buttons = [];
        if (onConfirm) {
            buttons.push(
                <Button
                    key="confirm"
                    onClick={(e) => {
                        e.preventDefault();
                        onConfirm();
                    }}
                    color="primary"
                >
                    {confirmLabel || 'Confirm'}
                </Button>
            );
        }

        return buttons;
    };
    return (
        <div
            className="modal"
            style={{
                display: isOpen ? 'flex' : 'none',
                position: 'absolute',
                height: '100%'
            }}
        >
            <div
                className="modal-content"
                style={{
                    top: '45%',
                    transform: 'translate(-50%, -30%)',
                    margin: '0'
                }}
            >
                <DialogTitle className={classes.title} disableTypography>
                    <Typography variant="h4">{title}</Typography>
                    <IconButton onClick={onClose} className={classes.closeButton}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>{children}</DialogContent>
                <DialogActions>{renderActions()}</DialogActions>
            </div>
        </div>
    );
}
SimpleModal.propTypes = {
    classes: PropTypes.object,
    title: PropTypes.string,
    children: PropTypes.object,
    onConfirm: PropTypes.func,
    confirmLabel: PropTypes.string,
    onClose: PropTypes.func,
    isOpen: PropTypes.bool
};

export default withStyles(styles)(SimpleModal);
