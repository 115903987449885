import api from './api';

const query = () =>
    api.request({
        method: 'GET',
        url: '/stocks/from-segments'
    });

export default {
    query
};
