import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { messageTest as styles } from './styles';
import { modalsButtonStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';
import { withStyles, makeStyles, Box, Typography, TextField, Button } from '@material-ui/core';
import { MESSAGE_TYPE, NOTIFICATION_MESSAGE_TYPE } from '../../constants/index';
import { toastr } from 'react-redux-toastr';
import campaignApi from '../../api/campaignApi';
import { useSelector } from 'react-redux';

function SendMessageTest({ classes, format }) {
    const testMessage = useRef(null);
    const form = useSelector((state) => state.message);
    const classesStyles = makeStyles(styles)();
    const globalButtonsStyles = makeStyles(modalsButtonStyles)();

    const sendTest = async () => {
        if (!testMessage.current.value) {
            if (format === MESSAGE_TYPE.EMAIL) {
                return toastr.warning('Please enter an email address');
            }
            if (format === MESSAGE_TYPE.SMS) {
                return toastr.warning('Please enter a mobile number');
            }
        } else if (!form.subject && format === MESSAGE_TYPE.EMAIL) {
            return toastr.warning('You need to fill the subject!');
        } else if (!form.content) {
            return toastr.warning('You need to write something on the content of the email!');
        }
        try {
            const mailRes = await campaignApi.sendMessageTest({
                recipient: testMessage.current.value.split(',').map((item) => item.trim()),
                content: form.content,
                subject: form.subject,
                format: form.format,
                segment: form.segment
            });
            if (mailRes.delivered) {
                toastr.success(`Test ${form.format.toLowerCase()} sent!`);
            } else {
                toastr.error('Something went wrong');
            }
        } catch (err) {
            return toastr.error(err.data.message || 'Something went wrong');
        }
    };

    return (
        <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row" mt={3} alignItems="center">
                <Typography>
                    Send test{' '}
                    {form.format === MESSAGE_TYPE.EMAIL || form.format === NOTIFICATION_MESSAGE_TYPE.EMAIL
                        ? form.format.toLowerCase()
                        : form.format}
                    :
                </Typography>
                <TextField
                    inputRef={testMessage}
                    style={{ width: '50%', marginLeft: 16, marginRight: 16 }}
                    placeholder={
                        form.format === MESSAGE_TYPE.EMAIL || form.format === NOTIFICATION_MESSAGE_TYPE.EMAIL
                            ? 'Separate email addresses with comma'
                            : 'Number must start with +44 (omit next 0). Separate numbers with commas.'
                    }
                    variant="outlined"
                    className={classesStyles.input}
                />
                <Button
                    onClick={async () => await sendTest()}
                    variant="contained"
                    color="primary"
                    className={`${globalButtonsStyles.baseButton} ${classes.testBtn}`}
                >
                    Send
                </Button>
            </Box>
        </Box>
    );
}

SendMessageTest.propTypes = {
    classes: PropTypes.object.isRequired,
    format: PropTypes.string
};

export default withStyles(styles)(SendMessageTest);
