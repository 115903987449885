import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    Box,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
    withStyles,
    InputAdornment,
    TextField,
    TablePagination,
    Button as MaterialButton,
    CircularProgress,
    Menu,
    MenuItem
} from '@material-ui/core';
import Moment from 'moment-timezone';
import { Search } from '@material-ui/icons';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';
import Button from '../common/Button';
// import TextField from '../common/TextField';
import segmentApi from '../../api/segmentApi';
import campaignApi from '../../api/campaignApi';
import { dynamicSegmentListViewStyles as styles } from './styles';
import CancelContinueModal from '../../collums-components/components/common/CancelContinueModal';

const campaignsUsingSegment = async (segmentId) => {
    try {
        const res = await campaignApi.query({ segment: segmentId });
        return res.data || [];
    } catch (e) {
        toastr.error('Something went wrong');
        return [];
    }
};

const CampaignsUsingSegmentList = (campaigns) => {
    const campaignText = (camp, i) => (
        <span key={i}>
            <br />
            {camp.name}, status {camp.status}
            <br />
        </span>
    );
    return (
        <div>
            {campaigns.length > 0 && 'This segment is currently in use with campaign:'}
            {campaigns.map((camp, i) => campaignText(camp, i))}
            Are you sure you want to delete this segment?
        </div>
    );
};

const CampaignList = ({ classes }) => {
    const history = useHistory();
    const [displayedSegments, setDisplayedSegments] = useState([]);
    const [nameFilter, setNameFilter] = useState('');
    const [sort, setSort] = useState({ property: 'createdAt', order: 'desc' });
    const [pageSize, setPageSize] = useState(25);
    const [page, setPage] = useState(0);
    const [totalSegments, setTotalSegments] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingCount, setIsLoadingCount] = useState(false);
    const [deletingSegment, setDeletingSegment] = useState({ showModal: false, id: '' });
    const [campaignsOfdeletingSegment, setCampaignsOfDeletingSegment] = useState([]);

    useEffect(() => {
        if (deletingSegment.showModal && deletingSegment.id) {
            setIsLoading(true);
            campaignsUsingSegment(deletingSegment.id)
                .then((data) => setCampaignsOfDeletingSegment(data))
                .finally(() => setIsLoading(false));
        }
        //eslint-disable-next-line
    }, [deletingSegment.showModal]);

    useEffect(() => {
        if (page) changePage(`${sort.order === 'desc' ? '-' : ''}${sort.property}`);
        //eslint-disable-next-line
    }, [page]);

    useEffect(() => {
        refreshList(`${sort.order === 'desc' ? '-' : ''}${sort.property}`, !nameFilter);
        //eslint-disable-next-line
    }, [nameFilter]);

    useEffect(() => {
        refreshList(`${sort.order === 'desc' ? '-' : ''}${sort.property}`);
        //eslint-disable-next-line
    }, [sort, pageSize]);
    const ActionButton = ({ row }) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const openMenu = (event) => {
            event.preventDefault();
            event.stopPropagation();
            setAnchorEl(event.currentTarget);
        };
        const closeMenu = (e) => {
            e.stopPropagation();
            setAnchorEl(null);
        };
        ActionButton.propTypes = {
            row: PropTypes.object
        };
        return (
            <>
                <MaterialButton onClick={openMenu} className={classes.grayButton}>
                    Options
                </MaterialButton>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
                    <MenuItem
                        onClick={(e) => {
                            downloadCsv(row.id);
                            closeMenu(e);
                        }}
                    >
                        Download CSV
                    </MenuItem>
                    <MenuItem
                        onClick={(e) => {
                            const equalNames = displayedSegments
                                .map((displayedSegment) => displayedSegment.name)
                                .filter(
                                    (name) =>
                                        name.startsWith(
                                            row.name.slice(
                                                0,
                                                row.name.indexOf(' -') >= 0 ? row.name.indexOf(' -') : row.name.length
                                            )
                                        ) || name === row.name
                                );
                            let name = row.name + ' - Copy'.repeat(equalNames.length);
                            copySegment(row, name);
                            closeMenu(e);
                        }}
                    >
                        Copy
                    </MenuItem>
                    <MenuItem
                        onClick={(e) => {
                            setDeletingSegment({ showModal: true, id: row.id, row });
                            closeMenu(e);
                        }}
                    >
                        Delete
                    </MenuItem>
                </Menu>
            </>
        );
    };
    const refreshList = async (sortBy, loadingScreen = true) => {
        if (page !== 0) setPage(0);
        try {
            loadingScreen && setIsLoading(true);
            const result = await segmentApi.query({
                countMatchedCustomers: false,
                nameFilter,
                page: 0,
                pageSize,
                pagination: true,
                sortBy
            });
            setDisplayedSegments(result.segments);
            setTotalSegments(result.totalSegments);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    const changePage = async (sortBy) => {
        try {
            setIsLoading(true);
            const result = await segmentApi.query({
                countMatchedCustomers: false,
                nameFilter,
                page,
                pageSize,
                pagination: true,
                sortBy
            });
            setDisplayedSegments(result.segments);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    const getNewOrder = (property) => {
        if (property === sort.property) {
            return 'asc' === sort.order ? 'desc' : 'asc';
        } else {
            return 'asc';
        }
    };

    const newSegment = () => history.push('/segments/dynamic/create');

    const editSegment = (segmentId) => history.push(`/segments/dynamic/create?id=${segmentId}`);

    const copySegment = async (segment, name) => {
        const newSegment = {
            name: name,
            allOrAny: segment.allOrAny,
            description: segment.description,
            rules: segment.rules
        };
        await segmentApi.create(newSegment).then(() => {
            toastr.success('Campaign successfully copied');
        });
        await refreshList();
    };

    const deleteSegment = async (segmentId) => {
        try {
            await segmentApi.remove(segmentId);
            await refreshList(`${sort.order === 'desc' ? '-' : ''}${sort.property}`);
            toastr.success('Segment deleted successfully');
        } catch (e) {
            toastr.error('Something went wrong');
        }
    };

    const countCustomersForSegment = async (id) => {
        setIsLoadingCount(true);
        let newSegments = displayedSegments.map((value) => {
            if (value.id === id) {
                value.loading = true;
            }
            return value;
        });
        setDisplayedSegments(newSegments);

        const countCustomer = await segmentApi
            .countCustomersForSegment(id)
            .then((result) => result)
            .catch((error) => console.error(error));
        newSegments = displayedSegments.map((value) => {
            if (value.id === id) {
                value.matchedCustomersCount = countCustomer.total;
                value.matchedCustomersAllowed = countCustomer.marketingAllowed;
                delete value.loading;
            }
            return value;
        });
        setDisplayedSegments(newSegments);
        setIsLoadingCount(false);
    };

    const downloadCsv = (segmentId) => segmentApi.downloadCsv(segmentId);

    const renderSegmentCount = (segment) => {
        if (segment.loading) {
            return (
                <div className={classes.loadingIcon}>
                    <CircularProgress className={classes.searchIconProgress} size={18} />
                </div>
            );
        }
        if (segment.matchedCustomersCount !== undefined) {
            return (
                <span
                    style={{ textAlign: 'center' }}
                >{`${segment.matchedCustomersCount} (${segment.matchedCustomersAllowed})`}</span>
            );
        }
        return (
            <MaterialButton
                className={classes.loadCount}
                disabled={isLoadingCount}
                placeholder={'count segment'}
                onClick={() => countCustomersForSegment(segment.id)}
            >
                <Typography>Load count</Typography>
            </MaterialButton>
        );
    };

    return (
        <Box className={classes.rootBox}>
            <Button className={classes.createNewButton} type="secondary" onClick={newSegment}>
                New segment
            </Button>
            <TextField
                variant="outlined"
                className={classes.filterInput}
                placeholder="Segment name"
                onChange={(e) => {
                    setNameFilter(e.target.value);
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton>
                                <Search />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
            <Table size="medium" stickyHeader className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableHeader}>
                            <TableSortLabel
                                active={'name' === sort.property}
                                direction={'name' === sort.property ? sort.order : 'asc'}
                                onClick={() => {
                                    setSort({ property: 'name', order: getNewOrder('name') });
                                }}
                            >
                                Name
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className={classes.tableHeader}>
                            <span>Description</span>
                        </TableCell>
                        <TableCell className={classes.tableHeader}>
                            <TableSortLabel
                                active={'createdAt' === sort.property}
                                direction={'createdAt' === sort.property ? sort.order : 'asc'}
                                onClick={() => {
                                    setSort({ property: 'createdAt', order: getNewOrder('createdAt') });
                                }}
                            >
                                Created
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className={classes.tableHeader} style={{ textAlign: 'center' }}>
                            <TableSortLabel
                                active={'matchedCustomersCount' === sort.property}
                                style={{ marginRight: '-10px' }}
                                direction={'matchedCustomersCount' === sort.property ? sort.order : 'asc'}
                                onClick={() => {
                                    setSort({
                                        property: 'matchedCustomersCount',
                                        order: getNewOrder('matchedCustomersCount')
                                    });
                                }}
                            >
                                #
                            </TableSortLabel>
                        </TableCell>
                        {/* <TableCell className={classes.tableHeader}/> */}
                        <TableCell className={classNames(classes.tableHeader, classes.tableActionsColumns)}>
                            <span className="MuiButtonBase-root MuiTableSortLabel-root">&nbsp;&nbsp;</span>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {displayedSegments.map((segment) => {
                        return (
                            <TableRow
                                className={classes.tableRow}
                                hover
                                style={{ cursor: 'default' }}
                                tabIndex={-1}
                                key={segment.id}
                            >
                                <TableCell className={classes.tableBodyCell} style={{ width: '30%' }}>
                                    <span
                                        style={{ color: '#3083EC', fontSize: '14px', cursor: 'pointer' }}
                                        onClick={() => editSegment(segment.id)}
                                    >
                                        {segment.name}
                                    </span>
                                </TableCell>
                                <TableCell className={classes.tableBodyCell} style={{ width: '40%' }}>
                                    <span>{segment.description}</span>
                                </TableCell>
                                <TableCell className={classes.tableBodyCell} style={{ width: '8%' }}>
                                    <span>{Moment(segment.createdAt).format('D/M/YY')}</span>
                                </TableCell>
                                <TableCell className={classes.tableBodyCell} style={{ width: '8%' }}>
                                    {renderSegmentCount(segment)}
                                </TableCell>
                                {/* <TableCell className={classes.tableBodyCell}/> */}
                                <TableCell className={classNames(classes.tableBodyCell, classes.tableActionsColumns)}>
                                    <ActionButton key={segment.id} row={segment} />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={totalSegments}
                rowsPerPage={pageSize}
                page={page}
                onChangePage={(e, newPage) => {
                    setPage(newPage);
                    if (newPage === 0) refreshList(`${sort.order === 'desc' ? '-' : ''}${sort.property}`);
                }}
                onChangeRowsPerPage={(e) => {
                    setPageSize(e.target.value);
                }}
                classes={{ root: classes.tablePagination }}
            />
            {isLoading && <LoadingScreen />}
            {deletingSegment.showModal && (
                <CancelContinueModal
                    open
                    onContinue={() => {
                        deleteSegment(deletingSegment.id);
                        setDeletingSegment({ showModal: false });
                    }}
                    onCancel={() => {
                        setDeletingSegment({ showModal: false });
                    }}
                    continueButtonText={'Delete'}
                    title={`Delete ${deletingSegment?.row?.name ? deletingSegment?.row?.name + ' ' : ''}Segment`}
                    contentText={CampaignsUsingSegmentList(campaignsOfdeletingSegment)}
                />
            )}
        </Box>
    );
};

CampaignList.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CampaignList);
