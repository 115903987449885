export const stepperPanel = () => ({
    stepper: {
        '& .MuiPaper-elevation1': {
            boxShadow: 'none'
        },
        '& .MuiStepButton-root': {
            border: '1px solid #c4c4c4',
            borderRadius: 4
        },
        '& .MuiStepIcon-active': {
            color: '#72c2f6'
        }
    },
    marginBottom16px: {
        marginBottom: 16
    },
    nextBtn: {
        background: '#72c2f6',
        textTransform: 'none',
        fontSize: 12,
        boxShadow: 'none',
        borderRadius: 0
    },
    cancelBtn: {
        background: 'white',
        textTransform: 'none',
        fontSize: 12,
        boxShadow: 'none',
        color: '#7C7E86',
        borderColor: '#7C7E86',
        borderStyle: 'solid',
        borderWidth: 1,
        marginRight: 12,
        borderRadius: 0,
        '&:hover': {
            background: 'white',
            boxShadow: 'none'
        }
    }
});

export const campaignListStyles = (theme) => ({
    grayButton: {
        backgroundColor: theme.palette.gray.bold,
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.gray.bolder
        }
    },
    addBtn: {
        background: '#72c2f6',
        textTransform: 'none',
        fontSize: 12,
        boxShadow: 'none',
        '&:hover': {
            background: '#72c2f6',
            boxShadow: 'none'
        }
    },
    selectRoot: {
        backgroundColor: 'white',
        width: '12rem',
        marginRight: 16,
        marginBottom: 16,
        '& > div > div': {
            boxSizing: 'border-box',
            height: 'inherit'
        },
        '& label.MuiInputLabel-shrink': {
            transform: 'translate(-2px, -2px) scale(0.75)',
            transformOrigin: 'top left'
        }
    },
    tableRow: {
        cursor: 'pointer',
        fontWeight: 14
    },
    searchBox: {
        '& input::placeholder': {
            fontSize: '14px !important',
            fontFamily: 'Roboto, Arial, "sans-serif" !important'
        },
        flex: 1,
        maxWidth: '250px',
        width: 'max-content',
        minWidth: '200px'
    },
    selectLabel: {
        fontSize: '14px',
        opacity: 1,
        marginTop: '-0.25rem',
        marginLeft: '1rem'
    },
    tableHeader: {
        fontSize: 14,
        opacity: 1,
        fontWeight: '500!important',
        borderBottom: '1px solid #e0e0e0',
        background: 'white',
        height: 49,
        paddingTop: 0,
        paddingBottom: 0
    },
    tableBodyCell: {
        borderBottom: '1px solid #e0e0e0',
        opacity: 1,
        fontSize: 14,
        paddingTop: 0,
        paddingBottom: 0,
        maxHeight: 49,
        '&> span': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            display: '-webkit-box'
        },
        '@media(max-width: 1024px)': {
            padding: '3px'
        }
    },
    tablePagination: {
        textTransform: 'none'
    },
    actionBtn: {
        marginRight: -15
    },
    marginRight12: {
        marginRight: '12px !important'
    },
    countersDiv: {
        display: 'flex',
        flexDirection: 'column'
    }
});

export const form = () => ({
    linkBtn: {
        margin: 0,
        padding: 0,
        color: '#3083EC',
        textTransform: 'none'
    },
    editReviewBtn: {
        height: 16,
        marginTop: 10,
        marginRight: 12
    },
    checkBoxTree: {
        height: 110,
        flexGrow: 1,
        maxWidth: 400
    },
    formRow: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    marginBottom16px: {
        marginBottom: 16
    },
    marginTop8px: {
        marginTop: 8
    },
    marginTop16px: {
        marginTop: '16px !important'
    },
    marginTop32px: {
        marginTop: '32px !important'
    },
    marginBottom32px: {
        marginBottom: 32
    },
    marginLeft0px: {
        marginLeft: '0px !important'
    },
    marginLeft8px: {
        marginLeft: 8
    },
    marginLeft16px: {
        marginLeft: 16
    },
    marginRight16px: {
        marginRight: 16
    },
    width10: {
        width: '10%'
    },
    opacity05: {
        opacity: 0.5
    },
    editor: {
        height: 430,
        width: '100%',

        '& .rdw-editor-main, & .cm-s-default': {
            borderColor: '#7C7E86',
            borderStyle: 'solid',
            borderWidth: 1
        }
    },
    mergeBtn: {
        background: 'white',
        textTransform: 'none',
        fontSize: 12,
        width: '100%',
        boxShadow: 'none',
        padding: 0,
        marginBottom: 10,
        borderWidth: 1,
        marginRight: 12,
        borderRadius: 0,
        '&:hover': {
            background: 'white',
            boxShadow: 'none'
        }
    },
    selectRoot: {
        backgroundColor: 'white',
        width: '12rem',
        '& > div > div': {
            boxSizing: 'border-box',
            height: 'inherit'
        },
        '& label.MuiInputLabel-shrink': {
            transform: 'translate(-2px, -2px) scale(0.75)',
            transformOrigin: 'top left'
        }
    },
    selectLabel: {
        fontSize: '0.7rem',
        opacity: 1,
        marginTop: '-0.25rem',
        marginLeft: '1rem'
    },
    selectSegmentRoot: {
        backgroundColor: 'white',
        width: '12rem',
        '& > div > div': {
            boxSizing: 'border-box',
            height: 'inherit'
        },
        '& label.MuiInputLabel-shrink': {
            transform: 'translate(11px, -7px) scale(0.75)',
            transformOrigin: 'top left'
        }
    },
    selectSegmentLabel: {
        fontSize: '14px',
        opacity: 1,
        marginTop: '-0.05rem',
        marginLeft: '0.2rem'
    },
    inputReview: {
        '& .Mui-disabled': {
            color: 'black',
            border: 0,
            padding: 0,
            '& .MuiOutlinedInput-notchedOutline': {
                display: 'none'
            }
        }
    },
    smsInput: {
        '& .MuiInputBase-input': {
            lineHeight: 1.1
        }
    },
    mergeTags: {
        overflowY: 'scroll'
    }
});

export const deleteConfirmationModalStyles = () => ({
    dialogTitle: {
        alignSelf: 'center',
        '& h2': {
            fontFamily: 'Roboto,Arial,Sans-serif',
            fontSize: 16,
            fontWeight: 500
        }
    },
    dialogContent: {
        display: 'flex',
        'align-items': 'center',
        ' justify-content': 'center',
        '& p': {
            fontFamily: 'Roboto,Arial,Sans-serif',
            color: ' rgba(0, 0, 0, 0.87)',
            marginBottom: 0
        }
    },
    buttonsContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        padding: '0.5rem 3rem',
        '& button': {
            width: 80,
            height: 38,
            borderRadius: 0,
            marginBottom: 16,
            textTransform: 'none',
            fontSize: 14,
            fontWeight: 500,
            padding: '6px 16px',
            border: '1px solid #000',
            fontFamily: 'Roboto,Arial,Sans-serif',
            color: 'rgba(0, 0, 0, 0.87)'
        },
        '& button:nth-child(2)': {
            backgroundColor: ' #b41778',
            color: 'white',
            border: '0px solid #000'
        }
    }
});
export const modalStyles = () => ({
    dialogTitle: {
        alignSelf: 'center',
        '& h2': {
            fontFamily: 'Roboto,Arial,Sans-serif',
            fontSize: 16,
            fontWeight: 500
        }
    },
    dialogContent: {
        display: 'flex',
        'align-items': 'center',
        ' justify-content': 'center',
        '& p': {
            fontFamily: 'Roboto,Arial,Sans-serif',
            color: ' rgba(0, 0, 0, 0.87)',
            marginBottom: 0
        }
    },
    buttonsContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        padding: '0.5rem 3rem',
        '& button': {
            width: 80,
            height: 38,
            borderRadius: 0,
            marginBottom: 16,
            textTransform: 'none',
            fontSize: 14,
            fontWeight: 500,
            padding: '6px 16px',
            border: '1px solid #000',
            fontFamily: 'Roboto,Arial,Sans-serif',
            color: 'rgba(0, 0, 0, 0.87)'
        },
        '& button:nth-child(2)': {
            backgroundColor: ' #b41778',
            color: 'white',
            border: '0px solid #000'
        }
    }
});
