import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { warningModalStyles } from './styles';
import CancelContinueModal from '../common/CancelContinueModal';

const WarningModal = ({ setShowWarning, leaveAction }) => {
    return (
        <CancelContinueModal
            setOpen={setShowWarning}
            onContinue={leaveAction}
            title={'Your changes will not saved'}
            contentText={'Are you sure you want to continue?'}
        />
    );
};

WarningModal.propTypes = {
    classes: PropTypes.object.isRequired,
    setShowWarning: PropTypes.func,
    leaveAction: PropTypes.func
};

export default withStyles(warningModalStyles)(WarningModal);
