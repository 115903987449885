import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { emailEditor as styles } from './styles';
import { Box, withStyles } from '@material-ui/core';
import TinyMce from '../../collums-components/components/common/TinyMce/TinyMce';
import BadgeLoading from './BadgeLoading';
import base64extractor from '../../services/base64extractor';
import base64converter from '../../services/base64converter';
import campaignApi from '../../api/campaignApi';
import { useSelector, useDispatch } from 'react-redux';
import { addOnForm } from '../../redux/actions/messageActions';
import { toastr } from 'react-redux-toastr';
import LoadingScreen from "../../collums-components/components/common/loadingScreen";

const TinyEmailEditor = ({ classes, setLoadingUpload, loadingUpload, editor, onClick }) => {
    const form = useSelector((state) => state.message);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const handleEditorState = async (content) => {
        if (!content.length) return;

        let data = content;

        // Extract the base64 images
        const base64img = data.includes('data:image') ? base64extractor(data) : null;

        // base64 images found in the editor
        if (base64img) {
            setLoadingUpload({ ...loadingUpload, visible: true });
            // Convert each base64 image to file
            const fileimg = base64img.map((img) =>
                base64converter(img, `image-${new Date().getTime()}.${img.split(/[/;]/g)[1]}`)
            );

            const path = [];

            // Insert each image in the database
            const promises = await fileimg.map(async (img) => path.push(await campaignApi.uploadImage(img)));

            // Await until all the images are inserted in the bucket
            await Promise.all(promises);

            // Replace all base64 images by S3 link files
            path.forEach((el) => (data = data.replace(/<img src="data:.*?>/, `<img src="${el}">`)));
            setLoadingUpload({ visible: true, status: 'complete' });
        }

        dispatch(addOnForm({ content: data }));
    };

    const defaultConfig = {
        selector: '.tinymce',
        plugins: ['lists', 'code', 'image', 'link', 'media', 'table'],
        toolbar:
            'undo redo | ' +
            'styles | ' +
            'bold italic strikethrough forecolor backcolor | ' +
            'link image media | ' +
            'table | ' +
            'indent align bullist numlist | ' +
            'code removeformat',
        table_toolbar:
            'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
        toolbar_mode: 'wrap',
        fixed_toolbar_container: '.toolbar',
        menubar: true,
        inline: false,
        auto_focus: 'editor-1',
        object_resizing: false,
        placeholder: 'Write here...',
        images_file_types: 'jpeg,jpg,png,gif',
        browser_spellcheck: true,
        automatic_uploads: true,
        file_picker_types: 'image,file',
        file_picker_callback: function(cb, value, meta) {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', meta.filetype === 'image' ? 'image/*' : '*/*');

            input.onchange = function() {
                setIsLoading(true);
                const file = this.files[0];
                const reader = new FileReader();

                reader.onload = function() {
                    const dataURL = reader.result;

                    fetch((process.env.REACT_APP_BACKEND_URL || process.env.COLLUMS_API_URL) + '/email-attachment-upload', {
                        method: 'POST',
                        headers: {
                            Authorization: localStorage.getItem('token'),
                            'Content-Type': 'application/json',
                            Accept: 'application/json'
                        },
                        body: JSON.stringify({ dataURL, fileName: file.name })
                    })
                        .then(response => {
                            if (response.status === 413) {
                                throw new Error('File exceeds max size 50MB');
                            }
                            return    response.json()
                        })
                        .then(data => {
                            cb(data.imageUrl, { alt: file.name, text: file.name });
                            setIsLoading(false);
                        })
                        .catch((e) => {
                            setIsLoading(false);
                            if (e.message) {
                                toastr.error(e.message);
                            } else {
                                toastr.error('Error uploading file. Please try again.');
                            }
                        });
                };

                reader.readAsDataURL(file);
            };

            input.click();
        }
    };

    const handleLoadingUpload = () => {
        setLoadingUpload({ visible: false, status: 'loading' });
    };

    // If new image is inserted, instant
    const handleInputEditor = (data) => (data.includes('data:image') ? handleEditorState(data) : '');

    const onEditorChangeHandler = (content) => {
        handleInputEditor(content);
        dispatch(addOnForm({ content }));
    };

    const onEditorBlurHandler = (event, editor) => {
        if (editor.isDirty()) {
            handleEditorState(editor.getContent());
        }
    };

    const onEditorClickHandler = () => {
        if (onClick) {
            onClick();
        }
    };

    return (
        <>
            <Box className={classes.editor}>
                <TinyMce
                    value={form.content}
                    className={classes.editor}
                    refEditor={editor}
                    onChange={onEditorChangeHandler}
                    init={{
                        ...defaultConfig,
                        selector: 'textarea',
                        height: '100%',
                        width: '100%',
                        max_height: 700,
                        min_height: 430
                    }}
                    editorProps={{
                        onBlur: onEditorBlurHandler,
                        onClick: onEditorClickHandler
                    }}
                />
                {isLoading && <LoadingScreen />}
            </Box>
            <BadgeLoading
                data={loadingUpload}
                setVisibility={handleLoadingUpload}
                style={{
                    marginTop: '4%',
                    marginLeft: '-11%'
                }}
            />
        </>
    );
};

TinyEmailEditor.propTypes = {
    classes: PropTypes.object.isRequired,
    setLoadingUpload: PropTypes.func,
    loadingUpload: PropTypes.object,
    editor: PropTypes.object,
    onClick: PropTypes.func
};

export default React.memo(withStyles(styles)(TinyEmailEditor));
