import React, { useState } from 'react';

import shortner from '../../services/urlShortener';
import PropTypes from 'prop-types';
import { Button, withStyles } from '@material-ui/core';

import { urlShorter as styles } from './styles';

const UrlShortener = ({ classes, visible, setVisibility, onChange }) => {
    const [url, setUrl] = useState('');

    const handleConvertion = async () => {
        shortner(url).then((res) => {
            if (res.data.shorturl) {
                onChange(res.data.shorturl);
            } else {
                onChange('');
            }
            setUrl('');
        });
    };

    return (
        <div
            className={classes.urlShorterWraper}
            style={{
                display: visible ? 'block' : 'none'
            }}
        >
            <div>
                <input
                    style={{ width: '100%', height: '2rem' }}
                    type="text"
                    value={url}
                    onChange={(event) => setUrl(event.target.value)}
                />
            </div>
            <div className={classes.urlShorterBtn}>
                <Button className={classes.cancelBtn} onClick={setVisibility}>
                    Cancel
                </Button>
                <Button className={classes.nextBtn} onClick={() => handleConvertion()}>
                    Insert
                </Button>
            </div>
        </div>
    );
};

UrlShortener.propTypes = {
    classes: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired,
    setVisibility: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired
};

export default withStyles(styles)(UrlShortener);
