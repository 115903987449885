import React from 'react';
import PropTypes from 'prop-types';
import { form as styles } from '../styles';
import { withStyles, Card, Box, TextField, FormControl } from '@material-ui/core';
import CampaignFormActionButtons from './CampaignFormActionButtons';
import { useSelector, useDispatch } from 'react-redux';
import { addOnForm } from '../../../redux/actions/messageActions';

function CampaignFormInfo({ nextStep, classes, setDisplayCancelModal }) {
    const dispatch = useDispatch();
    const form = useSelector((state) => state.message);

    return (
        <Card variant="elevation">
            <Box p={2} display="grid">
                <FormControl fullWidth required className={`${classes.formRow} ${classes.marginBottom16px}`}>
                    <TextField
                        label="Name *"
                        className={classes.inputField}
                        variant="outlined"
                        value={form.name || ''}
                        fullWidth
                        onChange={(e) => dispatch(addOnForm({ name: e.target.value }))}
                    />
                </FormControl>
                <FormControl required className={`${classes.formRow} ${classes.marginBottom16px}`}>
                    <TextField
                        label="Description"
                        className={classes.inputField}
                        variant="outlined"
                        fullWidth
                        value={form.description || ''}
                        onChange={(e) => dispatch(addOnForm({ description: e.target.value }))}
                    />
                </FormControl>
                <CampaignFormActionButtons
                    next={nextStep}
                    enableNext={!!form.name}
                    cancel={() => setDisplayCancelModal(true)}
                />
            </Box>
        </Card>
    );
}

CampaignFormInfo.propTypes = {
    classes: PropTypes.object.isRequired,
    nextStep: PropTypes.func,
    setDisplayCancelModal: PropTypes.func
};

export default withStyles(styles)(CampaignFormInfo);
