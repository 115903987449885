import React from 'react';
import PropTypes from 'prop-types';
import Picker, { SKIN_TONE_NEUTRAL } from 'emoji-picker-react';

const EmojiPicker = ({ visible, addEmoji }) => {
    return (
        <div
            style={{
                display: visible ? 'block' : 'none'
            }}
        >
            <Picker
                onEmojiClick={(event, item) => addEmoji(item.emoji)}
                disableAutoFocus={true}
                skinTone={SKIN_TONE_NEUTRAL}
                groupNames={{ smileys_people: 'PEOPLE' }}
            />
        </div>
    );
};

EmojiPicker.propTypes = {
    visible: PropTypes.bool.isRequired,
    addEmoji: PropTypes.func.isRequired
};

export default EmojiPicker;
