import api from './api';

const query = () =>
    api.request({
        method: 'GET',
        url: '/practitioners/filterBy'
    });

export default {
    query
};
