import { MESSAGE_FORM_REDUCER } from '../../constants/index';

export const addOnForm = (payload) => {
    return (dispatch) => {
        dispatch({
            type: MESSAGE_FORM_REDUCER.ADD_ON_FORM,
            payload
        });
    };
};

export const updatePrevious = (payload) => {
    return (dispatch) => {
        dispatch({
            type: MESSAGE_FORM_REDUCER.PREVIOUS_FORM,
            payload
        });
    };
};

export const cleanFields = () => {
    return (dispatch) => {
        dispatch({
            type: MESSAGE_FORM_REDUCER.CLEAN_FIELDS
        });
    };
};
