import React, { useState, useEffect } from 'react';
import { Box, Step, StepButton, StepContent, Stepper, IconButton, withStyles } from '@material-ui/core';
import CancelContinueModal from '../../common/CancelContinueModal';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { Close } from '@material-ui/icons';
import { stepperPanel } from '../styles';
import CampaignFormInfo from './CampaignFormInfo';
import CampaignFormRecipients from './CampaignFormRecipients';
import CampaignFormSend from './CampaignFormSend';
import CampaignFormContent from './CampaignFormContent';
import campaignApi from '../../../api/campaignApi';
import { toastr } from 'react-redux-toastr';
import { useSelector, useDispatch } from 'react-redux';
import { addOnForm, cleanFields, updatePrevious } from '../../../redux/actions/messageActions';

import moment from 'moment';

import LoadingScreen from '../../../collums-components/components/common/loadingScreen';
import { CAMPAIGN_STATUS, CAMPAIGN_TYPES } from '../../../constants';
import _ from 'lodash';

function CampaignForm({ classes }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [displayCancelModal, setDisplayCancelModal] = useState(false);

    const form = useSelector((state) => state.message);

    useEffect(() => {
        dispatch(addOnForm({ format: params.type && params.type.toUpperCase() }));
        dispatch(updatePrevious({ format: params.type && params.type.toUpperCase() }));
        /* eslint-disable-next-line */
    }, []);

    const [campaignId, setCampaignId] = useState(undefined);

    // Used to avoid saving on the first render (useless saves will change status when it shouldn't)
    // const didMountRef = useRef(false);

    const next = (fields) => {
        const newForm = _.pick(form, fields);
        const status = form.status || CAMPAIGN_STATUS.DRAFT;
        campaignApi.saveCampaign({ ...newForm, id: campaignId, status }).then((res) => {
            setCampaignId(res.id);
            dispatch(updatePrevious({ ...form }));
        });
        setActiveStep((active) => active + 1);
    };

    useEffect(() => {
        if (params.id) {
            setCampaignId(params.id);
            campaignApi.getCampaign(params.id).then((res) => {
                if (res.recurringTime) {
                    dispatch(
                        updatePrevious({
                            ...res,
                            nextSend: moment(res.nextSend)
                        })
                    );
                    dispatch(
                        addOnForm({
                            ...res,
                            nextSend: moment(res.nextSend)
                        })
                    );
                } else {
                    dispatch(updatePrevious({ ...res }));
                    dispatch(addOnForm(res));
                }
            });
        }
        /* eslint-disable-next-line */
    }, [params.id]);

    const validate = (data, type) => {
        if (type === CAMPAIGN_TYPES.RECURRING) {
            if (!data.recurringStart) {
                toastr.error('Recurring start date is required');
                return false;
            }
            if (!data.recurringEnd) {
                toastr.error('Recurring end date is required');
                return false;
            }
            if (!data.recurringDays) {
                toastr.error('Recurring week days are required');
                return false;
            }
        }
        return true;
    };

    const save = (type, status = undefined) => {
        const data = { ...form, type, status };

        if (type === CAMPAIGN_TYPES.ONE_OFF) {
            data.nextSend = undefined;
        }
        if (type === CAMPAIGN_TYPES.RECURRING) {
            data.recurringStart = data.recurringStart || new moment();
            data.recurringEnd = data.recurringEnd || new moment();
        }
        if (!validate(data, type)) return;
        if (type === CAMPAIGN_TYPES.RECURRING && form.noEndDate) {
            data.recurringEnd = moment().add(50, 'years');
        }
        setIsLoading(true);
        campaignApi
            .saveCampaign({ ...data, id: campaignId })
            .then(() => {
                toastr.success('Campaign created succesfully');
                setIsLoading(false);
                dispatch(cleanFields());
                history.push('/');
            })
            .catch((error) => {
                setIsLoading(false);
                toastr.error(error?.data?.message || error?.message || error);
            });
    };

    const checkFormat = (word) => {
        return word.length <= 3
            ? word.charAt(0).toUpperCase() + word.slice(1)
            : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    };
    return (
        <>
            <Box p={4}>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    position="relative"
                    className={classes.marginBottom16px}
                >
                    {form.format && (
                        <b style={{ fontSize: 16 }}>
                            {history.location.pathname.includes('/new')
                                ? `Create ${checkFormat(form.format)} Campaign`
                                : `Edit ${checkFormat(form.format)} Campaign - ${form.name}`}
                        </b>
                    )}
                    <IconButton
                        onClick={() => setDisplayCancelModal(true)}
                        aria-label="delete"
                        className={classes.margin}
                        size="medium"
                        style={{
                            position: 'absolute',
                            right: 5,
                            alignSelf: 'center'
                        }}
                    >
                        <Close color="#010100" fontSize="inherit" />
                    </IconButton>
                </Box>
                <Stepper nonLinear activeStep={activeStep} orientation="vertical" className={classes.stepper}>
                    <Step key="panel-info">
                        <StepButton onClick={() => setActiveStep(0)}>Campaign info</StepButton>
                        <StepContent>
                            <CampaignFormInfo
                                nextStep={() => next(['name', 'description', 'format'])}
                                setDisplayCancelModal={setDisplayCancelModal}
                            />
                        </StepContent>
                    </Step>
                    <Step key="panel-recipients">
                        <StepButton onClick={() => setActiveStep(1)}>Recipients</StepButton>
                        <StepContent>
                            <CampaignFormRecipients
                                nextStep={() => next(['segment', 'format'])}
                                setDisplayCancelModal={setDisplayCancelModal}
                            />
                        </StepContent>
                    </Step>
                    <Step key="panel-content">
                        <StepButton onClick={() => setActiveStep(2)}>Content</StepButton>
                        <StepContent>
                            <CampaignFormContent
                                nextStep={() => next(['content', 'subject', 'preview', 'format'])}
                                setDisplayCancelModal={setDisplayCancelModal}
                            />
                        </StepContent>
                    </Step>
                    <Step key="panel-send">
                        <StepButton onClick={() => setActiveStep(3)}>Send</StepButton>
                        <StepContent>
                            <CampaignFormSend onSave={save} />
                        </StepContent>
                    </Step>
                </Stepper>
            </Box>
            {displayCancelModal && (
                <CancelContinueModal
                    setOpen={setDisplayCancelModal}
                    onContinue={() => {
                        dispatch(cleanFields());
                        history.push('/');
                    }}
                    title={'Your changes will not be saved'}
                    contentText={'Are you sure you want to continue?'}
                />
            )}
            {isLoading && <LoadingScreen />}
        </>
    );
}

CampaignForm.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(stepperPanel)(CampaignForm);
