const types = {
    appointment: 'Appointment',
    course: 'Course',
    customer: 'Client',
    practitioner: 'Practitioner',
    product: 'Product'
};
const typeConditions = {
    appointment: 'appointmentCondition',
    course: 'courseCondition',
    customer: 'customerCondition',
    practitioner: 'practitionerCondition',
    product: 'productCondition'
};
const condititions = [
    { value: 'age', label: 'Age' },
    { value: 'birthday', label: 'Birthday' },
    { value: 'city', label: 'City' },
    { value: 'creationAnniversary', label: 'Creation anniversary' },
    { value: 'creationDate', label: 'Creation date' },
    { value: 'country', label: 'Country' },
    { value: 'gender', label: 'Gender' },
    { value: 'referralSource', label: 'Referral source' },
    { value: 'state', label: 'State' },
    { value: 'tag', label: 'Tag' },
    { value: 'is', label: 'is' },
    { value: 'is not', label: 'is not' },
    { value: 'expired', label: 'expired' },
    { value: 'purchased', label: 'purchased' },
    { value: 'remaining', label: 'remaining' },
    { value: 'willExpire', label: 'will expire' },
    { value: 'did not purchase', label: "Didn't purchase" },
    { value: 'purchased', label: 'Purchased' },
    { value: 'appointmentFrequency', label: 'Frequency of appts' },
    { value: 'lastAppointment', label: 'Last appointment' }
];
const operators = [
    { value: 'after', label: 'is After' },
    { value: 'before', label: 'is Before' },
    { value: 'between', label: 'is Between' },
    { value: 'exactly', label: 'is Exactly' },
    { value: 'lessThan', label: 'is Less than' },
    { value: 'moreThan', label: 'More than' },
    { value: 'all', label: 'is All' },
    { value: 'any', label: 'is Any' },
    ...new Array(31).fill(31).map((value, index) => {
        return { value: String(index + 1), label: String(index + 1) };
    })
];

const join = (array, sep, lastSep) => {
    if (!array.length) {
        return '';
    }
    return array.length === 1
        ? array[0]
        : array.reduce((acc, value, idx) => `${acc}${idx + 1 === array.length ? lastSep : sep}${value}`);
};

const finalText = (rule, conditionType) => {
    const condition = rule[conditionType];
    const dateProps = ['birthday', 'creationAnniversary', 'creationDate'];
    const textProps = ['city', 'state'];
    switch (conditionType) {
        case typeConditions.customer:
            if (condition === 'age' && rule.operator !== 'between') {
                return `${rule.ageValue} years old`;
            }
            if (condition === 'age' && rule.operator === 'between') {
                return `${rule.ageFrom} and ${rule.ageTo} years old`;
            }
            if (condition === 'gender') {
                return `${rule.gender}`;
            }
            if (condition === 'referralSource') {
                return `${rule.referralSource}`;
            }
            if (dateProps.includes(condition)) {
                if (rule.operator !== 'between') {
                    if (Number.isNaN(Number(rule.operator))) {
                        return `${rule.customerDateValue}`;
                    } else {
                        return `${rule.dateType} in the ${rule.dateMoment}`;
                    }
                } else {
                    return `${rule.customerDateFrom} and ${rule.customerDateTo}`;
                }
            }
            if (condition === 'tag') {
                return `${join(
                    rule.tags.map((tag) => tag.name),
                    ', ',
                    ' and '
                )}`;
            }
            if (condition === 'country') {
                return `${rule.country}`;
            }
            if (textProps.find((prop) => prop === condition)) {
                return `${rule.textValue}`;
            }
            break;
        case typeConditions.appointment: {
            let apptText = '';
            if (condition !== 'appointmentFrequency' && rule.operator !== 'between') {
                apptText += `${rule.occurrenceValue} ${rule.occurrenceUnit} ${
                    'lastAppointment' === condition ? 'ago' : 'in the future'
                }`;
            }
            if (condition !== 'appointmentFrequency' && rule.operator === 'between') {
                apptText += `${rule.occurrenceFrom} and ${rule.occurrenceTo}`;
            }
            if (condition === 'appointmentFrequency') {
                const frequencyText =
                    rule.operator !== 'between'
                        ? `${rule.frequencyValue}`
                        : `${rule.frequencyFrom} and ${rule.frequencyTo}`;
                apptText += `${frequencyText} times per ${rule.frequencyTimesUnit} in the last ${rule.frequencyLastPeriodValue} ${rule.frequencyLastPeriodUnit}`;
            }

            apptText += ` and ${rule.canHaveFutureBooking} have the same booking in the future`;

            return apptText;
        }
        case typeConditions.practitioner:
            return `${rule.practitioner.displayName}`;
        case typeConditions.course: {
            let courseText = '';
            let nonEditableText = '';
            if (rule.courseCondition === 'willExpire') nonEditableText = 'from now';
            else if (rule.courseCondition === 'expired' || rule.courseCondition === 'purchased')
                nonEditableText = 'ago';
            if (rule.operator !== 'between') {
                courseText += `${rule.courseFrom} ${rule.dateType} ${nonEditableText}`;
            } else {
                if (rule.courseCondition === 'remaining') {
                    courseText += `${rule.courseFrom} and ${rule.courseTo} ${rule.dateType}`;
                } else {
                    courseText += `${rule.courseDateFrom} and ${rule.courseDateTo}`;
                }
            }
            return courseText;
        }
        case typeConditions.product: {
            let productText = '';
            if (rule.operator !== 'between') {
                productText += `${rule.productFrom} ${rule.dateType} ago`;
            } else {
                productText += `${rule.productDateFrom} and ${rule.productDateTo}`;
            }
            return productText;
        }
        default:
            return '';
    }
    return '';
};

export const convertSegmentToText = (segment) => {
    const rules = segment.rules;
    const result = rules.map((rule) => {
        const typeText = ((type) => {
            switch (type) {
                case 'appointment':
                    return `${rule.service.name}`;
                case 'product':
                    return `${join(
                        rule.products.map((product) => product.name),
                        ', ',
                        ' and '
                    )}`;
                case 'course':
                    return `${rule.course.name}`;
                default:
                    return types[rule.type];
            }
        })(rule.type);
        const conditionType = typeConditions[rule.type];
        const condition = rule[conditionType];
        const condititionText = condititions.find((el) => el.value === condition).label.toLocaleLowerCase();
        const operatorText = operators.find((el) => el.value === rule.operator)?.label.toLocaleLowerCase() || '';
        const lastText = finalText(rule, conditionType);
        return [typeText, condititionText, operatorText, lastText].join(' ');
    });
    return result.join(segment.allOrAny === 'all' ? ' AND ' : ' OR ');
};
