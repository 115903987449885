export const dynamicSegmentCreateViewStyles = (theme) => ({
    container: {
        padding: 32
    },
    inputRow: {
        display: 'flex',
        marginBottom: 12
    },
    bigLabel: {
        fontFamily: 'Gilmer Bold',
        fontSize: 18,
        marginBottom: 18
    },
    loadingIcon: {
        display: 'inline-block',
        marginLeft: '14px',
        width: '20px',
        alignItems: 'center',
        justifyContent: 'center'
    },
    smallLabel: {
        fontFamily: 'Gilmer Medium',
        width: 96,
        paddingTop: 6
    },
    btnSearch: {
        height: '30px !important',
        marginLeft: '20px !important',
        '& > span > p': {
            display: 'flex',
            alignItems: 'center'
        },
        '& > span > p > :first-child': {
            marginLeft: '5px'
        },
        '&.disabled': {
            cursor: 'default',
            opacity: 0.5
        }
    },
    marginTop: {
        marginTop: 32
    },
    inlineLabel: {
        fontFamily: 'Gilmer Medium',
        paddingTop: 6,
        display: 'flex',
        alignItems: 'center'
    },
    nameInput: {
        width: 528
    },
    descriptionInput: {
        width: 528,
        '& .MuiInputBase-root': {
            height: 100
        },
        '& fieldset': {
            borderColor: theme.palette.black.full,
            height: 100
        },
        '& textarea': {
            height: 85,
            lineHeight: '18px'
        }
    },
    allOrAnySelect: {
        marginLeft: 6,
        marginRight: 6
    },

    bottomButtons: {
        marginTop: 18,
        display: 'flex',
        '& button': {
            margin: '10px 60px 0 0',
            '& span p': { fontWeight: 500 }
        }
    },
    addRuleBtn: {
        background: '#2b78e4',
        padding: 0,
        height: 30,
        marginTop: 5,
        '&:hover': {
            backgroundColor: '#286cd3'
        },
        '&:active': {
            backgroundColor: '#215bad'
        },
        '& svg': {
            width: 30,
            height: 30,
            fill: '#ffffff'
        }
    }
});

export const dynamicSegmentRuleStyles = () => ({
    ruleRow: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '12px',
        marginBottom: 12
    },
    ruleLabel: {
        fontFamily: 'Gilmer Medium',
        marginTop: 6,
        marginBottom: 6
    },
    ruleBigInput: {},
    ruleServicesInput: {
        width: 300,
        '& ..MuiAutocomplete-tagSizeSmall': {
            margin: 1
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 1)'
        }
    },
    ruleProductInput: {
        minWidth: 300,
        '& ..MuiAutocomplete-tagSizeSmall': {
            margin: 1
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 1)'
        }
    },
    rulePractitionersInput: {
        width: 250,
        marginRight: 12,
        '& ..MuiAutocomplete-tagSizeSmall': {
            margin: 1
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 1)'
        }
    },
    ruleTagsInput: {
        width: 400,
        marginRight: 12,
        '& ..MuiAutocomplete-tagSizeSmall': {
            margin: 1
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 1)'
        }
    },
    ruleSmallInput: {},
    ruleInlineLabel: {
        fontFamily: 'Gilmer Medium',
        marginTop: 10
    },
    deleteButton: {
        marginTop: -6,
        marginLeft: -6
    }
});

export const dynamicSegmentListViewStyles = (theme) => ({
    grayButton: {
        backgroundColor: theme.palette.gray.bold,
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.gray.bolder
        }
    },
    rootBox: {
        margin: 16
    },
    infoText: {
        marginTop: 24,
        marginBottom: 24
    },
    createNewButton: {
        marginBottom: '16px !important'
    },
    loadCount: {
        backgroundColor: 'gray',
        color: 'white',
        borderRadius: '3px',
        border: '1px solid white',
        padding: '5px 10px 5px 10px',
        margin: '5px 0 5px 0',
        '&:hover': {
            backgroundColor: 'white',
            color: 'gray',
            border: '1px solid gray'
        },
        '&:disabled': {
            opacity: 0.5,
            backgroundColor: 'gray',
            color: 'white'
        }
    },
    loadingIcon: {
        display: 'inline-block',
        width: '100%',
        textAlign: 'center'
    },
    filterInput: {
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
        maxWidth: '250px',
        width: 'max-content',
        minWidth: '200px',
        '& input::placeholder': {
            fontSize: '14px !important',
            fontFamily: 'Roboto, Arial, "sans-serif" !important'
        }
    },
    filterInputB: {
        border: 1
    },
    tableRow: {
        cursor: 'pointer',
        fontSize: 14
    },
    tableHeader: {
        fontSize: 14,
        opacity: 1,
        fontWeight: '500!important',
        borderBottom: '1px solid #e0e0e0',
        background: 'white'
    },
    tableBodyCell: {
        borderBottom: '1px solid #e0e0e0',
        opacity: 1,
        fontSize: 14,
        maxHeight: 49,
        '&> span': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            display: '-webkit-box'
        }
    },
    tableActionsColumns: {
        paddingTop: 0,
        paddingBottom: 0
    },
    actionBtn: {
        marginRight: -10
    }
});

export const warningModalStyles = () => ({
    cancelBtn: {
        background: 'white',
        textTransform: 'none',
        fontSize: 12,
        boxShadow: 'none',
        color: '#7C7E86',
        borderColor: '#7C7E86',
        borderStyle: 'solid',
        borderWidth: 1,
        marginRight: 12,
        borderRadius: 0,
        '&:hover': {
            background: 'white',
            boxShadow: 'none'
        }
    },
    deleteBtn: {
        backgroundColor: '#2b78e4',
        textTransform: 'none',
        fontSize: 14,
        color: 'white',
        boxShadow: 'none',
        borderRadius: 0,
        '&:hover': {
            background: '#2b78e4',
            boxShadow: 'none'
        }
    }
});
