import React from 'react';
import { TextField, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { testFieldStyles as styles } from './styles';

const TextFieldInput = ({ value, type, placeholder, onChange, className, classes }) => {
    return (
        <TextField
            className={classNames(classes.textField, className)}
            type={type || 'string'}
            variant="outlined"
            value={value}
            placeholder={placeholder}
            onChange={(event) => onChange(event.target.value)}
        />
    );
};

TextFieldInput.propTypes = {
    value: PropTypes.any.isRequired,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TextFieldInput);
