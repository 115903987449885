import React from 'react';
import PropTypes from 'prop-types';
import EmojiEmotionsOutlinedIcon from '@material-ui/icons/EmojiEmotionsOutlined';
import { ClickAwayListener } from '@material-ui/core';
import { useState } from 'react';

import EmojiPicker from './EmojiPicker';

const Emoji = ({ addEmoji }) => {
    const [visible, setVisibility] = useState(false);

    return (
        <ClickAwayListener onClickAway={() => setVisibility(false)}>
            <div style={{ display: 'inline-block' }}>
                <EmojiEmotionsOutlinedIcon style={{ cursor: 'pointer' }} onClick={() => setVisibility(!visible)} />
                <EmojiPicker visible={visible} setVisibility={setVisibility} addEmoji={addEmoji} />
            </div>
        </ClickAwayListener>
    );
};

Emoji.propTypes = {
    addEmoji: PropTypes.func.isRequired
};

export default Emoji;
