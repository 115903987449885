import React from 'react';
import { TextField, withStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { autocompleteStyles as styles } from './styles';

const AutocompleteInput = ({ value, options, onChange, className, classes, isMultiple }) => {
    if (isMultiple) {
        return (
            <Autocomplete
                multiple={true}
                className={classNames(classes.formControl, className)}
                value={options.filter((option) => value.find((el) => el === option.value))}
                options={options}
                getOptionLabel={(option) => option?.label || ''}
                renderInput={(params) => <TextField {...params} variant="outlined" />}
                onChange={(event, value) => {
                    onChange(value.map((el) => el.value));
                }}
            />
        );
    }
    return (
        <Autocomplete
            className={classNames(classes.formControl, className)}
            value={options.find((option) => option.value === value) || null}
            options={options}
            getOptionLabel={(option) => option.label || ''}
            renderInput={(params) => <TextField {...params} variant="outlined" />}
            onChange={(event, value) => onChange(value ? value.value : null)}
        />
    );
};

AutocompleteInput.propTypes = {
    value: PropTypes.string,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    isMultiple: PropTypes.bool
};

export default withStyles(styles)(AutocompleteInput);
